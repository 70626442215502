<ion-list lines="none">
  <ion-list-header class="list-header-menu">
      <span class="menu-title">OPERAÇÃO</span>
  </ion-list-header>

  <ion-menu-toggle class="list-menu" autoHide="false" *ngFor="let p of linksOperations; let i = index">
      <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" [name]="p.icon + '-outline'"></ion-icon>
          <ion-label class="menu-text">
              {{p.title}}
          </ion-label>
      </ion-item>
  </ion-menu-toggle>
</ion-list>
<!--
<ion-list lines="none">
  <ion-list-header class="list-header-menu">
      <span class="menu-title">QUALIDADE</span>
  </ion-list-header>

  <ion-menu-toggle class="list-menu" autoHide="false" *ngFor="let p of linksServiceLevel; let i = index">
      <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" [name]="p.icon + '-outline'"></ion-icon>
          <ion-label class="menu-text">
              {{p.title}}
          </ion-label>
      </ion-item>
  </ion-menu-toggle>
</ion-list>
-->
<ion-list lines="none">
  <ion-list-header class="list-header-menu">
      <span class="menu-title">Administração</span>
  </ion-list-header>

  <ion-menu-toggle class="list-menu" autoHide="false" *ngFor="let p of linksAdm; let i = index">
      <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" [name]="p.icon + '-outline'"></ion-icon>
          <ion-label class="menu-text">
              {{p.title}}
          </ion-label>
      </ion-item>
  </ion-menu-toggle>
</ion-list>
<hr class="ion-margin" />

<ion-list lines="none">
  <ion-menu-toggle class="list-menu" autoHide="false">
      <!--
      <ion-item button (click)="openTutorial()" detail="false">
          <ion-icon slot="start" size="small" name="hammer"></ion-icon>
          <ion-label class="menu-text">Show Tutorial</ion-label>
      </ion-item>
      -->
      <ion-item button (click)="logout()" detail="false">
          <ion-icon slot="start" size="small" name="log-out"></ion-icon>
          <ion-label class="menu-text">
              Sair
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

</ion-list>

<!--
<ion-list>
  <ion-menu-toggle class="list-menu" autoHide="false">
      <ion-item *ngIf="serviceCenterSelected">
          <ion-label>Acessando {{ serviceCenterSelected.abbr}}</ion-label>
      </ion-item>
      <ion-item *ngIf="!serviceCenterSelected">
          <ion-label>Acessando Todas Unidades</ion-label>
      </ion-item>
  </ion-menu-toggle>
</ion-list>
-->
<!--
<ion-list *ngIf="isAuthenticated" lines="none">
  <ion-list-header>
      Account {{isAuthenticated}}
  </ion-list-header>

  <ion-menu-toggle autoHide="false">
      <ion-item routerLink="/account" routerLinkActive="active" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" name="person"></ion-icon>
          <ion-label>
              Minha Conta
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

  <ion-menu-toggle autoHide="false">
      <ion-item button (click)="logout()" detail="false">
          <ion-icon slot="start" size="small" name="log-out"></ion-icon>
          <ion-label>
              Sair
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

</ion-list>

<ion-list lines="none">
  <ion-list-header>
      Account  {{isAuthenticated}}
  </ion-list-header>

  <ion-menu-toggle autoHide="false">
      <ion-item routerLink="/auth/login" routerLinkActive="active" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" name="log-in"></ion-icon>
          <ion-label>
              Login
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

  <ion-menu-toggle autoHide="false">
      <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" name="help"></ion-icon>
          <ion-label>
              Support
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

  <ion-menu-toggle autoHide="false">
      <ion-item routerLink="/auth/signup" routerLinkActive="active" routerDirection="root" detail="false">
          <ion-icon slot="start" size="small" name="person-add"></ion-icon>
          <ion-label>
              Signup
          </ion-label>
      </ion-item>
  </ion-menu-toggle>

  <ion-item>
      <ion-icon slot="start" size="small" name="moon-outline"></ion-icon>
      <ion-label>
          Dark Mode
      </ion-label>
      <ion-toggle [(ngModel)]="dark"></ion-toggle>
  </ion-item>
</ion-list>
-->

