import { Component, Input } from "@angular/core";

@Component({
  selector: "app-loading-table-special-column",
  templateUrl: "./loading-table-special-column.component.html",
  styleUrls: ["./loading-table-special-column.scss"],
})
export class LoadingTableSpecialColumnComponent {
  //Constructor
  constructor() {}
}
