import { Component, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../../../../app-modules-core/app-menu/models/menu-item";
import { MenuService } from "../../../../app-modules-core/app-menu/service/menu.service";
import { SHIPMENTS_CHILDREN_ROUTES } from "../../../shipments/shipments.routing";

@Component({
  selector: "app-global-search-nav",
  templateUrl: "global-search-nav.html",
  styleUrls: ["./global-search-nav.scss"],
})
export class GlobalSearchNavPage {
  public links: MenuItem[] = new MenuService().fromApplicationRoute(
    SHIPMENTS_CHILDREN_ROUTES
  );

  /**
   *
   */
  constructor(public router: Router) {}
}
