<!-- CORE COMPONENT LOADING INIT -->
<ion-grid>
  <ion-row class="ion-align-items-end">
    <!-- INIT -->
    <ion-col class="wrapper-divisor" size="3">
      <div class="wrapper-header">
        <ion-skeleton-text animated style="width: 20%; margin: 10px 40px">
        </ion-skeleton-text>
      </div>
      <ion-grid *ngFor="let item of [1, 2, 3]">
        <app-box status="information">
          <ion-row
            style="height: 100%"
            class="wrapper-body ion-justify-content-center ion-align-items-center"
          >
            <ion-skeleton-text animated style="width: 20%; margin: 10px 40px">
            </ion-skeleton-text>
          </ion-row>
        </app-box>
      </ion-grid>
    </ion-col>
    <ion-col>
      <div class="wrapper-content">
        <ion-skeleton-text animated style="width: 20%; margin: 10px 40px">
        </ion-skeleton-text>
        <hr class="underline" />
      </div>
      <ion-grid>
        <!-- TABLE HEADER INIT -->
        <ion-row>
          <ion-col *ngFor="let header of [1, 2, 3]">
            <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
            </ion-skeleton-text>
          </ion-col>
        </ion-row>
        <!-- TABLE HEADER END -->
        <!-- TABLE BODY INIT -->
        <ion-row *ngFor="let body of [1, 2, 3]">
          <ion-col *ngFor="let content of [1, 2, 3]; index as i">
            <app-box>
              <ion-row
                class="ion-justify-content-center ion-align-items-center"
              >
                <ion-skeleton-text
                  animated
                  style="width: 40%; margin: 10px 40px"
                >
                </ion-skeleton-text>
              </ion-row>
            </app-box>
          </ion-col>
        </ion-row>
        <!-- TABLE BODY END -->
      </ion-grid>
    </ion-col>
    <!-- END -->
  </ion-row>
</ion-grid>
<!-- CORE COMPONENT LOADING END -->
