import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { BaseHttpClient } from '../../../../app-core/http/abstractions/base-http-client';
import { libArray } from '../../../../app-core/libs/collections/util-collection';
import { ApiBaseService } from '../../../../app-core/services/api-base-service';
import { Shipper, ShipperList } from '../../models/shipper';

@Injectable({ providedIn: "root" })
export class ShipperBranchesSearch extends ApiBaseService {
  //Props
  private readonly urlShippersBranches = `${environment.hosts.bff.shippers_branches}`;

  /**
   *
   * @param http
   */
  constructor(protected http: BaseHttpClient) {
    super(http);
  }

  public findBranch(ids: number[]) {
    if (libArray.isEmpty(ids)) {
      return of(new ShipperList([]));
    }

    return this.fetch(ids);
  }

  private fetch(ids: number[]): Observable<ShipperList> {
    let sendParams = new HttpParams();
    ids.forEach((item) => {
      sendParams = sendParams.append("idsShipperGroup", item);
    });

    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams
    };

    const result = this.http.get(this.urlShippersBranches, options).pipe(
      switchMap((rawData: any[]) => {
        const list = new ShipperList(rawData.map(item => Shipper.fromApi(item)));
        return of(list);
      }),
      catchError(err => of(new ShipperList([])))
    );

    return result;
  }
}
