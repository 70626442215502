import { environment } from "@env/environment";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { ServicesStorage } from "./services.storage";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { Service, ServiceList } from "../models/service";
import { libArray } from "../../../app-core/libs/collections/util-collection";

@Injectable({ providedIn: "root" })
export class ServicesSearch extends ApiBaseService {
  //Props
  private readonly urlShipmentSservice = `${environment.hosts.bff.service}`;
  public isLoading$: Observable<Boolean>;
  /**
   *
   * @param http Constructor
   */
  constructor(
    protected http: BaseHttpClient,
    private storageService: ServicesStorage
  ) {
    super(http);
  }

  /**
   *
   */
  public findAndCache({ ids = [], org }: { ids?: number[]; org: number }) {
    var result$ = this.fetch({ ids, org }).pipe(
      tap(async (values) => {
        await this.storageService.save(values.getAll);
        return of(values);
      }),
      catchError((err) => {
        console.log(err);
        return of(new ServiceList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findFromCache({
    ids = [],
    org,
  }: {
    ids?: number[];
    org: number;
  }): Observable<ServiceList> {
    const result$ = from(this.storageService.get()).pipe(
      switchMap((values) => {
        if (!libArray.isEmpty(values)) {
          return of(new ServiceList(values));
        }

        return this.findAndCache({ ids, org });
      }),
      catchError((err) => {
        console.log(err);
        return of(new ServiceList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findAll({ ids = [], org }: { ids: number[]; org: number }) {
    const listFromApi = this.fetch({ ids, org });

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch({
    ids = [],
    org,
  }: {
    ids?: number[];
    org: number;
  }): Observable<any> {
    let sendParams = new HttpParams();

    ids.forEach((item) => {
      sendParams = sendParams.append("ids", item);
    });
    sendParams = sendParams.append("idOrganization", org);
    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http.get(this.urlShipmentSservice, options).pipe(
      switchMap((rawData: any) => {
        let list = rawData.map((item) => Service.fromApi(item));
        let result = new ServiceList(list);
        return of(result);
      }),
      catchError((err) => {
        console.log(err);
        return of([]);
      })
    );
    return result;
  }

  /**
   * Apply filters
   * @param listOfItems
   * @param orgs
   * @returns
   */
  private _filter(listOfItems: ServiceList, ids: number[]) {
    let filteredList = listOfItems.getAll;

    if (!libArray.isEmpty(ids)) {
      filteredList = filteredList.filter((x) => ids.indexOf(x.id) >= 0);
    }

    return filteredList;
  }
}
