import { Component, Input } from '@angular/core';

@Component({
    selector: 'heading-drawer',
    templateUrl: './heading-drawer.component.html',
    styleUrls: ['./heading-drawer.scss'],

})
export class HeadingDrawerComponent { 

    @Input() title: string;

    //Constructor
    constructor(){

    }
}