<ion-grid>
  <ion-col>
    <ion-row
      ><ion-text
        ><h1>Não encontramos o que você procura :(</h1></ion-text
      ></ion-row
    >
    <ion-row
      ><ion-text
        ><p>{{ message }}</p></ion-text
      ></ion-row
    >
  </ion-col>
</ion-grid>
