import { Injectable } from '@angular/core';
import { forkJoin, lastValueFrom, Observable, of, tap, throwError, withLatestFrom } from 'rxjs';
import { BaseHttpClient } from '../../../app-core/http/abstractions/base-http-client';
import { libArray } from '../../../app-core/libs/collections/util-collection';
import { BaseStore } from '../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { ApiBaseService } from '../../../app-core/services/api-base-service';
import { User } from '../../auth/models/user';
import { Organization, OrganizationList } from '../../organizations/models/organization';
import { SettingsSystem, SettingsSystemList } from '../../organizations/models/settings-system';
import { OrganizationSearch } from '../../organizations/services/organization.search';
import { SettingsOrganizationSearch } from '../../organizations/services/settings-organization.search';
import { ServiceCenterSearch } from '../../service-centers/services/service-center.search';
import { IAppGlobalState } from '../store/app-global-model';

@Injectable({ providedIn: "root" })
export class AppSettingsService extends ApiBaseService {

    //Props

    /**
     * 
     * @param http Constructor
     */
    constructor(protected http: BaseHttpClient,
                private settingsOrgSearch: SettingsOrganizationSearch,
                private orgSearch: OrganizationSearch,
                private serviceCenterSearch: ServiceCenterSearch
                ) {

        super(http);
    }



    /**
     * 
     * @param infoUser 
     * @returns 
     */
    public async loadSystemSettings(infoUser: User) {

        const orgList = await lastValueFrom(this.orgSearch.findAndCache(infoUser.orgsIds));
        const settingsList = await lastValueFrom(this.settingsOrgSearch.findAndCache(infoUser.orgsIds));
        await lastValueFrom(this.serviceCenterSearch.findAndCache(infoUser.orgsIds));
        const currentOrg = await lastValueFrom(this._getSystemOrganization(orgList, infoUser));
        const currentSettings = await lastValueFrom(this._getSystemSettings(settingsList, infoUser));
        
        //If currentSettings.organization not found, get default org
        if(currentSettings.organization == null && currentOrg != null){
            currentSettings.organization = currentOrg;
        }

        return {
            organization: currentOrg,
            settings: currentSettings,
            user: infoUser
        };
        /*
        .subscribe({
            next: (values) => {
                console.log(values.orgs);
                return of(true);
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info('complete') 
                return of(false);
            }
        });
        */
    }

    /**
     * 
     * @param orgs 
     * @param infoUser 
     * @returns 
     */
    private _getSystemOrganization(orgList: OrganizationList, infoUser: User): Observable<Organization>{

        if(!infoUser.hasOrgs){
            return throwError( () => new Error('Usuário informado não possui permissão para nenhuma organização no momento.'));
        }

        //Se houver permissao para mais e uma org, não precisamos configurar a org atual
        if(infoUser.hasMultipleOrgs){
            return of(new Organization());
        }

        const idOrg = libArray.first(infoUser.orgsIds);
        const arrayOrg = orgList.getAll.filter(item => item.id == idOrg);

        if(arrayOrg.length == 0){
            return throwError( () => new Error('Houve conflito entre os dados da organização com o login informado.'));
        }

        const currentOrg = arrayOrg[0];
        
        return of(currentOrg);
    }


    /**
     * 
     * @param orgs 
     * @param infoUser 
     * @returns 
     */
     private _getSystemSettings(settingsList: SettingsSystemList, infoUser: User): Observable<SettingsSystem>{

        if(!infoUser.hasOrgs){
            return throwError( () => new Error('Usuário informado não possui permissão para nenhuma organização no momento.'));
        }

        //Se houver permissao para mais e uma org, não precisamos configurar a org atual
        if(infoUser.hasMultipleOrgs){
            return of(new SettingsSystem());
        }

        const idOrg = libArray.first(infoUser.orgsIds);
        const arraySettings = settingsList.getAll.filter(item => item.organization.id == idOrg);

        if(arraySettings.length == 0){
            return of(new SettingsSystem());
        }

        const settings = arraySettings[0];
        return of(settings);
    }

}

