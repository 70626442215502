import { Pipe, PipeTransform } from "@angular/core";
import * as momentTimezone from "moment-timezone";

@Pipe({
  name: "customTimePipe",
})
export class CustomTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    /*
    Los_Angeles
    Sao_Paulo
    */
    return momentTimezone(value, "HH:mm").utc(true).tz('America/Sao_Paulo').format("HH:mm A");
  }
}
