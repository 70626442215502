import { select } from "@ngrx/store";
import { Observable } from "rxjs";
import { BaseStore } from "../../app-core/libs/UTILNgrx/Store/BaseStore";
import { IAppGlobalState } from "../../app-modules-core/app-state/store/app-global-model";
import { User } from "../../app-modules-core/auth/models/user";
import { selectStateAuthUser } from "../../app-modules-core/auth/store/app-auth-state.selectors";

export class ComponentUserBase {
  //Propertiers
  protected infoAuth$: Observable<{ isAuthenticated: boolean; profile: User }>;
  public profile: User = new User();

  /**
   *
   */
  constructor(protected store: BaseStore<IAppGlobalState>) {
    this.infoAuth$ = this.store.pipe(select(selectStateAuthUser));
  }

  /**
   *
   */
  listenerInfoAuth(): void {
    this.infoAuth$.subscribe((info) => {
      this.profile = info.profile ?? new User();
    });
  }
}
