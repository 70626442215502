import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseStore } from '../../../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../../../../app-modules-core/app-state/store/app-global-model';
import { navToHome, navToSelectOrganization } from '../../../../../app-modules-ui/global-links';
import { OrganizationBaseComponent } from '../../../organization-base';

@Component({
    selector: 'app-sidebar-header-brand',
    templateUrl: './sidebar-header-brand.html',
    styleUrls: ['./sidebar-header-brand.scss'],
    encapsulation: ViewEncapsulation.None

})
export class SidebarHeaderBrandComponent extends OrganizationBaseComponent implements OnInit, OnDestroy {

    /**
     * 
     */
    constructor(private router: Router,
        protected store: BaseStore<IAppGlobalState>) {
        super(store);

    }



    /**
     * 
     */
    goToHome() {
        navToHome(this.router);
    }

    /**
     * 
     */
     goToSelectOrganization() {
        navToSelectOrganization(this.router);
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.subscribeAppSate();
    }

    ngOnDestroy(): void {
        this.unsubscribeAppSate();
    }

}
