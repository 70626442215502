export interface IAppMessage{
    id: number;
    title: string;
    description: string;
    type: AppMessageType;
    iconName: string | null;
    iconSrc: string | null; 

    get isError(): boolean;
    get isInfo(): boolean;
    get isSuccess(): boolean;
    get isHelp(): boolean;
    get isProcess(): boolean;
}

export type AppMessageType =
    | 'info'
    | 'help'
    | 'error'
    | 'process'
    | 'success';

export class AppMessage implements IAppMessage{

    id: number;
    title: string;
    description: string;
    type: AppMessageType;
    iconName: string | null;
    iconSrc: string | null; 

    /**
     * 
     */
    constructor(_title: string, _desc: string, _type: AppMessageType){
        this.title = _title;
        this.description = _desc;
        this.type = _type;
    }

    get isError(): boolean {
        return this.type == 'error';
    }

    get isInfo(): boolean {
        return this.type == 'info';
    }

    get isSuccess(): boolean {
        return this.type == 'success';
    }

    get isHelp(): boolean {
        return this.type == 'help';
    }

    get isProcess(): boolean {
        return this.type == 'process';
    }
}

export class AppMessageError extends AppMessage{

    /**
     * 
     */
    constructor(_title: string, _desc: string){
        super(_title, _desc, 'error');
    }
}

export class AppMessageInfo extends AppMessage{

    /**
     * 
     */
    constructor(_title: string, _desc: string){
        super(_title, _desc, 'info');
    }
}

export class AppMessageSuccess extends AppMessage{

    /**
     * 
     */
    constructor(_title: string, _desc: string){
        super(_title, _desc, 'success');
    }
}

export class AppMessageProcess extends AppMessage{

    /**
     * 
     */
    constructor(_title: string, _desc: string){
        super(_title, _desc, 'process');
    }
}