import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { HeaderDefaultModule } from '../../organisms/header-default/header-default.module';
import { DefaultContentTemplate } from './default-content-template';

@NgModule({
    declarations: [
        DefaultContentTemplate
    ],
    imports: [
        SharedModule,
        HeaderDefaultModule
    ],
    exports: [
        DefaultContentTemplate
    ]
})
export class DefaultContentTemplateModule {
}
