import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, defaultIfEmpty, finalize, map, mapTo, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseHttpClient } from '../../../app-core/http/abstractions/base-http-client';
import { NumberList } from '../../../app-core/libs/collections/list-model-base';
import { ApiBaseService } from '../../../app-core/services/api-base-service';
import { Organization, OrganizationList } from '../models/organization';
import { SettingsSystem } from '../models/settings-system';
import { OrganizationStorage } from './organization.storage';
import { SettingsOrganizationStorage } from './settings-organization.storage';

@Injectable({ providedIn: "root" })
export class OrganizationSearch extends ApiBaseService {

    //Props
    private readonly urlOrganizations = `${environment.hosts.bff.organizations}`;

    /**
     *
     * @param http Constructor
     */
    constructor(protected http: BaseHttpClient,
                private storageService: OrganizationStorage
                ) {

        super(http);
    }

    /**
     *
     */
     public findAndCache(orgs: number[]){

        var result$ = this.fetch(orgs)
                        .pipe(
                            tap(async values => {
                                await this.storageService.save(values.getAll);
                                return of(values);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of(new OrganizationList([]));
                            })

                        );


        return result$;
    }

    /**
     * Get All From API
     */
    public find(orgs: number[]){

        const listFromApi = this.fetch(orgs);

        return listFromApi;
    }

    /**
     *
     * @returns
     */
    private fetch(orgs: number[]): Observable<OrganizationList>{

        const sendParams = new HttpParams();
        orgs.forEach( (item) => { sendParams.append("id", item); });

        const options = {
            headers: this.httpHeaderDefault,
            params: sendParams
        };

        const result = this.http.get(this.urlOrganizations, options)
                        .pipe(
                            switchMap( (rawData: any[]) => {
                                let list = rawData.map(item => Organization.fromApi(item));
                                let result = new OrganizationList(list);
                                return of(result);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of(new OrganizationList([]));
                            })
                        );
        return result;
    }

}

