import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export class HttpResponseUtils {
    /**
     * Constructor
     */
    constructor() {
    }

    /**
     * Error messagens HTTPS Erros?
     *
     */
    static getMessageFromError(response: HttpErrorResponse ): string {

        if (response.status === HttpStatusCode.InternalServerError){
            return "Ops, ocorreu algum problema ao tentar executar esta ação. Tente novamente mais tarde!";
        }

        if (response.status > 500){
            return "Não foi possível conectar com o servidor remoto. Tente novamente mais tarde!";
        }

        if (response.status === HttpStatusCode.NotFound){
            return "Não conseguimos localizar o recurso informado no servidor remoto!";
        }

        if (response.status === HttpStatusCode.Forbidden){
            return "Ops, parece que você está sem permissão para esta operação!";
        }

        if (response.status === HttpStatusCode.Unauthorized){
            return "Ops, somente usuários autorizados podem realizar esta operação. Por favor, faça seu login.";
        }

        if (response.status === HttpStatusCode.TooManyRequests){
            return "Ops, No momento estamos tendo muitas tentativas para a mesma operação, tente novamente em instantes.";
        }

        return "Não conseguimos nos comunicar com o servidor! Tente novamente em alguns minutos.";
    }

}
