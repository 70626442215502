import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";

import { MenuController } from "@ionic/angular";
import { Observable } from "rxjs";
import { Storage } from "@ionic/storage";
import { ActionItem } from "../../../../app-modules-core/app-actions/models/action-item";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";
import { ShippingAgents } from "../../../../app-modules-core/shipping-agents/models/shippingAgents";
import { ShippingAgentSearch } from "../../../../app-modules-core/shipping-agents/services/shippingAgents.search";
//import { MENU_SETTINGS } from '../../../../app-modules-core/app-menu/models/menu-settings.const';
//import { FuseDrawerComponent } from '../../../../shared/components/organisms/drawer';
import { BaseStore } from "../../../../app-core/libs/UTILNgrx/Store/BaseStore";

@Component({
  selector: "app-shipping-agent-list",
  templateUrl: "shipping-agent-list.html",
  styleUrls: ["./shipping-agent-list.scss"],
})
export class ShippingAgentListPage {
  //@ViewChild(FuseDrawerComponent) filterDrawer: FuseDrawerComponent;

  public listOfShippingAgents: ShippingAgents[];

  /**
   *
   * @param menu
   * @param router
   * @param storage
   * @param cd
   */
  constructor(
    public menu: MenuController,
    public router: Router,
    public storage: Storage,
    public shippingAgentSearch: ShippingAgentSearch,
    private store: BaseStore<IAppGlobalState>
  ) {
    this._loadOrganization().subscribe((settings) => {
      this.shippingAgentSearch
        .find(settings.organization.id)
        .subscribe((obj) => (this.listOfShippingAgents = obj.listOfItems));
    });
  }

  /**
   * Show/Hide filters
   */
  public toggleFilters = () => {
    //this.filterDrawer.toggle();
  };

  /**
   * List of utilities actions available
   */
  public listOfActions: ActionItem[] = [
    {
      title: "Filtros",
      iconSrc: "filter",
      clickAction: this.toggleFilters,
    },
  ];

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }
}
