import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Route, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

import { MenuController, Platform, ToastController } from "@ionic/angular";

import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";

import { AppStorageService } from "./app-core/storage/app-storage-service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  loggedIn = false;
  dark = false;

  //Constructor
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: AppStorageService,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  //Initialize
  private initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  //Init Database/LocalStorage
  private async initDatabase(): Promise<void> {
    await this.storage.init();
  }
  //#endregion NAVIGATION COMMANDS

  //#region APP EVENTS

  //Event Start Angular App
  async ngOnInit() {
    this.initDatabase();
    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: "Update available!",
        position: "bottom",
        buttons: [
          {
            role: "cancel",
            text: "Reload",
          },
        ],
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }
  //#endregion APP EVENTS

  //#region GLOBAL LISTENERS

  //#endregion GLOBAL LISTENERS
}
