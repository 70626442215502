import { Component, EventEmitter, Output, Input } from "@angular/core";

import { PaginationOptions } from "./models/pagination-options";

@Component({
  selector: "app-pagination",
  templateUrl: "pagination.html",
  styleUrls: ["./pagination.scss"],
})
export class PaginationComponent {
  public PaginationOptions: PaginationOptions;
  public minNumberPage: number = 1;
  public arrayPaginas: Number[] = [this.minNumberPage];
  public isSelected: boolean = false;

  public results: Number[] = [];
  public disableNext: boolean = false;
  public disablePrev: boolean = true;
  public showMorePagesLeft: boolean = false;
  public showMorePagesRight: boolean = false;

  public totalRegistros: number = 0;
  public totalPaginas: number = 0;

  /**
   *
   */
  constructor() {
    this.PaginationOptions = new PaginationOptions();
  }

  @Output() private data = new EventEmitter<PaginationOptions>();
  @Input() countData: number;
  @Input() loading: boolean = false;

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.controlNextPrev();
    this.setPagesAndRegisters();
  }

  public nextPage(): void {
    this.controlNextPrev();
    this.setCurrentPage(1);

    if (this.PaginationOptions.currentPage - 1 == this.getLastElementPage()) {
      this.arrayPaginas.push(this.PaginationOptions.currentPage);
      return;
    }
  }

  public prevPage(): void {
    this.controlNextPrev();
    this.setCurrentPage();
  }

  public sendParamns(currentPage?: string): void {
    this.PaginationOptions.currentPage =
      currentPage == undefined
        ? this.PaginationOptions.currentPage
        : Number(currentPage);

    this.controlNextPrev();
    this.verifyNumberPages();
    this.PaginationOptions.offset = this.getOffset(
      this.PaginationOptions.currentPage
    );
    this.data.emit(this.PaginationOptions);
    this.setPagesAndRegisters();
  }

  private getOffset(currentPage: number): number {
    return (currentPage - 1) * this.PaginationOptions.limit;
  }

  private setCurrentPage(incrementValue?: number): void {
    if (incrementValue !== undefined && incrementValue > 0) {
      this.PaginationOptions.currentPage += incrementValue;
    } else {
      this.PaginationOptions.currentPage--;
    }
  }

  private setPagesAndRegisters(): void {
    this.totalPaginas = this.getLastElementPage();

    if (!(this.PaginationOptions.currentPage < this.totalPaginas)) {
      if (this.countData < this.PaginationOptions.limit) {
        this.totalRegistros =
          (this.totalPaginas - 1) * this.PaginationOptions.limit +
          this.countData;
        return;
      }

      this.totalRegistros = this.totalPaginas * this.countData;
    }
  }

  private getLastElementPage(): number {
    return this.arrayPaginas.slice(-1)[0] as number;
  }

  private controlNextPrev(): void {
    if (
      this.countData == undefined ||
      this.countData < this.PaginationOptions.limit
    ) {
      this.disableNext = true;
      return;
    }

    if (this.PaginationOptions.currentPage == 1) {
      this.disablePrev = true;
      this.disableNext = false;
      return;
    }

    this.disablePrev = false;
    this.disableNext = false;
  }

  private verifyNumberPages(): void {
    this.showMorePagesRight = false;
    this.showMorePagesLeft = false;

    let totalPaginas = this.getLastElementPage();
    let moreRight = totalPaginas - this.PaginationOptions.currentPage;

    if (this.PaginationOptions.currentPage < totalPaginas && moreRight > 1) {
      this.showMorePagesRight = true;
    }

    if (
      this.PaginationOptions.currentPage > 1 &&
      this.PaginationOptions.currentPage > 2 &&
      totalPaginas > 3
    ) {
      this.showMorePagesLeft = true;
    }

    this.buildNewArrayPaginas();
  }

  private buildNewArrayPaginas(): void {
    let numberInitial = 1;
    let numberFinish = 1;
    let qtdPositionleft = 1;
    let qtdPositionRight = 1;
    let totalPaginas = this.getLastElementPage();

    if (totalPaginas > 3) {
      numberInitial = this.PaginationOptions.currentPage - qtdPositionleft;
      numberFinish = this.PaginationOptions.currentPage + qtdPositionRight;

      if (numberInitial <= 0) {
        numberInitial = 1;
      }

      if (numberFinish >= totalPaginas) {
        numberInitial = totalPaginas - (qtdPositionleft + qtdPositionRight);
        numberFinish = totalPaginas;
      }

      if (this.countData > 0) {
        let newArrayPaginas: number[] = [];

        for (var x = numberInitial; x <= numberFinish; x++) {
          newArrayPaginas.push(x);
        }

        this.arrayPaginas = newArrayPaginas;
      }
    }
  }
}
