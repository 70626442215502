import { Component } from "@angular/core";

@Component({
  selector: "app-loading-table-vertical",
  templateUrl: "./loading-table-vertical.component.html",
  styleUrls: ["./loading-table-vertical.scss"],
})
export class LoadingTableVerticalComponent {
  //Constructor
  constructor() {}
}
