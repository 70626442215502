<app-heading-page title="Detalhes da Remessa {{ idShipment }}">
</app-heading-page>

<ion-content>
  <div *ngIf="shipment$ | async as shipment; else itemLoading">
    <div [style.display]="shipment.id ? 'block' : 'none'">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <ion-button
              color="{{colorNameStatus}}"
              size="small"
              class="fw-normal"
              *ngIf="shipment?.status?.name"
            >
              {{shipment?.status?.name}}</ion-button
            >
          </div>
        </div>
      </div>
      <ion-card color="light" class="mt-0">
        <ion-card-content>
          <div class="row">
            <div class="col-md-6 fs-085">
              <div class="row">
                <div class="col-md-12 col-xxl-6">
                  <app-label-icon-01 iconSrc="hashtag"
                    >{{shipment?.id}}</app-label-icon-01
                  >
                  <app-label-icon-01 iconSrc="city"
                    >{{shipment?.shipper_branch?.shipper?.tradeName ??
                    '-'}}</app-label-icon-01
                  >
                  <app-label-icon-01 iconSrc="tags"
                    >{{shipment?.service?.name ?? '-'}}</app-label-icon-01
                  >
                  <app-label-icon-01
                    *ngIf="shipment.delivery_provider?.name"
                    iconSrc="shipping-fast"
                    >VIA {{shipment.delivery_provider.name}}</app-label-icon-01
                  >
                </div>
                <div class="col-md-12 col-xxl-6">
                  <app-label-icon-01 iconSrc="map-marker-alt"
                    >Origem {{shipment?.sender_origin?.address?.city?.name}} -
                    {{shipment?.sender_origin?.address?.city?.state?.abbr}}</app-label-icon-01
                  >
                  <app-label-icon-01 iconSrc="map-marker-check"
                    >Destino {{shipment?.destination?.address?.city?.name}} -
                    {{shipment?.destination?.address?.city?.state?.abbr}}</app-label-icon-01
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12 col-xxl-6 fs-075">
                  <app-label-icon-01
                    *ngIf="shipment?.deadline?.dt_delivery_original"
                    iconSrc="calendar-alt"
                    >Prazo Original
                    {{shipment?.deadline?.dt_delivery_original}}</app-label-icon-01
                  >
                  <app-label-icon-01
                    *ngIf="shipment?.deadline?.dt_delivery_current"
                    iconSrc="calendar-alt"
                    >Prazo Atual
                    {{shipment?.deadline?.dt_delivery_current}}</app-label-icon-01
                  >
                </div>
                <div class="col-12 col-xxl-6 fs-075">
                  <app-label-check *ngIf="shipment?.flow?.dt_register_finish"
                    >Criado em
                    {{shipment?.flow?.dt_register_finish}}</app-label-check
                  >
                  <app-label-check *ngIf="shipment?.flow?.dt_provider_engaged"
                    >Engajado em
                    {{shipment?.flow?.dt_provider_engaged}}</app-label-check
                  >
                  <app-label-check *ngIf="shipment?.flow?.dt_done_finish"
                    >Entregue em {{shipment?.flow?.dt_done_finish}}
                  </app-label-check>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-1 mb-2" />
          <div class="row">
            <div class="col">
              <app-badge-default
                *ngIf="deliveryOnTime(shipment?.deadline?.dt_delivery_current, shipment?.flow?.dt_done_finish, shipment?.status?.id)"
                color="#08A93C"
                cssClass="float-md-end"
                ><em>Entregue no Prazo!</em></app-badge-default
              >
              <app-badge-default
                *ngIf="deliveryInProgress(shipment?.deadline?.dt_delivery_current, shipment?.flow?.dt_done_finish, shipment?.status?.id)"
                color="#424A52"
                cssClass="float-md-end"
                ><em>Em andamento no prazo!</em></app-badge-default
              >
              <app-badge-default
                *ngIf="lateDelivery(shipment?.deadline?.dt_delivery_current, shipment?.flow?.dt_done_finish, shipment?.status?.id)"
                color="red"
                cssClass="float-md-end"
                ><em>Em andamento fora do prazo :(</em></app-badge-default
              >
              <app-badge-default
                *ngIf="deliveredLate(shipment?.deadline?.dt_delivery_current, shipment?.flow?.dt_done_finish, shipment?.status?.id)"
                color="red"
                cssClass="float-md-end"
                ><em
                  >Entregue com atraso em {{ shipment?.flow?.dt_done_finish }}
                  :(</em
                ></app-badge-default
              >
              <app-badge-default
                *ngIf="canceledDelivery(shipment?.flow?.dt_cancellation, shipment?.status?.id)"
                color="red"
                cssClass="float-md-end"
                ><em
                  >Remessa cancelada em {{ shipment?.flow?.dt_cancellation
                  }}.</em
                ></app-badge-default
              >
              <app-badge-default
                *ngIf="abort(shipment?.status?.id)"
                color="red"
                cssClass="float-md-end"
                ><em
                  >Remessa descartada em {{ shipment?.flow?.abort }}.</em
                ></app-badge-default
              >
            </div>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card color="light">
        <ion-card-content
          *ngIf="shipment?.destination ||
            shipment?.receiver ||
            shipment?.sender_origin ||
            shipment?.sender_dispatcher ||
            shipment?.payer"
        >
          <div class="row">
            <div class="col-md-12 col-xxl-6" *ngIf="shipment?.destination">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit:
                {title: 'Destinatário',
                name: shipment?.destination?.name,
                document: shipment?.destination?.document?.number,
                postalCode: shipment?.destination?.address?.postalCode,
                addressName: shipment?.destination?.address?.city,
                addressNameFull: shipment?.destination?.address } }"
              ></ng-container>
            </div>
            <div class="col-md-12 col-xxl-6" *ngIf="shipment?.sender_origin">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit:
                {title: 'Remetente',
              name: shipment?.sender_origin?.name,
              document: shipment?.sender_origin?.document?.number,
              postalCode: shipment?.sender_origin?.address?.postalCode,
              addressName: shipment?.sender_origin?.address?.city,
              addressNameFull: shipment?.sender_origin?.address } }"
              ></ng-container>
            </div>
            <div class="col-md-12 col-xxl-6" *ngIf="shipment?.receiver">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit:
                {title: 'Recebedor',
                name: shipment?.receiver?.name,
                document: shipment?.receiver?.document?.number,
                postalCode: shipment?.receiver?.address?.postalCode,
                addressName: shipment?.receiver?.address?.city,
                addressNameFull: shipment?.receiver?.address } }"
              ></ng-container>
            </div>
            <div
              class="col-md-12 col-xxl-6"
              *ngIf="shipment?.sender_dispatcher"
            >
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: {title: 'Expedidor',
              name: shipment?.sender_dispatcher?.name,
              document: shipment?.sender_dispatcher?.document?.number,
              postalCode: shipment?.sender_dispatcher?.address?.postalCode,
              addressName: shipment?.sender_dispatcher?.address?.city,
              addressNameFull: shipment?.sender_dispatcher?.address } }"
              ></ng-container>
            </div>
            <div
              class="col-md-12 col-xxl-6"
              *ngIf="shipment?.sender_origin && !shipment?.sender_dispatcher"
            >
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: {title: 'Expedidor',
              name: shipment?.sender_origin?.name,
              document: shipment?.sender_origin?.document?.number,
              postalCode: shipment?.sender_origin?.address?.postalCode,
              addressName: shipment?.sender_origin?.address?.city,
              addressNameFull: shipment?.sender_origin?.address } }"
              ></ng-container>
            </div>
            <div class="col-md-12 col-xxl-6" *ngIf="shipment?.payer">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: {title: 'Tomador do Serviço',
              name: shipment?.payer?.name,
              document: shipment?.payer?.document?.number,
              postalCode: shipment?.payer?.address?.postalCode,
              addressName: shipment?.payer?.address?.city,
              addressNameFull: shipment?.payer?.address } }"
              ></ng-container>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <ion-card
        color="light"
        class="mt-0"
        *ngIf="documents$ | async as documents; else itemLoading"
      >
        <ion-card-header>
          <app-h2-icon>Documentos de Transporte</app-h2-icon>
        </ion-card-header>
        <ion-card-content *ngIf="documents?.length > 0">
          <div class="row">
            <div
              class="col-12 col-md-4 col-xxl-3"
              *ngFor="let document of documents"
            >
              <ng-container
                [ngTemplateOutlet]="itemDocument"
                [ngTemplateOutletContext]="{ $implicit:
              {title: getInvoiceDescription(document.type, document.number)+'/'+(document.series ?? ''),
              key: getInvoiceKey(document.invoice_br, document.number, document.series),
              amount: document.amount,
              subOrder: document.order.sub_order,
              customerOrder: document.order.customer_order,
              deliveryNumber: document.order.delivery_number,
              shipperPackingList: document.shipper_packing_list

          } }"
              ></ng-container>
            </div>
          </div>
        </ion-card-content>
        <app-empty-content-message
          *ngIf="!(documents?.length > 0)"
          message="Não encontramos resultados para a busca de documentos de transporte."
        ></app-empty-content-message>
      </ion-card>

      <ion-card
        color="light"
        class="mt-0"
        *ngIf="packages$ | async as packages; else itemLoading"
      >
        <ion-card-header>
          <app-h2-icon>Volumes de Transporte</app-h2-icon>
        </ion-card-header>
        <ion-card-content *ngIf="packages?.length > 0">
          <div class="row">
            <div
              class="col-12 col-md-4 col-xxl-3"
              *ngFor="let package of packages"
            >
              <ng-container
                [ngTemplateOutlet]="itemPackage"
                [ngTemplateOutletContext]="{ $implicit: {
               title: package.current_tag,
               quantity: packages?.length,
              temperature: package.packing.temperature,
              weightInformed: package?.packing?.weight?.informed,
              heightInformed: package?.packing?.dimensions?.informed?.height,
              lenghtInformed: package?.packing?.dimensions?.informed?.length,
              widthInformed: package?.packing?.dimensions?.informed?.width,
              weightMeasured: package?.packing?.weight?.measured,
              heightMeasured: package?.packing?.dimensions?.measured?.height,
              lenghtMeasured: package?.packing?.dimensions?.measured?.length,
              widthMeasured: package?.packing?.dimensions?.measured?.width

              } }"
              ></ng-container>
            </div>
          </div>
        </ion-card-content>
        <app-empty-content-message
          *ngIf="!(packages?.length > 0)"
          message="Não encontramos resultados para a busca de documentos de transporte."
        ></app-empty-content-message>
      </ion-card>

      <ion-card
        color="light"
        class="mt-0"
        *ngIf="trackings$ | async as trackings; else itemLoading"
      >
        <ion-card-header>
          <app-h2-icon>Tracking da Remessa</app-h2-icon>
        </ion-card-header>
        <ion-card-content *ngIf="trackings?.length > 0">
          <list-full-width-01>
            <ion-item
              color="light"
              class="pb-1 border-bottom mb-3"
              *ngFor="let tracking of trackings"
              lines="none"
            >
              <item-list-full-width-01>
                <div class="row">
                  <div class="col-12">
                    <app-label-icon-01
                      iconSrc="check"
                      cssClass="text-uppercase"
                    >
                      <span>{{tracking.descricao}}</span>
                    
                      <ion-badge 
                        *ngIf="tracking.trace && tracking.type?.id == ERRO_DE_INTEGRACAO"
                        class="badge-tentativa" 
                        color="danger"
                      >
                      {{tracking.trace}}
                      </ion-badge
                      >
                    </app-label-icon-01>
                  </div>
                  <div class="col-4 col-xl-4">
                    <app-label-icon-01 iconSrc="calendar-check"
                      >{{ tracking.dt_occurrence }}</app-label-icon-01
                    >
                  </div>
                  <div class="col-4 col-xl-6">
                    <app-label-icon-01 iconSrc="user"
                      >{{tracking.user ?? "Sistema"}}</app-label-icon-01
                    >
                  </div>
                  <div class="col-4 col-xl-2 container-actions">
                    <div class="col-2 col-xl-2">
                      <app-label-icon-01
                        *ngIf="tracking.evidences && tracking.evidences.length > 0"
                        iconSrc="images"
                        class="tracking-icon"
                        (click)="openEvidenceModal(tracking.evidences, tracking.dt_occurrence )"
                      ></app-label-icon-01>
                    </div>

                    <div class="col-2 col-xl-2">
                      <app-label-icon-01
                        *ngIf="tracking.place && tracking.place.latitude !== 0 && tracking.place.longitude !== 0"
                        iconSrc="location-dot"
                        class="icon"
                        (click)="openLocalizationModal(tracking.place, tracking.dt_occurrence )"
                      ></app-label-icon-01>
                    </div>
                    <div class="col-2 col-xl-2">
                      <app-label-icon-01
                        *ngIf="tracking.observation"
                        iconSrc="info-circle"
                        class="icon"
                        (click)="openTableModal(tracking.id, tracking.descricao, tracking.observation, tracking.dt_occurrence)"
                      ></app-label-icon-01>
                    </div>
                  </div>
                </div>
              </item-list-full-width-01>
            </ion-item>
          </list-full-width-01>
        </ion-card-content>
        <app-empty-content-message
          *ngIf="!(trackings?.length > 0)"
          message="Não encontramos resultados para a busca de documentos de transporte."
        ></app-empty-content-message>
      </ion-card>

      <!--
<fuse-drawer class="" [mode]="'over'" [name]="'settingsDrawer'" [position]="'right'" #filterDrawer>
    <heading-drawer title="Filtros de Busca">
        <ion-icon (click)="filterDrawer.toggle()" class="ico-close ion-float-right" name="close"></ion-icon>
    </heading-drawer>
    <div class="overflow-auto px-2">
    </div>

    <div class="position-absolute w-100 bottom-0 end-0">
        <div class="container-fluid">
            <div class="row bg-white">
                <div class="col d-flex align-items-center fst-italic ion-color-light">
                    <ion-text color="medium">Limpar campos</ion-text> &nbsp; ou
                </div>
                <div class="col">
                    <ion-button expand="full" fill="solid">Aplicar</ion-button>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button>
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
</ion-fab>
-->
      <quick-actions-right [listOfActions]="listOfActions">
      </quick-actions-right>
    </div>
    <app-empty-content-message
      [style.display]="!shipment.id ? 'block' : 'none'"
      message="Não encontramos resultados para a busca solicitada."
    ></app-empty-content-message>
  </div>
  <!-- LOADING INIT -->

  <!-- LOADING END -->
</ion-content>

<ng-template #itemTemplate let-item>
  <app-h2-icon>{{item.title}}</app-h2-icon>
  <div class="mb-3 mt-2 fs-085">
    <app-label-icon-01 iconSrc="user">{{item.name}}</app-label-icon-01>
    <app-label-icon-01 iconSrc="id-card">{{item.document}}</app-label-icon-01>
    <app-label-icon-01 iconSrc="map-marker-alt"
      >{{item.postalCode}}</app-label-icon-01
    >
    <app-label-icon-01 iconSrc="map-pin"
      >{{item.addressName?.name}} -
      {{item.addressName?.state?.abbr}}</app-label-icon-01
    >
    <app-label-icon-01 iconSrc="map">
      <span class="font-thinner"
        >{{item.addressNameFull?.name}} - {{item.addressNameFull?.neighborhood}}
        - {{item.addressNameFull?.number}}</span
      >
    </app-label-icon-01>
  </div>
</ng-template>

<ng-template #itemDocument let-item>
  <div class="box-info text-truncate">
    <app-label-bullet>{{ item.title ?? "-"}}</app-label-bullet>
    <app-label-bullet>Chave {{ item.key ?? "-"}}</app-label-bullet>
    <app-label-bullet>{{ item.amount | currencyFormatBRL }}</app-label-bullet>
    <app-label-bullet>Pedido {{ item.customerOrder ?? "-" }}</app-label-bullet>
    <app-label-bullet>Entrega {{ item.subOrder ?? "-" }}</app-label-bullet>
    <app-label-bullet>Transporte {{ item.deliveryNumber ?? "-" }}</app-label-bullet>
    <app-label-bullet>Carga {{ item.shipperPackingList ?? "-" }}</app-label-bullet>
  </div>
</ng-template>

<ng-template #itemPackage let-item>
  <div class="box-info text-truncate">
    <app-label-bullet>Etiqueta: {{item.title}}</app-label-bullet>
    <app-label-bullet *ngIf="item.weightInformed"
      >Peso Inf: {{item.weightInformed}}Kg | {{item.heightInformed | number :
      '1.2-2' }}x{{item.lenghtInformed | number : '1.2-2'
      }}x{{item.widthInformed | number : '1.2-2' }}cm</app-label-bullet
    >
    <app-label-bullet *ngIf="item.weightMeasured"
      >Peso Afe: {{item.weightMeasured}}Kg | {{item.heightMeasured | number :
      '1.2-2' }}x{{item.lenghtMeasured | number : '1.2-2'
      }}x{{item.widthMeasured | number : '1.2-2' }}cm</app-label-bullet
    >
  </div>
</ng-template>

<ng-template #itemLoading let-item>
  <ion-card color="light" class="mt-0">
    <ion-card-header>
      <app-h2-icon>
        <ion-skeleton-text
          animated
          style="width: 10%; height: 10px; margin: 10px 40px"
        >
        </ion-skeleton-text>
      </app-h2-icon>
    </ion-card-header>
    <ion-grid>
      <div class="container-body">
        <ion-row class="loading" *ngFor="let body of [1]">
          <ion-skeleton-text
            animated
            style="width: 20%; height: 30px; margin: 10px 40px"
          >
          </ion-skeleton-text>
          <ion-skeleton-text
            animated
            style="width: 20%; height: 30px; margin: 10px 40px"
          >
          </ion-skeleton-text>
          <ion-skeleton-text
            animated
            style="width: 20%; height: 30px; margin: 10px 40px"
          >
          </ion-skeleton-text>
        </ion-row>
      </div>
    </ion-grid>
  </ion-card>
</ng-template>
