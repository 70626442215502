<!-- TABLE INIT -->
<ion-content
  scroll-y="false"
  *ngIf="table?.headers.length > 0 && table?.bodies.length > 0"
  [style.display]="!loading ? 'block' : 'none'"
>
  <!-- *ngIf="table?.headers.length > 0 && table?.bodies.length > 0" -->
  <ion-grid>
    <!-- TABLE HEADER INIT -->
    <div class="container-header">
      <ion-row class="container-row">
        <ion-col *ngFor="let header of table?.headers">
          <p>{{ header.name }}</p>
        </ion-col>
      </ion-row>
    </div>
    <!-- TABLE HEADER END -->
    <!-- TABLE BODY INIT -->
    <ion-content>
      <div class="container-body">
        <ion-row
          class="container-row"
          *ngFor="let body of table?.bodies"
          routerLink="{{ redirectUrl }}/{{ body.id }}"
        >
          <ion-col
            class="container-column"
            *ngFor="let content of body.content; index as i"
          >
            <app-paragraph-multi-size
              *ngFor="let value of content.value; index as i"
              size="sm-0"
              text="{{ !!value ? value : ' - ' }}"
              class="content-row"
            >
            </app-paragraph-multi-size>
          </ion-col>
        </ion-row>
      </div>
      <ng-content></ng-content>
    </ion-content>
    <!-- TABLE BODY END -->
  </ion-grid>
</ion-content>
<!-- TABLE END -->

<!-- TABLE LOADING INIT -->
<ion-grid [style.display]="loading ? 'block' : 'none'">
  <ion-row class="loading">
    <ion-col *ngFor="let header of [1, 2, 3, 4, 5]">
      <ion-skeleton-text animated style="width: 40%; margin: 20px 40px">
      </ion-skeleton-text>
    </ion-col>
  </ion-row>
  <div class="container-body">
    <ion-row class="loading" *ngFor="let body of [1, 2, 3, 4, 5, 6]">
      <ion-skeleton-text animated style="width: 100%; margin: 20px 40px">
      </ion-skeleton-text>
    </ion-row>
  </div>
</ion-grid>
<!-- TABLE LOADING END -->

<!-- TABLE EMPTY CONTENT INIT -->
<!-- <ion-grid *ngIf="!(table?.bodies.length > 0) && !loading">
  <app-empty-content-message
    *ngIf="!(table?.bodies.length > 0)"
    message="Não encontramos resultados para a busca solicitada."
  ></app-empty-content-message>
</ion-grid> -->
<!-- TABLE EMPTY CONTENT END -->
