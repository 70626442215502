import { createSelector } from '@ngrx/store';
import { IAppGlobalSettings, IAppGlobalState, IAppTemplateState } from './app-global-model';

export const selectAppGlobalSettings = (state: IAppGlobalState) => {
    //console.log(state);
    return state.globalSettings;
} 

export const selectOrgAndServiceCenter = createSelector(
    selectAppGlobalSettings, 
    (state: IAppGlobalSettings) => {
        return {
            serviceCenterSelected: state.serviceCenterSelected,
            organization: state.organization
        };        
    }
);

export const selectOrganization = createSelector(
    selectAppGlobalSettings, 
    (state: IAppGlobalSettings) => {
        return state.organization;
    }
);

export const selectTemplate = createSelector(
    selectAppGlobalSettings, 
    (state: IAppGlobalSettings) => {
        return state.template;
    }
);

export const selectTemplateMessage = createSelector(
    selectTemplate, 
    (state: IAppTemplateState) => {
        return {
            isLoading: state?.isLoading,
            appMessage: state?.appMessage
        };        
    }
);

