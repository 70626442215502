export class AuthError extends Error {

    /**
     * 
     * @param message 
     */
    constructor(message) {
        super(message); 
        this.name = "AuthError";
    }
}