<!-- TABLE INIT -->
<ion-grid *ngIf="table?.headers.length > 0 && table?.bodies.length > 0">
  <!-- TABLE HEADER INIT -->
  <ion-row class="container-row">
    <ion-col *ngFor="let header of table?.headers">
      <app-header
        size="sm"
        text="{{ header.name }}"
        letter-spacing="tight"
      ></app-header>
    </ion-col>
  </ion-row>
  <!-- TABLE HEADER END -->
  <!-- TABLE BODY INIT -->
  <div class="container-body">
    <ion-row class="container-row" *ngFor="let body of table?.bodies">
      <ion-col *ngFor="let content of body.content; index as i">
        <app-paragraph-multi-size
          routerLink="{{ redirectUrl }}/{{ body.id }}"
          size="sm"
          text="{{ content.value }}"
          class="content-row"
        ></app-paragraph-multi-size>
      </ion-col>
    </ion-row>
  </div>
  <!-- TABLE BODY END -->
</ion-grid>
<!-- TABLE END -->

<!-- TABLE LOADING INIT -->
<ion-grid *ngIf="!(table?.bodies.length > 0) && loading">
  <ion-row class="loading">
    <ion-col *ngFor="let header of [1, 2, 3, 4, 5]">
      <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
      </ion-skeleton-text>
    </ion-col>
  </ion-row>
  <div class="container-body">
    <ion-row class="loading" *ngFor="let body of [1, 2, 3, 4, 5, 6]">
      <ion-skeleton-text animated style="width: 100%; margin: 10px 40px">
      </ion-skeleton-text>
    </ion-row>
  </div>
</ion-grid>
<!-- TABLE LOADING END -->

<!-- TABLE EMPTY CONTENT INIT -->
<!-- <ion-grid *ngIf="!(table?.bodies.length > 0) && !loading">
  <app-empty-content-message
    *ngIf="!(table?.bodies.length > 0)"
    message="Não encontramos resultados para a busca solicitada."
  ></app-empty-content-message>
</ion-grid> -->
<!-- TABLE EMPTY CONTENT END -->
