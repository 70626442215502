import { Component, Input } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Table } from "../../tables/table.interfaces";

@Component({
  selector: "app-modal-table",
  templateUrl: "./modal-table.component.html",
  styleUrls: ["./modal-table.component.scss"],
})
export class ModalTableComponent {
  @Input() title: string;
  @Input() canClose: boolean = true;
  @Input() callback: Function;
  @Input() table: Table;

  constructor(
    private modalController: ModalController,
    public navParams: NavParams
  ) {}

  closeModal() {
    this.modalController.dismiss();
    if (this.canClose) {
      if (this.callback) {
        this.callback();
        return;
      }
      this.modalController.dismiss();
    }
  }
}
