import { createAction, props } from '@ngrx/store';
import { IAppGlobalState } from '../../app-state/store/app-global-model';

export enum ActionTypes {
    APP_HYDRATE = "[APP_HYDRATE] APP_HYDRATE",
    APP_HYDRATE_SUCCESS = "[APP_HYDRATEL] APP_HYDRATE_SUCCESS",
    APP_HYDRATE_FAIL = "[APP_HYDRATEL] APP_HYDRATE_FAIL"
}


export const AppHydrate = createAction(
    ActionTypes.APP_HYDRATE
);

export const AppHydrateSuccess = createAction(
    ActionTypes.APP_HYDRATE_SUCCESS,
    props<{ state: IAppGlobalState  }>()
);

export const AppHydrateFail = createAction(
    ActionTypes.APP_HYDRATE_FAIL
);