import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppMessage } from '../../../../../app-core/libs/UTILEntities/app-message';
import { BaseStore } from '../../../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../../../../app-modules-core/app-state/store/app-global-model';
import { MessageBoxForgotPassword, MessageBoxLogin, SideblockMessageTypes } from '../../../../../app-modules-core/auth/auth-messages';
import { selectStateAuthSidebar } from '../../../../../app-modules-core/auth/store/app-auth-state.selectors';

@Component({
  selector: 'app-sign-in-sideblock',
  templateUrl: './sign-in-sideblock.component.html',
  styleUrls: ['./sign-in-sideblock.component.css']
})
export class SignInSideblockComponent implements OnInit {

    //State Observables
    sidebarMessage$: Observable<string>;    

    //Props
    message: AppMessage = MessageBoxLogin;

    /**
     * Constructor
     */
    constructor(private store: BaseStore<IAppGlobalState>) { 
        this.sidebarMessage$ = store.pipe(select(selectStateAuthSidebar));
    }

    //
    private listenerChangeMessage(){

        this.sidebarMessage$.subscribe( (sidebarMessage) => {
            //console.log(`appAuthState.subscribe`);
            //console.log(sidebarMessage);

            if(sidebarMessage == "login" && this.message.id != MessageBoxLogin.id){
                this.message = MessageBoxLogin;
                return;
            }

            if(sidebarMessage != "login"  && this.message.id != MessageBoxForgotPassword.id){
                this.message = MessageBoxForgotPassword;
                return;
            }
        })
    }

    //
    ngOnInit(): void {
        //console.log(`ngOnInit SignInSideblockComponent`);
        this.listenerChangeMessage();
    }

}
