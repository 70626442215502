import { Component, Input } from "@angular/core";
import { Table, TableBody, TableHeader } from "../table.interfaces";

@Component({
  selector: "app-table-default",
  templateUrl: "./table-default.component.html",
  styleUrls: ["./table-default.scss"],
})
export class TableComponent {
  @Input() headers: TableHeader[];
  @Input() bodies: TableBody[];
  @Input() table: Table;
  @Input() loading: Boolean = false;
  @Input() redirectUrl: String;

  //Constructor
  constructor() {}
}
