<ion-app>
  <ion-row class="ion-justify-content-between ion-no-margin modal-title-nav">
    <ion-text class="modal-title">
      <app-header size="sm" [text]="title" letter-spacing="wide"></app-header>
    </ion-text>
    <ion-text>
      <ion-col class="text-end close" (click)="closeModal()">X</ion-col>
    </ion-text>
  </ion-row>

  <div>
    <ion-label class="modal-sub-title">Condutor indo coletar na origem</ion-label>
    <ion-label class="modal-sub-title-2">Localização capturada em {{ dtOccurrence }}</ion-label>
  </div>

  <div class="on-map" *ngIf="place">
    <agm-map [latitude]="place.latitude" [longitude]="place.longitude" [zoom]="13" [disableDefaultUI]="false"
      [styles]="styleConfiMap">

      <agm-marker [latitude]="place.latitude" [longitude]="place.longitude" [agmFitBounds]="false"
        [iconUrl]="{url: 'assets/img/app-icon/icon-sinc-map.png'}">
      </agm-marker>

    </agm-map>
  </div>

</ion-app>
