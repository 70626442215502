import { ListModelBase } from "../../../app-core/libs/collections/list-model-base";

export class Service {
  id: number;
  name: string;
  isActive: boolean;

  static fromApi(response: any) {
    const item = new Service();
    item.id = response.id;
    item.name = response.name;
    //fixo como Ativo
    item.isActive = true;
    return item;
  }
}

export class ServiceList extends ListModelBase<Service> {
  listOfItems: Service[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: Service[]) {
    super(initialList);
  }
}
