import { OrganizationInterceptor } from "./../../app-core/http/interceptors/organization.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AuthRequestInterceptor } from "../../app-core/http/interceptors/auth-request.interceptor";
import { AppStorageService } from "../../app-core/storage/app-storage-service";
import { AuthService } from "./services/auth.service";

@NgModule({
  imports: [],
  exports: [],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthRequestInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => {
        //console.log(`host: ${window.location.hostname}`);
        return authService.check();
      },
      deps: [AuthService],
      multi: true,
    },
  ],
})
export class AppAuthModule {}

/*,
{
    provide: APP_INITIALIZER,
    useFactory: appInitializerAuth,
    multi: true,
    deps: [
        AuthService
    ]
}, */

//{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
