import { NgModule } from "@angular/core";
import { BaseStore, BaseStoreModule } from './Store/BaseStore';

@NgModule({
  imports: [],
  providers: [
      BaseStoreModule, 
      BaseStore, 
      //BaseEffectsModule, 
      //BaseEffectActions
    ],
})
export class BaseNGRXModule {}
