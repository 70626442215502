import { ListModelBase } from "../../../app-core/libs/collections/list-model-base";
import { Organization } from "../../organizations/models/organization";

export class Tracking {
  id: number;
  organization: Organization;
  name: string;
  abbr: string;

  /**
   *
   */
  static fromInfo(_id: number, _idOrganization: number, _abbr: string) {
    let sc = new Tracking();
    sc.id = _id;
    sc.organization = new Organization();
    sc.organization.id = _idOrganization;
    sc.abbr = _abbr;
    return sc;
  }

  /**
   *
   * @param response
   */
  static fromApi(response: any) {
    const item = new Tracking();
    item.id = response.id;
    item.organization = Organization.fromApi(response.organization);
    item.abbr = response.abbr;
    item.name = response.name;

    return response;
  }
}

export class TrackingList extends ListModelBase<Tracking> {
  listOfItems: Tracking[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: Tracking[]) {
    super(initialList);
  }
}
