<ion-app>
  <ion-row class="ion-justify-content-between ion-no-margin modal-title-nav">
    <ion-text class="modal-title">
      <app-header size="sm" [text]="title" letter-spacing="wide"></app-header>
    </ion-text>
    <ion-text>
      <ion-col class="text-end close" (click)="closeModal()">X</ion-col>
    </ion-text>
  </ion-row>

  <ion-slides pager="true" [options]="slideOpts">
    <ion-slide *ngFor="let evidence of evidences; index as i">
        <ion-img class="featured-img" [src]="evidence.base64_file" ></ion-img>
        <span class="modal-subtitle-img">Evidência capturada em {{ dtOccurrence }}</span>
    </ion-slide>
  </ion-slides>
</ion-app>
