import { Injectable, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { fromEvent, Observable, Subscription } from "rxjs";
import PlatformType from "./models/PlatformType";

@Injectable({ providedIn: "root" })
export class PlatformIdentifierService {

    public platformType: number = PlatformType.web.value;
    public platformTypeAux: number = PlatformType.web.value;
    public resizeObservable$: Observable<Event>;
    public resizeSubscription$: Subscription;

    constructor(public platformParam: Platform) {
        if (platformParam.width() <= PlatformType.mobile.width) {
            this.platformType = PlatformType.mobile.value;
            this.platformTypeAux = this.platformType;
        }
    }

    public getPlataformType(): any {
        const typeObservable = new Observable(observer => {

            //chamado ao redimensionar a tela
            this.resizeObservable$ = fromEvent(window, 'resize');
            this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {

                if (window.innerWidth <= PlatformType.mobile.width) {
                    this.platformType = PlatformType.mobile.value;
                }

                if (window.innerWidth >= PlatformType.web.width) {
                    this.platformType = PlatformType.web.value;
                }

                if (this.platformTypeAux != undefined && this.platformTypeAux != this.platformType) {
                    location.reload();
                }

                this.platformTypeAux = this.platformType;
            });

            observer.next(this.platformType);

        });

        return typeObservable;
    }
}
