export class ServiceTypesConst {
  private services: Map<Number, string>;

  public static LOGISTICA_REVERSA: Number = 1;
  public static ENTREGA: Number = 2;
  public static ENTREGA_AGENDADA: Number = 3;
  public static ENTREGA_STANDART: Number = 4;
  public static TRANSFERENCIA_DE_CARGA: Number = 5;
  public static AGENDAMENTO: Number = 6;
  public static NOTIFICACAO_EMAIL: Number = 7;
  public static ENTREGA_EXPRESSA: Number = 8;
  public static RETIRA_LOJA: Number = 9;
  public static TROCA_SIMULTANEA: Number = 10;
  public static ENTREGA_SAME_DAY: Number = 11;
  public static LOGISTICA_REVERSA_B2B: Number = 12;
  public static LOGISTICA_REVERSA_B2C: Number = 13;
  public static AVISO_RECEBIMENTO: Number = 14;
  public static NEXT_DAY: Number = 15;
  public static ENTREGA_LEVE: Number = 16;
  public static ENTREGA_PESADA: Number = 17;
  public static ENTREGA_P2P: Number = 18;
  public static ENTREGA_REDESPACHO: Number = 19;
  public static CASH_DELIVERY: Number = 20;
  public static SMART_LABEL: Number = 21;
  public static LOGISTICS: Number = 22;
  public static MALHA_DIRETA_3P: Number = 23;
  public static REDESPACHO_3P: Number = 24;
  public static GROCERY_CONVENTIONAL: Number = 26;
  public static GROCERY_EXPRESS: Number = 27;

  /**
   *
   */
  constructor() {
    this.init();
    this.fill();
  }

  public get(id: Number): string {
    return this.services.get(id);
  }

  public init(): void {
    this.services = new Map<Number, string>();
  }

  private fill() {
    this.services.set(ServiceTypesConst.LOGISTICA_REVERSA, "Logistica Reversa");
    this.services.set(ServiceTypesConst.ENTREGA, "Entrega");
    this.services.set(ServiceTypesConst.ENTREGA_AGENDADA, "Entrega Agendada");
    this.services.set(
      ServiceTypesConst.ENTREGA_STANDART,
      "Entrega Convencional"
    );
    this.services.set(
      ServiceTypesConst.TRANSFERENCIA_DE_CARGA,
      "Transferência de carga"
    );
    this.services.set(ServiceTypesConst.AGENDAMENTO, "Agendamento");
    this.services.set(
      ServiceTypesConst.NOTIFICACAO_EMAIL,
      "Notificacao por email"
    );
    this.services.set(ServiceTypesConst.ENTREGA_EXPRESSA, "Entrega expressa");
    this.services.set(ServiceTypesConst.RETIRA_LOJA, "Retirada na loja");
    this.services.set(ServiceTypesConst.TROCA_SIMULTANEA, "Troca simultânea");
    this.services.set(
      ServiceTypesConst.ENTREGA_SAME_DAY,
      "Entrega no mesmo dia"
    );
    this.services.set(
      ServiceTypesConst.LOGISTICA_REVERSA_B2B,
      "Logistica Reversa (b2b)"
    );
    this.services.set(
      ServiceTypesConst.LOGISTICA_REVERSA_B2C,
      "Logistica Reversa (b2c)"
    );
    this.services.set(
      ServiceTypesConst.AVISO_RECEBIMENTO,
      "Aviso de recebimento"
    );
    this.services.set(ServiceTypesConst.NEXT_DAY, "Próximo dia");
    this.services.set(ServiceTypesConst.ENTREGA_LEVE, "Entrega leve");
    this.services.set(ServiceTypesConst.ENTREGA_PESADA, "Entrega pesada");
    this.services.set(ServiceTypesConst.ENTREGA_P2P, "Entrega P2P");
    this.services.set(
      ServiceTypesConst.ENTREGA_REDESPACHO,
      "Entrega Redespacho"
    );
    this.services.set(ServiceTypesConst.CASH_DELIVERY, "");
    this.services.set(ServiceTypesConst.SMART_LABEL, "");
    this.services.set(ServiceTypesConst.LOGISTICS, "");
    this.services.set(ServiceTypesConst.MALHA_DIRETA_3P, "Malha direta");
    this.services.set(ServiceTypesConst.REDESPACHO_3P, "Redespacho");
    this.services.set(
      ServiceTypesConst.GROCERY_CONVENTIONAL,
      "Mercado Convencional"
    );
    this.services.set(ServiceTypesConst.GROCERY_EXPRESS, "Mercado Expresso");
  }
}
