import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Table } from "../table.interfaces";

@Component({
  selector: "app-table-special-column",
  templateUrl: "./table-special-column.component.html",
  styleUrls: ["./table-special-column.scss"],
})
export class TableSpecialColumnComponent implements OnChanges {
  @Input() table: Table;
  @Input() title: string;
  @Input() loading: Boolean = false;
  @Input() specialColumn = 2;
  @Input() tableSpecialColumnCallback: (id: Number) => Promise<void>;

  public newTable: Table = {
    bodies: [],
    headers: [],
  };

  tableSpecialColumnClick = async (id: Number) => {
    if (this.tableSpecialColumnCallback) {
      await this.tableSpecialColumnCallback(id);
      return;
    }
  };

  //Constructor
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.table) return;
    this.newTable.bodies = this.table.bodies;
    this.newTable.headers = this.table.headers;

    this._validate();
    this._filter();
  }

  private _validate() {
    if (
      !this.table.bodies.every(
        (c) => c.content.length === this.table.headers.length
      )
    ) {
      console.error(
        `TableSpecialColumnComponent - The numbers of 'headers' has to be the same numbers of 'columns'`
      );
    }

    if (this.specialColumn > this.table.headers.length) {
      console.error(
        `TableSpecialColumnComponent - The selected number of 'specialColumn' must be lower than the number of headers`
      );
    }
  }

  private _filter() {
    this.newTable.headers = this.table.headers.filter(
      (header, index) => index !== this.specialColumn - 1
    );

    this.newTable.bodies = this.table.bodies.map((row, firstIndex) => {
      const filtered = row.content.filter((r, secondIndex) => {
        return secondIndex !== this.specialColumn - 1;
      });

      return { content: filtered };
    });

    if (this.specialColumn > this.table.headers.length) {
      this.specialColumn = 1;
    }
  }
}
