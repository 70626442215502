import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { IonicModule } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";

import localeBr from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SharedModule } from "./shared/shared.module";
import { TemplatesModule } from "./shared/components/templates/templates.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppCoreModule } from "./app.module-core";
import { AppAuthModule } from "./app-modules-core/auth/app-auth.module";
import { Drivers } from "@ionic/storage";
import { AppLoggerModule } from "./app-core/logging/app-logger.module";
import * as moment from "moment";

registerLocaleData(localeBr, "pt");
// moment.updateLocale("pt-br", localization);

@NgModule({
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      platform: {
        'desktop': (win) => {
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
          return !isMobile;
        }
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "__sincloguidb",
      driverOrder: [Drivers.IndexedDB, , Drivers.LocalStorage],
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),

    //Auth App Module
    AppLoggerModule,

    //Auth App Module
    AppAuthModule,

    //Stores, Reducers, Interceptors
    AppCoreModule,

    //Forms, Common, Ionic, Router, Global Components
    SharedModule,

    //Templates
    TemplatesModule,
  ],
  declarations: [AppComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar],
  bootstrap: [AppComponent],
  exports: [SharedModule, TemplatesModule],
})
export class AppModule { }
