import { AuthGuard } from "./app-core/guards/auth.guard";
import { NoAuthGuard } from "./app-core/guards/no-auth.guard";
import { OrganizationGuard } from "./app-core/guards/organization.guard";
import { IApplicationRoute } from "./app-core/routes/abstractions/route.interface";
import { AUTH_CHILDREN_ROUTES } from "./app-modules-ui/auth/auth-children.routing";
import { AuthTemplateComponent } from "./shared/components/templates/auth-template/auth-template.component";
import { DefaultContentTemplate } from "./shared/components/templates/default-content/default-content-template";
import { DefaultTemplateComponent } from "./shared/components/templates/default-template/default-template.component";

const APP_ROUTES: IApplicationRoute[] = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./app-modules-ui/home/home-main.module").then(
        (m) => m.HomeMainModule
      ),
    fullPath: `/home`,
    component: DefaultContentTemplate,
    guards: [OrganizationGuard, AuthGuard],
  },
  {
    path: "nav",
    loadChildren: () =>
      import("./app-modules-ui/menus/menus.module").then((m) => m.MenusModule),
    fullPath: `/nav`,
    component: DefaultContentTemplate,
  },
];

export const BASE_ROUTES: IApplicationRoute[] = [
  {
    path: "auth",
    guards: [NoAuthGuard],
    childGuard: [NoAuthGuard],
    component: AuthTemplateComponent,
    data: {
      layout: "empty",
    },
    subRoutes: AUTH_CHILDREN_ROUTES,
  },
  {
    path: "",
    component: DefaultTemplateComponent,
    data: {
      layout: "empty",
    },
    subRoutes: APP_ROUTES,
  },
];
