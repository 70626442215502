import { NgModule } from '@angular/core';
import { AppLoggerService } from './app-logger-service';
import { LogPublishersService } from './logger-publisher';

@NgModule({
    imports: [
    ],
    exports: [
    ],
    providers: [
        AppLoggerService,
        LogPublishersService
    ]
})
export class AppLoggerModule {
}
