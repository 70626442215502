<div [ngSwitch]="size">
  <h1
    *ngSwitchCase="'lg'"
    [ngClass]="{
      'letter-tight': letterSpacing === 'tight',
      'letter-wide': letterSpacing === 'wide',
      'letter-wider': letterSpacing === 'wider'
    }"
  >
    {{ text }}
  </h1>
  <h3
    *ngSwitchCase="'md'"
    [ngClass]="{
      'letter-tight': letterSpacing === 'tight',
      'letter-wide': letterSpacing === 'wide',
      'letter-wider': letterSpacing === 'wider'
    }"
  >
    {{ text }}
  </h3>
  <h6
    *ngSwitchCase="'sm'"
    [ngClass]="{
      'letter-tight': letterSpacing === 'tight',
      'letter-wide': letterSpacing === 'wide',
      'letter-wider': letterSpacing === 'wider'
    }"
  >
    {{ text }}
  </h6>
  <h1 *ngSwitchDefault>{{ text }}</h1>
</div>
