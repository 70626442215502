import { Injectable } from '@angular/core';
import { AppStorageService } from '../../../app-core/storage/app-storage-service';
import { ServiceCenter } from '../models/service-center';

@Injectable({ providedIn: "root" })
export class ServiceCenterStorage {

    //Props
    private readonly KEY_COLLECTION_SERVICE_CENTERS = 'service_centers';

    /**
     * 
     */
    constructor(private storageService: AppStorageService) {
    }

    /**
     * 
     */
     public async get(): Promise<ServiceCenter[]>{
        const jsonValue = await this.storageService.get(this.KEY_COLLECTION_SERVICE_CENTERS);
        const list = JSON.parse(jsonValue);
        return list;
    }

    /**
     * 
     */
     public async save(items: ServiceCenter[]){
        await this.clear();
        return this.storageService.set(this.KEY_COLLECTION_SERVICE_CENTERS, JSON.stringify(items));
    }

    /**
     * 
     * @returns 
     */
    public clear(){
        return this.storageService.remove(this.KEY_COLLECTION_SERVICE_CENTERS);
    }


}

