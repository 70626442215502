import { Observable } from 'rxjs';
import { LogEntry } from './logger.model';

export abstract class LoggerPublisherBase {

    location: string;

    abstract log(record: LogEntry): Observable<boolean>;

    abstract clear(): Observable<boolean>;
}
