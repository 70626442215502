
import { Action, ActionReducer } from '@ngrx/store';
import { IAppGlobalState } from '../../app-state/store/app-global-model';
import * as AppHydrateAcions from './hydrate.actions';


function isHydrateSuccess(action: Action): action is ReturnType<typeof AppHydrateAcions.AppHydrateSuccess> {
    return action.type === AppHydrateAcions.AppHydrateSuccess.type;
}


export const hydrationMetaReducer = (reducer: ActionReducer<IAppGlobalState>): ActionReducer<IAppGlobalState> => {

    return (state, action) => {
        
        if (isHydrateSuccess(action)) {
            return action.state;
        } else {
            return reducer(state, action);
        }
    };
};