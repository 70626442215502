import { AuthGuard } from "../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../app-core/guards/organization.guard";
import { IApplicationRoute } from "../../app-core/routes/abstractions/route.interface";
import { ShipperListPage } from "./pages/list/shipper-list";

export const SHIPPERS_ROUTES: IApplicationRoute[] = [
  {
    path: "shippers",
    loadChildren: () =>
      import("../shippers/shippers.module").then((m) => m.ShippersModule),
    guards: [OrganizationGuard, AuthGuard],
  },
];

export const SHIPPERS_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "",
    component: ShipperListPage,
  },
  {
    path: "list",
    component: ShipperListPage,
    fullPath: `/nav/shippers`,
    icon: "shippers",
    displayName: "Embarcadores",
  },
];
