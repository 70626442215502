import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  from,
  map,
  mergeMap,
  Observable,
  of,
  pipe,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { HttpResponseUtils } from "../../../app-core/libs/UTILHttp/utils/http-response-utils";
import { AuthService } from "../services/auth.service";
import * as AuthActions from "./app-auth-state.actions";
import * as AppActions from "../../app-state/store/app-global.actions";
import { AuthError } from "../models/auth-error";
import { LoadingSettingsApp } from "../../app-state/global-messages";
import * as AuthMgs from "../auth-messages";
import {
  AppMessage,
  AppMessageError,
} from "../../../app-core/libs/UTILEntities/app-message";
import { AppSettingsService } from "../../app-state/services/app-settings.service";
import { TokenService } from "../services/token.service";
import { AppLoggerService } from "../../../app-core/logging/app-logger-service";
import { SettingsSystem } from "../../organizations/models/settings-system";

@Injectable()
export class AuthEffects {
  /**
   *
   * @param actions
   * @param authService
   * @param router
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private logger: AppLoggerService,
    private authService: AuthService,
    private settingsService: AppSettingsService
  ) {}

  // effects go here
  logIn = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.ActionLogin),
        mergeMap((info) => {
          return this._loginRun(info);
        })
      );
    },
    { dispatch: true }
  );

  _loginRun(user: any): Observable<any> {
    return this.authService.signIn(user.username, user.password).pipe(
      switchMap(async (response) => {
        const checkResponse = TokenService.isValidResponse(response);

        if (!checkResponse.isValid) {
          return AuthActions.ActionLoginFailure({
            infoError: new AppMessageError("", checkResponse.textMessage),
          });
        }

        const infoUser = await this.authService.registerResponseToken(response);

        return AuthActions.LoadingSystemSettings({
          user: infoUser,
          appMsg: LoadingSettingsApp,
          isLogin: true,
        });
        //return AuthActions.ActionLoginSuccess({infoProfile: user});
      }),
      catchError(async (res) => {
        this.logger.error("signIn response", res);
        this.logger.debug("signIn response", res);
        this.logger.info("signIn response", res);
        if (res.status == HttpStatusCode.BadRequest) {
          return AuthActions.ActionLoginFailure({
            infoError: AuthMgs.MsgIncorrectLogin,
          });
        }

        if (res instanceof AuthError) {
          return AuthActions.ActionLoginFailure({
            infoError: new AppMessageError("", res.message),
          });
        }

        if (!(res instanceof HttpErrorResponse)) {
          return AuthActions.ActionLoginFailure({
            infoError: AuthMgs.MsgCouldNotReachServer,
          });
        }

        return AuthActions.ActionLoginFailure({
          infoError: new AppMessageError(
            "",
            HttpResponseUtils.getMessageFromError(res)
          ),
        });
      })
    );
  }

  // effects go here
  loadingAppSettings = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.LoadingSystemSettings),
        switchMap((infoAction) => {
          return this._loadingAppSettingsRun(infoAction);
        })
      );
    },
    { dispatch: true }
  );

  _loadingAppSettingsRun(info: any): Observable<any> {
    return from(this.settingsService.loadSystemSettings(info.user)).pipe(
      switchMap((res) => {
        let actions = [];

        //Se houver configuracao de sistema, dispara ação para setup, senão, configura um setup padrão
        const settingsSystem =
          res.settings.id > 0
            ? res.settings
            : SettingsSystem.defaultSettings(res.organization);

        actions.push(
          AppActions.AppSetupOrgSettings({ settings: settingsSystem })
        );
        actions.push(
          AuthActions.ActionLoginSuccess({ infoProfile: info.user })
        );

        return actions;
      }),
      catchError((err: any) => {
        console.error(err);
        return of(
          AuthActions.ActionLogout({
            infoError: new AppMessageError(
              "",
              "Não foi possível obter as configurações para inicializar o sistema."
            ),
          })
        );
      })
    );
  }

  // effects
  loginSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.ActionLoginSuccess),
        tap((actionProps) => {
          console.log("EFFECT loginSuccess");
          console.log(actionProps);
          //this.authService.setAuthLocal(actionProps.infoProfile);
          this.router.navigateByUrl("/home", { replaceUrl: true });
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Do logout and fire AppResetSettingsAction
   */
  logout = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.ActionLogout),
        mergeMap((info) => {
          return this._logoutRun(info);
        })
      );
    },
    { dispatch: true }
  );

  _logoutRun(info: any): Observable<any> {
    return from(this.authService.signOut()).pipe(
      switchMap((res) => {
        return [
          AuthActions.ActionLogoutSuccess({ infoError: info.infoError }),
          AppActions.AppResetSettings(),
        ];
      }),
      catchError((err: any) => {
        console.error(err);
        return of(false);
      })
    );
  }

  // effects
  logoutSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.ActionLogoutSuccess),
        tap(() => {
          console.log("EFFECT LOGOUT SUCCESS");
          this.router.navigateByUrl("/auth/login", { replaceUrl: true });
        })
      );
    },
    { dispatch: false }
  );
}
