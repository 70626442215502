import { Component, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../../../../app-modules-core/app-menu/models/menu-item";
import { MenuService } from "../../../../app-modules-core/app-menu/service/menu.service";
import { OPERATIONS_DASH_CHILDREN_ROUTES } from "../../../operations-dash/operations-dash.routing";

@Component({
  selector: "app-shipments-operations-nav",
  templateUrl: "shipments-operations-nav.html",
  styleUrls: ["./shipments-operations-nav.scss"],
})
export class ShipmentsOperationsNavPage {
  public linksOperations: MenuItem[] = new MenuService().fromApplicationRoute(
    OPERATIONS_DASH_CHILDREN_ROUTES
  );

  constructor(public router: Router) {}
}
