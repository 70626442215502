import { ListModelBase } from '../../../app-core/libs/collections/list-model-base';
import { libArray } from '../../../app-core/libs/collections/util-collection';
import { Organization } from './organization';



export class SettingsSystem{
    id: number;
    organization: Organization;
    theme: string;

    system = new class {
        custom_style: string;
        title_default: string;
        title_min: string;
    };

    header = new class {
        content_default: string;
        content_min: string;
    };

    /**
     * 
     * @param response 
     */
    static fromApi(response: any){

        const item = new SettingsSystem();
        item.id = response.id;
        item.organization = Organization.fromApi(response.organization);
        item.theme = response.theme;

        const system = response.system;
        if(system){
            item.system.custom_style = system.custom_style;
            item.system.title_default = system.title_default;
        }

        const header = response.header;
        if(header){
            item.header.content_default = header.content_default;
        }

        return item;
    }

    static defaultSettings(organization: any){

        const item = new SettingsSystem();
        item.organization = Organization.fromApi(organization);
        item.system.title_default = item.organization.tradeName;

        return item;        
    }

}

export class SettingsSystemList extends ListModelBase<SettingsSystem>{

    listOfItems: SettingsSystem[];

    /**
     * 
     * @param initialList 
     */
    constructor(initialList: SettingsSystem[]){
        super(initialList);
    }

    /**
     * 
     * @param organization 
     * @returns 
     */
    public firstByOrg(organization: Organization){
        
        const results = this.listOfItems.filter(item => item.organization?.id == organization.id);

        if(!results || results.length == 0){
            return this._createDefaultSetting(organization);
        }

        return libArray.first(results);
    }

    /**
     * 
     * @param organization 
     * @returns 
     */
    private _createDefaultSetting(organization: Organization){
        let item = new SettingsSystem();
        item.organization = organization;
        item.system.title_default = `${organization.tradeName} - TMS`;
        return item;
    }
}
