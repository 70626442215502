import { Injectable } from "@angular/core";
import { AppStorageService } from "../../../app-core/storage/app-storage-service";
import { Shipper } from "../models/shipper";

@Injectable({ providedIn: "root" })
export class ShipperStorage {
  //Props
  private readonly KEY_COLLECTION_SERVICES = "shipper";

  /**
   *
   */
  constructor(private storageService: AppStorageService) {}

  /**
   *
   */
  public async get() {
    return JSON.parse(
      await this.storageService.get(this.KEY_COLLECTION_SERVICES)
    );
  }

  /**
   *
   */
  public async save(items: Shipper[]) {
    await this.clear();
    return this.storageService.set(
      this.KEY_COLLECTION_SERVICES,
      JSON.stringify(items)
    );
  }

  /**
   *
   * @returns
   */
  public clear() {
    return this.storageService.remove(this.KEY_COLLECTION_SERVICES);
  }
}
