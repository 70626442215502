import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { NumberList } from "../../../app-core/libs/collections/list-model-base";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { LoadingService } from "../../../app-core/services/loadings/loading.service";
import { PaginationOptions } from "../../../shared/components/molecules/paginations/Pagination/models/pagination-options";
import { Shipment, ShipmentList } from "../models/shipment";
import { StatusFilter } from "../models/status";

@Injectable({ providedIn: "root" })
export class ShipmentsSearch extends ApiBaseService {
  //Props
  private readonly urlShipments = `${environment.hosts.bff.shipments}`;
  public isLoading$: Observable<Boolean>;
  private paginationOptions: PaginationOptions = new PaginationOptions();
  /**
   *
   * @param http Constructor
   */
  constructor(protected http: BaseHttpClient, private loading: LoadingService) {
    super(http);
    this.isLoading$ = loading.loading$;
  }

  /**
   *
   */
  public findAll(filter: StatusFilter) {
    const listFromApi = this.fetch(filter);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(filter: StatusFilter): Observable<any> {
    const options = {
      headers: this.httpHeaderDefault,
      params: this._queryProducer(filter),
    };

    this.loading.show();

    const result = this.http.get(`${this.urlShipments}`, options).pipe(
      switchMap((rawData: any) => {
        let list = rawData.map((item) => Shipment.fromApi(item));
        this.loading.hide();
        let result = new ShipmentList(list);
        return of(result);
      }),
      catchError((err) => {
        console.log(err);
        this.loading.hide();
        return of([]);
      })
    );
    return result;
  }

  /**
   * Apply filters
   * @param listOfItems
   * @param orgs
   * @returns
   */
  private _filter(listOfItems: ShipmentList, orgs: number[]) {
    let filteredList = listOfItems.getAll;

    if (!libArray.isEmpty(orgs)) {
      filteredList = filteredList.filter(
        (x) => orgs.indexOf(x.organization.id) >= 0
      );
    }

    return filteredList;
  }

  /**
   *
   * @param filter
   * @returns HttpParams
   */
  private _queryProducer(filter: StatusFilter): HttpParams {
    let sendParams = new HttpParams();

    if (!libArray.isEmpty(filter.idsService)) {
      filter.idsService.forEach((item) => {
        sendParams = sendParams.append("idsService", item);
      });
    }

    if (!libArray.isEmpty(filter.idsStatus)) {
      filter.idsStatus.forEach((item) => {
        sendParams = sendParams.append("idsStatus", item);
      });
    }

    if (!libArray.isEmpty(filter.ids)) {
      filter.ids.forEach((item) => {
        sendParams = sendParams.append("ids", item);
      });
    }

    if (!libArray.isEmpty(filter.shipperDocuments)) {
      filter.shipperDocuments.forEach((item) => {
        sendParams = sendParams.append("shipperDocuments", item);
      });
    }

    sendParams = sendParams.append("idOrganization", filter.org);
    sendParams = sendParams.append("_limit", filter.limit);
    sendParams = sendParams.append("_offset", filter.offset);
    sendParams = sendParams.append("registerDateIni", filter.startDate);
    sendParams = sendParams.append("registerDateEnd", filter.endDate);
    sendParams = sendParams.append("sortByIdDesc", filter.sortByIdDesc);

    return sendParams;
  }
}
