<div class="heading-page">
    <h4 class="mb-0">
        {{title}}
        <ion-icon 
            (click)="goBack()"
            class="icon-back align-bottom ion-float-right" 
            src="assets/font-awesome/svg/solid/arrow-left.svg">
        </ion-icon>
    </h4>
    <ion-text class="fs-085" *ngIf="subTitle">{{subTitle}}</ion-text>
    <hr class="mt-1" />
</div>
