import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon-text',
    templateUrl: './icon-text.component.html',
    styleUrls: ['./icon-text.scss'],

})
export class IconTextComponent { 

    @Input() iconSrc: string;
    @Input() iconName: string;
    @Input() cssClass: string;

    //Constructor
    constructor(){

    }
}