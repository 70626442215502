import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SidebarDefaultComponent } from './sidebar-default';
import { SidebarHeaderBrandModule } from './sidebar-header-brand/sidebar-header-brand.module';
import { SidebarHeaderUserModule } from './sidebar-header-user/sidebar-header-user.module';
import { SidebarMenuModule } from './sidebar-menu/sidebar-menu.module';

@NgModule({
    declarations: [
        SidebarDefaultComponent
    ],
    imports: [
        SharedModule,
        SidebarHeaderBrandModule,
        SidebarHeaderUserModule,
        SidebarMenuModule
    ],
    exports: [
        SidebarDefaultComponent
    ]
})
export class SidebarDefaultModule {
}
