import { AuthGuard } from "../../../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../../../app-core/guards/organization.guard";
import { IApplicationRoute } from "../../../../app-core/routes/abstractions/route.interface";
import { OPERATIONS_DASH_ROUTES } from "../../../operations-dash/operations-dash.routing";
import { ShipmentsOperationsNavPage } from "./shipments-operations-nav";

export const SHIPMENT_OPERATIONS_ROUTES: IApplicationRoute[] = [
  {
    path: "shipments-operations",
    component: ShipmentsOperationsNavPage,
    guards: [OrganizationGuard, AuthGuard],
    displayName: "Gestão de Remessas",
    icon: "car",
    fullPath: "/nav/shipments-operations",
  },
];
