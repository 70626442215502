import { NgModule } from '@angular/core';
import { AlertMessageComponentModule } from './components/molecules/alert-messages/alert-message.module';

@NgModule({
    imports: [
        AlertMessageComponentModule
    ],
    exports: [
        AlertMessageComponentModule
    ]
})
export class ComponentsGlobalModule{
}
