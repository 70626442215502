import { Injectable } from '@angular/core';

@Injectable()
export class libArray{

    public static isEmpty<T>(items: Array<T>): boolean{
        if(!items){
            return true;
        }

        if(items.length == 0){
            return true;
        }

        const cleanList = items.filter(item => !!item);

        if(cleanList.length == 0){
            return true;
        }        
        
        return false;
    }

    public static isSingle<T>(items: Array<T>): boolean{
        if(this.isEmpty(items)){
            return false;
        }

        return items.length == 1;
    }   
    
    public static isMultiple<T>(items: Array<T>): boolean{
        if(this.isEmpty(items)){
            return false;
        }

        return items.length > 1;
    }
    
    public static first<T>(items: Array<T>, defaultValue: T = null): T{
        if(this.isEmpty(items)){
            return defaultValue;
        }

        return items[0];
    }     

    /**
     * Split a large array into multiple smaller arrays
     * @param inputValue 
     * @returns 
     */
    public toSmallerLists(originalList: any[], limitOfSizeList: number): any[]{

        if(originalList.length == 0){
            return originalList;
        }

        let listOfSubLists = [];

        if(originalList.length <= limitOfSizeList){
            listOfSubLists.push(originalList);
            return listOfSubLists;
        }

        const totalOfSublists = Math.ceil(originalList.length / limitOfSizeList);

        for (let i = 0; i < totalOfSublists; i++) {
            let chunkArray = originalList.splice(0, limitOfSizeList);
            listOfSubLists.push(chunkArray);
            // do whatever
        }

        return listOfSubLists;
    }
}