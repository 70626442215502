import { Component, OnDestroy, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppMessage } from '../../../../app-core/libs/UTILEntities/app-message';
import { AppLoaderService } from '../../../../app-core/libs/UTILIonic/services/app-ionic-loader';
import { BaseStore } from '../../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState, IAppTemplateState } from '../../../../app-modules-core/app-state/store/app-global-model';
import { selectTemplateMessage } from '../../../../app-modules-core/app-state/store/app-global.selectors';

@Component({
    selector: 'app-auth-template',
    templateUrl: './auth-template.component.html',
    styleUrls: ['./auth-template.component.scss']
})
export class AuthTemplateComponent implements OnInit {

    //Propertiers
    protected infoTemplate$: Observable<{isLoading: boolean, appMessage: IAppMessage}>;
    public template:IAppTemplateState;

    
    /**
     * 
     */
    constructor(private store: BaseStore<IAppGlobalState>,
                private serviceLoader: AppLoaderService) { 

        this.infoTemplate$ = this.store.pipe(select(selectTemplateMessage));
    }

    /**
     * 
     */
    ngOnInit(): void {
        //this.store.dispatch(AppLoadingSystemSettings({user: null, appMsg: LoadingSettingsApp}));
        this.listenerTemplate();
    }

    /**
     * 
     */
     listenerTemplate(): void{
        this.infoTemplate$.subscribe( (info) => {
            
            if(info.isLoading){
                this.serviceLoader.create(info.appMessage.description);
                return;
            }

            if(info.isLoading === false){
                this.serviceLoader.closeWithRetry();
            }
        })
    }    

}
