<p
  class="ion-no-margin"
  [ngClass]="{
    'small-0': size === 'sm-0',
    small: size === 'sm',
    medium: size === 'md',
    large: size === 'lg'
  }"
>
  {{ text }}
</p>
