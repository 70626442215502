import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { BaseHttpClient } from "../../../../app-core/http/abstractions/base-http-client";
import { ApiBaseService } from "../../../../app-core/services/api-base-service";
import { LoadingService } from "../../../../app-core/services/loadings/loading.service";

@Injectable({ providedIn: "root" })
export class EventSearch extends ApiBaseService {
  //Props
  private readonly urlEvents = `${environment.hosts.bff.events}`;
  public isLoading$: Observable<Boolean>;
  /**
   *
   * @param http Constructor
   */
  constructor(protected http: BaseHttpClient, private loading: LoadingService) {
    super(http);
    this.isLoading$ = this.loading.loading$;
  }

  /**
   *
   */
  public find(id: number[], org: number) {
    console.log(id);
    const listFromApi = this.fetch(id, org);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(id: number[], org: number): Observable<any> {
    let sendParams = new HttpParams();

    id.forEach((item) => {
      sendParams = sendParams.append("ids", item);
    });
    sendParams = sendParams.append("idOrganization", org);
    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http.get(this.urlEvents, options).pipe(
      switchMap((rawData: any) => {
        let result = rawData;
        this.loading.hide();
        return of(result);
      }),
      catchError((err) => {
        console.log(err);
        this.loading.hide();
        return of([]);
      })
    );
    return result;
  }
}
