import { Injectable } from '@angular/core';
import { AppStorageService } from '../../../app-core/storage/app-storage-service';
import { OrganizationStorage } from '../../organizations/services/organization.storage';
import { SettingsOrganizationStorage } from '../../organizations/services/settings-organization.storage';
import { User } from '../models/user';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class AuthStorageService {

    //Keys
    private readonly KEY_USER_TOKEN = "sincustok";
    private readonly KEY_USER_TOKEN_REFRESH = "sincustokref";
    private readonly KEY_USER = "sincuseinf";

    /**
     * 
     */
    constructor(private storageService: AppStorageService,
                private orgStorage: OrganizationStorage,
                private settingsStorage: SettingsOrganizationStorage) { 

    }

    public async saveUser(userInfo: User) {
        await this.storageService.remove(USER_KEY);
        await this.storageService.set(this.KEY_USER, JSON.stringify(userInfo));        
    }

    /*
    public async getUser(): Promise<any> {
        return await JSON.parse(this.storage.get(USER_KEY));
    }
    */

    /**
     * Save user info locally
     * @param response 
     */
     public async saveAuthInfo(response: any, userInfo: User){

        // Store tokens and user infos in storage
        await this.storageService.set(this.KEY_USER_TOKEN, response.access_token);
        await this.storageService.set(this.KEY_USER_TOKEN_REFRESH, response.refresh_token);
        await this.saveUser(userInfo);        
    }

    /**
     * 
     */
    public async clearAuthInfo() {
        await this.storageService.remove(this.KEY_USER_TOKEN);
        await this.storageService.remove(this.KEY_USER_TOKEN_REFRESH);
        await this.storageService.remove(this.KEY_USER);
        await this.orgStorage.clear();
        await this.settingsStorage.clear();
    }

    /**
     * 
     * @returns 
     */
    public async getToken(): Promise<string> {
        return await this.storageService.get(this.KEY_USER_TOKEN);
    }

    /**
     * 
     * @returns 
     */
    public async getRefreshToken(): Promise<string> {
        return await this.storageService.get(this.KEY_USER_TOKEN_REFRESH);
    }

    /**
     * 
     * @returns 
     */
     public async getUser(): Promise<User> {
        const jsonValue = await this.storageService.get(this.KEY_USER);
        const item = User.fromJson(jsonValue);
        return item;
    }    

}