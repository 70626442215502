import { Injectable } from '@angular/core';
import { AppStorageService } from '../../../app-core/storage/app-storage-service';
import { Organization } from '../models/organization';

@Injectable({ providedIn: "root" })
export class OrganizationStorage {

    //Props
    private readonly KEY_COLLECTION_ORGANIZATIONS = 'organizations';

    /**
     * 
     */
    constructor(private storageService: AppStorageService) {
    }

    /**
     * 
     */
     public get(){
         return this.storageService.get(this.KEY_COLLECTION_ORGANIZATIONS);
    }

    /**
     * 
     */
     public async save(items: Organization[]){
        await this.clear();
        return this.storageService.set(this.KEY_COLLECTION_ORGANIZATIONS, JSON.stringify(items));
    }

    /**
     * 
     * @returns 
     */
    public clear(){
        return this.storageService.remove(this.KEY_COLLECTION_ORGANIZATIONS);
    }


}

