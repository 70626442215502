<app-heading-page title="Provedores de Entrega">
</app-heading-page>

<ion-card color="light" class="mt-0">
    <ion-card-content>
        <list-full-width-01>
            <ion-item-sliding *ngFor="let item of listOfShippingAgents">
                <ion-item color="light">
                    <item-list-full-width-01>
                        <div class="row">
                            <div class="col-9">
                                <span>#{{item.id}}</span>&nbsp;&nbsp;
                                <span>{{item.tradeName}}</span>
                            </div>
                            <div class="col-3 text-end">
                                <ion-badge class="fw-light" [color]="item.isActive? 'success': 'danger'">
                                    {{item.isActive? 'Ativo': 'Inativo'}}
                                </ion-badge>
                            </div>
                        </div>
                    </item-list-full-width-01>
                </ion-item>
                <ion-item-options class="options-actions-grid" side="end">
                    <ion-item-option color="danger">Excluir</ion-item-option>
                </ion-item-options>
            </ion-item-sliding>
        </list-full-width-01>

    </ion-card-content>
</ion-card>

<!-- 
<fuse-drawer class="" [mode]="'over'" [name]="'settingsDrawer'" [position]="'right'" #filterDrawer>
    <heading-drawer title="Filtros de Busca">
        <ion-icon (click)="filterDrawer.toggle()" class="ico-close ion-float-right" name="close"></ion-icon>    
    </heading-drawer>
    <div class="overflow-auto px-2">
    </div>

    <div class="position-absolute w-100 bottom-0 end-0">
        <div class="container-fluid">
            <div class="row bg-white">
                <div class="col d-flex align-items-center fst-italic ion-color-light">
                    <ion-text color="medium">Limpar campos</ion-text> &nbsp; ou
                </div>
                <div class="col">
                    <ion-button expand="full" fill="solid">Aplicar</ion-button>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>

<quick-actions-right [listOfActions]="listOfActions">
</quick-actions-right>

-->
<ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button>
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
</ion-fab>
