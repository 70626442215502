<app-heading-page
  title="Gestão Engajamento"
  subTitle="Aqui vamos acompanhar se as remessas que nos são confiadas, estão sendo alocados com um provedor de entregas."
>
</app-heading-page>
<ion-content>
  <div *ngIf="combinedEngage$ | async as combined">
    <div *ngIf="(signalr.isLoading$ | async) === false">
      <app-table-special-column
        *ngIf="combined.engage"
        class="ion-hide-md-down"
        [table]="combined.engage"
        specialColumn="1"
        title="TOTALIZADOR DE REMESSAS"
        [tableSpecialColumnCallback]="openEngageDetail"
      ></app-table-special-column>
      <app-table-vertical
        *ngIf="combined.engage"
        [table]="combined.engage"
        class="ion-hide-md-up"
        [tableVerticalCallback]="openEngageDetail"
      ></app-table-vertical>
      <app-empty-content-message *ngIf="!combined.engage">
      </app-empty-content-message>
    </div>
  </div>

  <app-loading-table-special-column
    class="ion-hide-md-down"
    *ngIf="signalr.isLoading$ | async"
  >
  </app-loading-table-special-column>

  <app-loading-table-vertical
    class="ion-hide-md-up"
    *ngIf="signalr.isLoading$ | async"
  ></app-loading-table-vertical>
</ion-content>
