<!-- TABLE INIT -->
<ion-grid *ngIf="table?.headers?.length > 0 && table?.bodies?.length > 0">
  <ion-col>
    <div class="container-body">
      <ion-row class="container-row" *ngFor="let body of table.bodies">
        <ion-row
          class="container-nested-row"
          *ngFor="let content of body.content; index as i"
          (click)="click(content.value)"
        >
          <app-header
            size="sm"
            text="{{ table.headers[i].name + ' :' }}"
            letter-spacing="tight"
            style="height: 30px"
          ></app-header>
          <app-paragraph-multi-size
            size="sm"
            text="{{ content.value }}"
            class="content-row"
            style="height: 30px"
          ></app-paragraph-multi-size>
        </ion-row>
      </ion-row>
    </div>
  </ion-col>
</ion-grid>
