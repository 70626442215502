import { Injectable } from "@angular/core";
import { DropDown } from "./dropdown.interfaces";

@Injectable({ providedIn: "root" })
export class DropdownService {
  buildDropdown = (
    optionsList: any,
    valueKey: string,
    nameKey: string
  ): DropDown => {
    const optionsDropDown: DropDown = {
      options: [],
    };

    if (!optionsList && optionsList?.length === 0) return optionsDropDown;

    optionsList.forEach((option) => {
      optionsDropDown.options.push({
        value: option[valueKey]?.toString(),
        name: option[nameKey]?.toString(),
      });
    });

    return optionsDropDown;
  };
}
