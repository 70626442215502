import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { Evidences } from '../../../../../app-modules-core/trackings/evidences/models/evidences';


@Component({
  selector: 'app-modal-slide',
  templateUrl: './modal-slide.component.html',
  styleUrls: ['./modal-slide.component.scss'],
})
export class ModalSlideComponent implements OnInit {

  @Input() title: string = "Custom modal";
  @Input() canClose: boolean = true;
  @Input() evidences: Evidences[];
  @Input() dtOccurrence: string;
  @Input() callback: Function;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    pager: true,
    scrollbar: true,
    pagination: {
      el: ".swiper-pagination",
      paginationType: "custom",
      type: "bullets",
      clickable: true,
      renderBullet: function (index, className) {
        return `<img class="${className} cmd-img" src="assets/img/icons-svg/spin.svg" />`;
      }
    }
  };

  constructor(private modalController: ModalController) { }

  ngOnInit(): void {
    document.addEventListener('ionSlidesDidLoad', (clickEvent: MouseEvent) => {
      this.alterPaginationImages();
    });

    document.addEventListener('ionSlideTransitionEnd', (clickEvent: MouseEvent) => {
      this.alterPaginationImages();
    });

  }


  closeModal() {
    if (this.canClose) {
      if (this.callback) {
        this.callback();
        return;
      }

      this.alterPaginationImages();
      this.modalController.dismiss();
    }
  }

  alterPaginationImages() {
    let allDotsImages: any = document.querySelectorAll('.cmd-img');

    allDotsImages.forEach((e, i) => {
      this.evidences.forEach((evidence, index_evidence) => {
        if (i == index_evidence) {
          e.setAttribute('src', `${evidence.base64_file}`);
        }
      })
    });
  }

}
