import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { LogPublisherConfig } from './logger.model';
import { LoggerLocalStorage } from './loggers/logger-localstorage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggerPublisherBase } from './logger-publisher-base';
import { LoggerConsole } from './loggers/logger-console';
import { LoggerApi } from './loggers/logger-api';


@Injectable()
export class LogPublishersService {

    //Public properties
    publishers: LoggerPublisherBase[] = [];

    /**
     * 
     */
    constructor(private http: HttpClient) {
        // Build publishers arrays
        this.buildPublishers();
    }

    // Build publishers array
    buildPublishers(): void {

        let logPub: LoggerPublisherBase;

        this.getLoggers().subscribe(response => {
            const listOfPublishers = response.filter(p => p.isActive);

            for (let pub of listOfPublishers) {
             
                switch (pub.loggerName.toLowerCase()) {
                    case "console":
                        logPub = new LoggerConsole();
                        break;
                    case "localstorage":
                        logPub = new LoggerLocalStorage();
                        break;
                    case "webapi":
                        logPub = new LoggerApi(this.http);
                        break;
                }
                
                // Set location of logging
                logPub.location = pub.loggerLocation;

                // Add publisher to array
                this.publishers.push(logPub);
            }
        });

    }

    /**
     * 
     * @returns 
     */
    getLoggers(): Observable<LogPublisherConfig[]> {
        const pathConfigFile = environment.logging.configFile;

        return this.http.get(pathConfigFile)
                        .pipe(
                            map(response => response),
                            catchError(this.handleErrors)
                        );
    }

    /**
     * 
     * @param error 
     * @returns 
     */
    private handleErrors(error: any): Observable<any> {

        let errors: string[] = [];
        let msg: string = "";
        
        msg = "Status: " + error.status;
        msg += " - Status Text: " + error.statusText;
        if (error.json()) {
            msg += " - Exception Message: " + error.json().exceptionMessage;
        }
        errors.push(msg);
        console.error('An error occurred', errors);
        return throwError( () => new Error(errors[0]));
    }    
}