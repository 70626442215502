import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InputFormOkComponent } from './input-form-ok/input-form-ok.component';
import { InputFormErrorComponent } from './input-form-error/input-form-error.component';
import { InlineErrorComponent } from './inline-error/inline-error.component';
import { InlineInfoComponent } from './inline-info/inline-info.component';
import { InlineMessageComponent } from './inline-message/inline-message.component';
import { InlineSuccessComponent } from './inline-success/inline-success.component';
import { InlineProcessingComponent } from './inline-processing/inline-processing.component';


@NgModule({
    declarations: [
        InputFormErrorComponent,
        InputFormOkComponent,
        InlineErrorComponent,
        InlineInfoComponent,
        InlineSuccessComponent,
        InlineProcessingComponent,
        InlineMessageComponent
    ],
    imports: [
        IonicModule,
        CommonModule
    ],
    exports: [
        InputFormErrorComponent,
        InputFormOkComponent,
        InlineErrorComponent,
        InlineInfoComponent,
        InlineSuccessComponent,
        InlineProcessingComponent,
        InlineMessageComponent
    ]
})
export class AlertMessageComponentModule { }
