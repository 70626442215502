<!-- TABLE INIT -->
<ion-grid>
  <ion-col>
    <div class="container-body">
      <ion-row class="container-row" *ngFor="let body of [1, 2]">
        <ion-row
          class="container-nested-row"
          *ngFor="let content of [1, 2]; index as i"
        >
          <ion-skeleton-text animated style="width: 20%; margin: 10px 40px">
          </ion-skeleton-text>
          <ion-skeleton-text animated style="width: 20%; margin: 10px 40px">
          </ion-skeleton-text>
        </ion-row>
      </ion-row>
    </div>
  </ion-col>
</ion-grid>
