import { LoadingService } from "./../../services/loadings/loading.service";
import { Injectable } from "@angular/core";
import * as signalR from "@aspnet/signalr"; // or from "@microsoft/signalr" if you are using a new library
import { environment } from "@env/environment";
import { Observable, of, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  // public dataSubject = new Subject<any>();
  public dataSubject = new Subject<SignalrData>();
  private hubConnection: signalR.HubConnection;
  public isLoading$: Observable<Boolean>;
  public handleInterval: any;

  //Props
  private readonly urlSignalR = `${environment.hosts.bff.signalr}`;

  /**
   *
   */
  constructor(private loadingService: LoadingService) {
    if (this.urlSignalR == "" || !this.urlSignalR) {
      throw new Error("a base url must be defined for signalr instance");
    }

    this.isLoading$ = loadingService.loading$;
  }

  public createConnection(route: string) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.urlSignalR}/${route}`)
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 400000;
  }

  public startConnection = (
    route: string,
    hubName: string,
    org: string,
    ids: string[] = null
  ) => {
    this.hubConnection
      .start()
      .then(() => {
        this.connectToHub(hubName, org, ids);
        this.handleInterval = setInterval(
          () => this.connectToHub(hubName, org, ids),
          300000
        );

        console.log("Connection started");
      })
      .catch((err) => {
        console.log("Error while starting connection: " + err);
        this.dataSubject.next({ listOfData: null });
        return err;
      });
  };

  public closeConnection() {
    this.hubConnection.stop().then((c) => {
      console.log("Connection Close");
      this.hubConnection = null;
      clearInterval(this.handleInterval);
    });
  }

  public clearHub(methodName: string) {
    this.hubConnection.off(methodName);
  }

  public connectToHub(
    methodName: string,
    org: string,
    idsDeliveryProviders: string[] = null
  ) {
    try {
      this.loadingService.show();
      if (idsDeliveryProviders != null) {
        this.hubConnection
          .invoke(methodName, org, idsDeliveryProviders)
          .catch(() => {
            this.loadingService.hide();
            this.dataSubject.next({ listOfData: null });
          });
        return;
      }

      this.hubConnection.invoke(methodName, org).catch(() => {
        this.loadingService.hide();
        this.dataSubject.next({ listOfData: null });
      });
    } catch (error) {
      this.loadingService.hide();
    }
  }

  public connectListener = (methodName: string) => {
    this.hubConnection.on(methodName, (data) => {
      if (!!data) {
        this.dataSubject.next({ listOfData: data });
        this.loadingService.hide();
        return;
      }
      this.dataSubject.next({ listOfData: null });
      this.loadingService.hide();
    });
  };

  public connectInvoke = (methodName: string) => {
    try {
      this.loadingService.show();
      this.hubConnection.invoke(methodName);
    } catch (err) {
      console.error(err);
    }
  };
}

export interface SignalrData {
  listOfData: any[];
}
