import { Action, createReducer, on, props } from '@ngrx/store';
import * as AppActions from './app-global.actions';
import { IAppGlobalSettings, IAppGlobalState, IAppTemplateState } from './app-global-model';


//#region REDUCER GLOBAL SETTINGS
const appGlobalSettingsInitial: IAppGlobalSettings = {
    serviceCenterSelected: null,
    organization: null,
    template: null
};


const appGlobalReducerInternal = createReducer(
    appGlobalSettingsInitial,

    on(AppActions.AppSetupOrgSettings, (state, { settings }): IAppGlobalSettings => {
        return {
            ...state,
            organization: settings.organization,
            template: {
                ...state.template,
                title: settings.system.title_default
            }
        };
    }),

    on(AppActions.AppSetupAllOrgs, (state, { }): IAppGlobalSettings => {
        return {
            ...state,
            organization: null,
            template: {
                ...state.template,
                title: 'SINCLOG'
            }
        };
    }),    

    on(AppActions.AppSelectServiceCenter, (state, { serviceCenter }): IAppGlobalSettings => {
        return {
            ...state,
            serviceCenterSelected: serviceCenter
        };
    }),


    on(AppActions.AppResetSettings, (state, {  }): IAppGlobalSettings => {
        return {
            ...state,
            serviceCenterSelected: null,
            organization: null,
            template: null
        };
    })

);

//#endregion REDUCER GLOBAL SETTINGS



export function appGlobalSettingsReducer(state: IAppGlobalSettings | undefined, action: Action) {
    return appGlobalReducerInternal(state, action);
}

