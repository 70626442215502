import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-bullet',
    templateUrl: './label-bullet.component.html',
    styleUrls: ['./label-bullet.scss'],

})
export class LabelBulletComponent { 

    @Input() cssClass: string;
    @Input() title: string;
    @Input() noItalic: boolean;

    //Constructor
    constructor(){

    }
}