import { Component, Input } from "@angular/core";
import { Table, TableBody, TableHeader } from "../table.interfaces";

@Component({
  selector: "app-table-line-break",
  templateUrl: "./table-line-break.component.html",
  styleUrls: ["./table-line-break.scss"],
})
export class TableLineBreakComponent {
  @Input() headers: TableHeader[];
  @Input() bodies: TableBody[];
  @Input() table: Table;
  @Input() loading: Boolean = false;
  @Input() redirectUrl: String;

  //Constructor
  constructor() {}
}
