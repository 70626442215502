<div class="container">
  <div class="col-12">
    <h4 class="mb-0">Acompanhamento de Operações</h4>
    <ion-text class="fs-085"
      >Fluxos de engajamento até a conclusão das entregas</ion-text
    >
    <hr />
  </div>
</div>
<div class="container">
  <div class="row">
    <div
      class="col-xl-2 col-md-3 col-sm-6 col-6"
      *ngFor="let p of linksOperations; let i = index"
    >
      <app-linkbox-image
        [title]="p.title"
        [routerLink]="p.url"
        [iconSVG]="p.icon"
      >
      </app-linkbox-image>
    </div>
  </div>
</div>
<!--
<div class="container mt-3">
  <div class="col-12">
      <h4 class="mb-0">Operações InHouse</h4>
      <ion-text class="fs-085">Processos para registrar chegada de veículos e cargas, além da preparação dos volumes para expedição.</ion-text>
      <hr />
  </div>
</div>
<div class="container">
  <div class="row">
      <div class="col-xl-2 col-md-3 col-sm-6 col-6" *ngFor="let p of linksInHouse; let i = index">
          <app-linkbox-image
              [title]="p.title"
              [iconSVG]="p.icon"
          ></app-linkbox-image>
      </div>
  </div>
</div>

<div class="container mt-3">
  <div class="col-12">
      <h4 class="mb-0">Operações de Last Mile</h4>
      <ion-text class="fs-085">Processos de preparação de volumes e rotas para entrega na última milha</ion-text>
      <hr />
  </div>
</div>
<div class="container">
  <div class="row">
      <div class="col-xl-2 col-md-3 col-sm-6 col-6" *ngFor="let p of linksLastMile; let i = index">
          <app-linkbox-image
              [title]="p.title"
              [iconSVG]="p.icon"
          ></app-linkbox-image>
      </div>
  </div>
</div>
-->

<!-- COLOCAR UM SWIPER COM AVISOS E COMUNICADOS ABAIXO DA MESMA FORMA QUE FAZÍAMOS COM AS RELEASES
<div class="container">
  <div class="col-12">
      <swiper (swiper)="setSwiperInstance($event)" (slideChangeTransitionStart)="onSlideChangeStart()">
          <ng-template swiperSlide>
              <img src="assets/img/ica-slidebox-img-1.png" class="slide-image" />
              <h2 class="slide-title">
                  Welcome to
                  <b>ICA</b>
              </h2>
              <p>
                  The
                  <b>ionic conference app</b> is a practical preview of the ionic framework in action, and a
                  demonstration
                  of proper code
                  use.
              </p>
          </ng-template>

          <ng-template swiperSlide>
              <img src="assets/img/ica-slidebox-img-2.png" class="slide-image" />
              <h2 class="slide-title">What is Ionic?</h2>
              <p>
                  <b>Ionic Framework</b> is an open source SDK that enables developers to build high quality mobile
                  apps
                  with web technologies
                  like HTML, CSS, and JavaScript.
              </p>
          </ng-template>

          <ng-template swiperSlide>
              <img src="assets/img/ica-slidebox-img-3.png" class="slide-image" />
              <h2 class="slide-title">What is Ionic Appflow?</h2>
              <p>
                  <b>Ionic Appflow</b> is a powerful set of services and features built on top of Ionic Framework that
                  brings a totally new
                  level of app development agility to mobile dev teams.
              </p>
          </ng-template>

          <ng-template swiperSlide>
              <img src="assets/img/ica-slidebox-img-4.png" class="slide-image" />
              <h2 class="slide-title">Ready to Play?</h2>
              <ion-button fill="clear" (click)="startApp()">
                  Continue
                  <ion-icon slot="end" name="arrow-forward"></ion-icon>
              </ion-button>
          </ng-template>
      </swiper>

  </div>
</div>
-->
