import { NgModule } from '@angular/core';
import { SignInSideblockModule } from '../../../../app-modules-ui/auth/login/components/sign-in-sideblock/sign-in-sideblock.module';
import { SharedModule } from '../../../shared.module';
import { AuthTemplateComponent } from './auth-template.component';

@NgModule({
    declarations: [
        AuthTemplateComponent
    ],
    imports: [
        SharedModule,
        SignInSideblockModule
        
    ],
    exports: [
        AuthTemplateComponent
    ]
})
export class AuthTemplateModule {
}
