<div class="form-group">
    <ion-label>{{ labelText }}</ion-label>
    <div class="input-group input-group-width">
        <ion-input [(ngModel)]="componentNameStart" class="input-date border-radius-left" type="date"
            id="{{componentNameStart}}" name="{{componentNameStart}}"
            (ngModelChange)="componentNameStartChange.emit(componentNameStart)" value="{{ startValue }}"></ion-input>

        <span class="input-group-addon"> até </span>

        <ion-input [(ngModel)]="componentNameEnd" class="input-date border-radius-right" type="date"
            id="{{componentNameEnd}}" name="{{componentNameEnd}}"
            (ngModelChange)="componentNameEndChange.emit(componentNameEnd)" value="{{ endValue }}"></ion-input>
    </div>
</div>