import { ShipperStorage } from "./shipper.storage";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { Shipper, ShipperList } from "../models/shipper";

@Injectable({ providedIn: "root" })
export class ShipperSearch extends ApiBaseService {
  //Props
  private readonly urlShippers = `${environment.hosts.bff.shippers}`;

  /**
   *
   * @param http
   */
  constructor(
    protected http: BaseHttpClient,
    private storageShipper: ShipperStorage
  ) {
    super(http);
  }

  /**
   *
   */
  public findAndCache({ orgs, term = "" }: { orgs: number[]; term?: string }) {
    var result$ = this.fetch({ orgs: orgs, term: term }).pipe(
      tap(async (values) => {
        await this.storageShipper.save(values.getAll);
        return of(values);
      }),
      catchError((err) => {
        console.log(err);
        return of(new ShipperList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findFromCache({
    orgs,
    term = "",
  }: {
    orgs: number[];
    term?: string;
  }): Observable<ShipperList> {
    const result$ = from(this.storageShipper.get()).pipe(
      switchMap((values) => {
        if (!libArray.isEmpty(values)) {
          return of(new ShipperList(values));
        }

        return this.findAndCache({ orgs, term });
      }),
      catchError((err) => {
        console.log(err);
        return of(new ShipperList([]));
      })
    );

    return result$;
  }

  public find({ orgs, term = "" }: { orgs: number[]; term?: string }) {
    if (libArray.isEmpty(orgs)) {
      return of(new ShipperList([]));
    }

    return this.fetch({ orgs, term });
  }

  private fetch({
    orgs,
    term = "",
  }: {
    orgs: number[];
    term?: string;
  }): Observable<ShipperList> {
    let sendParams = new HttpParams();
    orgs.forEach((item) => (sendParams = sendParams.append("id", item)));
    sendParams = sendParams.append("term", term);

    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http.get(this.urlShippers, options).pipe(
      switchMap((rawData: any[]) => {
        const list = new ShipperList(
          rawData.map((item) => Shipper.fromApi(item))
        );
        return of(list);
      }),
      catchError((err) => of(new ShipperList([])))
    );

    return result;
  }
}
