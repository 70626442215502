<app-heading-page title="Consulta Geral de Remessas"></app-heading-page>

<ion-content scroll-y="false" class="ion-hide-sm-down">
  <ion-card color="light" class="mt-0">
    <ion-card-content>
      <app-empty-content-message
        *ngIf="!(tableService.table.bodies.length > 0) && !(tableService.table.headers.length > 0) && !loading"
        message="Não encontramos resultados para a busca de remessas."></app-empty-content-message>
      <app-table-line-break [loading]="loading" [table]="tableService.table" redirectUrl="/nav/shipments/details/">
      </app-table-line-break>
    </ion-card-content>
  </ion-card>
</ion-content>
<app-pagination class="ion-hide-sm-down" (data)="pagination($event)" [loading]="loading"
  [countData]="countDataShipments">
</app-pagination>


<ion-content class="ion-hide-sm-up">
  <app-table-vertical-line-break [loading]="loading" [table]="tableService.table" redirectUrl="/nav/shipments/details/">
  </app-table-vertical-line-break>

  <!-- Paginação Mobile -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="pagination($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Carregando mais remessas...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<fuse-drawer class="" [mode]="'over'" [name]="'settingsDrawer'" [position]="'right'" #filterDrawer>

  <div class="overflow-auto px-2 no-padding">
    <ion-row class="no-padding">
      <ion-col class="no-padding">
        <heading-drawer title="Filtros de Busca" class="no-padding-heading-drawer">
          <ion-icon (click)="filterDrawer.toggle()" class="ico-close ion-float-right" name="close"></ion-icon>
        </heading-drawer>
      </ion-col>
    </ion-row>
    <ion-row class="no-padding ion-margin-vertical">
      <ion-col class="no-padding">
        <ion-label>Serviços</ion-label>
        <app-dropdown-multi-select [(componentName)]="ddlServices" name="ddlServices" [options_list]="services$"
          placeholder="Selecione">
        </app-dropdown-multi-select>
      </ion-col>
    </ion-row>
    <ion-row class="no-padding ion-margin-vertical">
      <ion-col class="no-padding">
        <ion-label>Status</ion-label>
        <app-dropdown-multi-select [(componentName)]="ddlStatus" name="ddlStatus" [options_list]="status$"
          placeholder="Selecione">
        </app-dropdown-multi-select>
      </ion-col>
    </ion-row>
    <ion-row class="no-padding ion-margin-vertical">
      <ion-col class="no-padding">
        <ion-label>Embarcador</ion-label>
        <app-dropdown-multi-select-auto-complete (data)="getShipperByTerm($event)"
          (dataSelected)="getShippersSelected($event)" [(shippers)]="shippers">
        </app-dropdown-multi-select-auto-complete>
      </ion-col>
    </ion-row>
    <ion-row class="no-padding ion-margin-vertical">
      <ion-col class="no-padding">
        <ion-label>Data de Cadastro</ion-label>
        <app-filter-date-interval [(componentNameStart)]="startDate" [(componentNameEnd)]="endDate"
          startName="startDate" endName="endDate"></app-filter-date-interval>
      </ion-col>
    </ion-row>
  </div>
  <div>
    <ion-row class="no-padding">
      <ion-col class="no-padding">
        <ion-button class="padding-end config-button" color="light" expand="full" (click)="clearFilters()">Limpar campos
        </ion-button>
      </ion-col>
      <ion-col class="no-padding">
        <ion-button class="padding-start config-button" expand="full" (click)="findByFilters()">Aplicar</ion-button>
      </ion-col>
    </ion-row>
  </div>

</fuse-drawer>

<quick-actions-right [listOfActions]="listOfActions"> </quick-actions-right>