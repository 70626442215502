<div
  *ngIf="!blocked"
  class="container-box"
  [ngClass]="{
    success: status === 'success',
    warning: status === 'warning',
    information: status === 'information',
    danger: status === 'danger'
  }"
>
  <ng-content></ng-content>
</div>

<div
  *ngIf="blocked"
  class="container-box blocked ion-justify-content-center ion-align-items-center"
>
  <ion-icon name="ban-outline"></ion-icon>
</div>
