import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

enum AppLoaderState{
    none = 'none',
    creating = 'creating',
    opened = 'opened',
    closing = 'closing',
}


@Injectable({
    providedIn: 'root'
})
export class AppLoaderService {

    //Props
    private _state: AppLoaderState.none | AppLoaderState.creating | AppLoaderState.opened | AppLoaderState.closing = AppLoaderState.none;
    private readonly retryLimit = 10;

    /**
     * 
     * @param loadingController 
     */
    constructor(private loadingController: LoadingController) { 

    }

    get isHidden():boolean{
        return this._state == AppLoaderState.none;
    }

    private hiddenState(){
        this._state = AppLoaderState.none;
    }
    
    get isCreating():boolean{
        return this._state == AppLoaderState.creating;
    }

    private creatingState(){
        this._state = AppLoaderState.creating;
    }
    
    get isOpen():boolean{
        return this._state == AppLoaderState.opened;
    }

    private openState(){
        this._state = AppLoaderState.opened;
    }    
    
    get isClosing():boolean{
        return this._state == AppLoaderState.closing;
    }

    private closingState(){
        this._state = AppLoaderState.closing;
    }    

    /**
     * 
     * @param msg 
     */
    public create(msg: string = 'Carregando...'){

        if(!this.isHidden){
            return;
        }

        this.creatingState();
        this.simpleLoader(msg)
            .then(loader => {
                loader.present()
                      .then(value => {
                          this.openState();
                      })
            });
    }

    /**
     * 
     * @param retryCount 
     */
    public closeWithRetry(retryCount: number = 1){

        if(this.isHidden){
            console.log('LOADER HIDDEN');
            return;
        }

        if(!this.isOpen && retryCount < this.retryLimit){
            console.log('LOADER NOT HIDDEN, NOT OPEN');
            this.closeWithRetry(++retryCount);
            return;
        }

        this.close();
        
    }

    /**
     * 
     * @returns 
     */
    public close(){
        
        if(!this.isOpen){
            console.log('LOADER NOT OPEN');
            return;
        }

        this.closingState();
        this.loadingController.dismiss()
            .then(response => {
                this.hiddenState();
            })
            .catch(response => {
                console.log('Error occured : ', response);
            });

    }

    /**
     * 
     * @param msg 
     */
    private simpleLoader(msg: string = 'Carregando...') {

        return this.loadingController.create({
            message: msg,
            cssClass: 'app-loader-default',
            animated: true,
            spinner: 'lines-sharp',
            keyboardClose: false
        });
    }
}