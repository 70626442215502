import { ListModelBase } from "../../../app-core/libs/collections/list-model-base";

export class Status {
  id: number;
  name: string;

  static fromApi(response: any) {
    const item = new Status();
    item.id = response.id;
    item.name = response.name;
    return item;
  }
}

export class StatusList extends ListModelBase<Status> {
  listOfItems: Status[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: Status[]) {
    super(initialList);
  }
}

export class StatusFilter {
  org: number;
  idsService: number[];
  idsStatus: number[];
  shipperDocuments: number[];
  startDate: string;
  endDate: string;
  sortByIdDesc: boolean;
  ids: number[];
  limit: number = 5;
  offset: number = 0;

  static filter = new StatusFilter();

  static withOrganization(org: number): StatusFilter {
    this.filter.org = org;
    return this.filter;
  }

  static withServices(idsServices: number[]): StatusFilter {
    this.filter.idsService = idsServices;
    return this.filter;
  }

  static withStatus(idsStatus: number[]): StatusFilter {
    this.filter.idsStatus = idsStatus;
    return this.filter;
  }

  static withDates(startDate: string, endDate: string): StatusFilter {
    this.filter.startDate = startDate;
    this.filter.endDate = endDate;
    return this.filter;
  }

  static withSortByIdDesc(sortByIdDesc: boolean): StatusFilter {
    this.filter.sortByIdDesc = sortByIdDesc;
    return this.filter;
  }

  static withPagination(limit: number, offset: number): StatusFilter {
    this.filter.limit = limit;
    this.filter.offset = offset;
    return this.filter;
  }

  static withShipperDocuments(shipperDocuments: number[]): StatusFilter {
    this.filter.shipperDocuments = shipperDocuments;
    return this.filter;
  }
}
