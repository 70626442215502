export abstract class ListModelBase<TEntity>{

    //Props
    protected abstract listOfItems: TEntity[];

    /**
     * Always clear listOfItems
     */
    constructor(initialList: TEntity[]) {
        this.reset();

        if(initialList?.length > 0){
            this.addRange(initialList);
        }
    }

    public get getAll(): TEntity[]{
        return this.listOfItems;
    }


    public get count(): number{
        return (this.listOfItems?.length ?? 0);
    }

    public get isEmpty(): boolean{
        return this.count == 0;
    }    

    public get isSingle(): boolean{
        return this.count == 1;
    }    
    
    public get isMultiple(): boolean{
        return this.count > 1;
    }    

    private first(): TEntity{
        return this.listOfItems[0];
    }

    public firstOrDefault(defaultValue: TEntity = null): TEntity{
        return this.isEmpty? defaultValue: this.first();
    }    

    public addRange(list: TEntity[]): void{
        this.listOfItems = this.listOfItems.concat(list);
    }

    public add(item: TEntity): void{
        this.listOfItems.push(item);
    }

    public reset(): void{
        this.listOfItems = [];
    }

}

export class NumberList extends ListModelBase<number>{

    protected listOfItems: number[];

    /**
     * 
     * @param initialList 
     */
    constructor(initialList: number[]){
        super(initialList)
    }
}

export class StringList extends ListModelBase<string>{

    protected listOfItems: string[];

    /**
     * 
     * @param initialList 
     */
    constructor(initialList: string[]){
        super(initialList)
    }
}