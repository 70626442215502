import { Action, createAction, createReducer, on, props } from '@ngrx/store';
import * as AuthActions from './app-auth-state.actions';
import { IAppAuthState } from './app-auth-state.models';

//#region REDUCER AUTHSTATE
const authInitialState: IAppAuthState = {
    sidebarMessage: 'login',
    isAuthenticated: false,
    isLoading: false,
    message: null,
    profile: null
};


const authReducerInternal = createReducer(
    authInitialState,

    on(AuthActions.ChangeAuthSidebar, (state, { sidebar }): IAppAuthState => {
        return {
            ...state,
            sidebarMessage: sidebar
        };
    }),

    on(AuthActions.ActionLogin, (state, { username, password }): IAppAuthState => {
        return {
            ...state,
            isLoading: true
        };
    }),

    on(AuthActions.LoadingSystemSettings, (state, { user, appMsg }): IAppAuthState => {
        return {
            ...state,
            isLoading: true,
            message: appMsg
        };
    }),
    

    on(AuthActions.ActionLoginSuccess, (state, { infoProfile }): IAppAuthState => {
        return {
            ...state,
            isAuthenticated: true,
            isLoading: false,
            message: null,
            profile: infoProfile

        };
    }),

    on(AuthActions.ActionLoginFailure, (state, { infoError }): IAppAuthState => {
        return {
            ...state,
            isAuthenticated: false,
            isLoading: false,
            profile: null,
            message: infoError
        };
    }),

    on(AuthActions.ActionLogout, (state, { infoError }): IAppAuthState => {
        return {
            ...state,
            isLoading: true,
            message: infoError
        };
    }),    

    on(AuthActions.ActionLogoutSuccess, (state, {infoError }): IAppAuthState => {
        return {
            ...state,
            isLoading: false,
            isAuthenticated: false,
            profile: null,
            message: infoError
        };
    }),      
);

export function appAuthStateReducer(state: IAppAuthState | undefined, action: Action) {
    return authReducerInternal(state, action);
}
