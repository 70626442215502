import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { LoadingService } from "../../../app-core/services/loadings/loading.service";
import { Status, StatusList } from "../models/status";
import { StatusStorage } from "./status.storage";

@Injectable({ providedIn: "root" })
export class StatusSearch extends ApiBaseService {
  //Props
  private readonly urlShipmentStatus = `${environment.hosts.bff.status}`;
  public isLoading$: Observable<Boolean>;
  /**
   *
   * @param http Constructor
   */
  constructor(
    protected http: BaseHttpClient,
    private loading: LoadingService,
    private storageStatus: StatusStorage
  ) {
    super(http);
    this.isLoading$ = loading.loading$;
  }

  /**
   *
   */
  public findAndCache({ ids = [], org }: { ids?: number[]; org: number }) {
    var result$ = this.fetch(ids, org).pipe(
      tap(async (values) => {
        await this.storageStatus.save(values.getAll);
        return of(values);
      }),
      catchError((err) => {
        console.log(err);
        return of(new StatusList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findFromCache({
    ids = [],
    org,
  }: {
    ids?: number[];
    org: number;
  }): Observable<StatusList> {
    const result$ = from(this.storageStatus.get()).pipe(
      switchMap((values) => {
        if (!libArray.isEmpty(values)) {
          return of(new StatusList(values));
        }

        return this.findAndCache({ ids, org });
      }),
      catchError((err) => {
        console.log(err);
        return of(new StatusList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findAll({ ids = [], org }: { ids?: number[]; org: number }) {
    const listFromApi = this.fetch(ids, org);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(ids: number[], org: number): Observable<any> {
    let sendParams = new HttpParams();

    if (!libArray.isEmpty(ids)) {
      ids.forEach((item) => {
        sendParams = sendParams.append("ids", item);
      });
    }
    sendParams = sendParams.append("idOrganization", org);
    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };
    this.loading.show();

    const result = this.http.get(this.urlShipmentStatus, options).pipe(
      switchMap((rawData: any) => {
        this.loading.hide();
        let list = rawData.map((item) => Status.fromApi(item));
        let result = new StatusList(list);
        return of(result);
      }),
      catchError((err) => {
        console.log(err);
        this.loading.hide();
        return of([]);
      })
    );
    return result;
  }
}
