import { Component, Input } from "@angular/core";
import { TableBody, TableHeader } from "../table.interfaces";

@Component({
  selector: "app-table-box",
  templateUrl: "./table-box.component.html",
  styleUrls: ["./table-box.scss"],
})
export class TableBoxComponent {
  @Input() headers: TableHeader[];
  @Input() bodies: TableBody[];
  @Input() tableBoxCallback: (id: Number) => Promise<void>;

  //Constructor
  constructor() { }

  async tableBoxClick(id: Number) {
    if (this.tableBoxCallback) {
      await this.tableBoxCallback(id);
      return;
    }
  }

  returnSize(lenght: number) {
    let ret = 12 / lenght;
    return ret.toFixed(2);
  }
}
