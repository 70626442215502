import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseHttpClient } from '../../../app-core/http/abstractions/base-http-client';
import { NumberList } from '../../../app-core/libs/collections/list-model-base';
import { ApiBaseService } from '../../../app-core/services/api-base-service';
import { SettingsSystem, SettingsSystemList } from '../models/settings-system';
import { SettingsOrganizationStorage } from './settings-organization.storage';

@Injectable({ providedIn: "root" })
export class SettingsOrganizationSearch extends ApiBaseService {

    //Props
    private readonly urlSettingsSystem = `${environment.hosts.bff.organizations}/settings/system`;

    /**
     * 
     * @param http Constructor
     */
    constructor(protected http: BaseHttpClient,
                private storageService: SettingsOrganizationStorage
                ) {

        super(http);
    }

    /**
     * 
     */
     public findAndCache(orgs: number[]): Observable<SettingsSystemList>{
        
        var result = this.fetch(orgs)
                        .pipe(
                            tap(async values => {
                                await this.storageService.save(values.getAll);
                                return of(values);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of( new SettingsSystemList([]));
                            })                            

                        );


        return result;
    }

    /**
     * 
     */
    public find(orgs: number[]): Observable<SettingsSystemList>{

        const listFromApi = this.fetch(orgs);

        return listFromApi;
    }

    /**
     * 
     * @returns 
     */
    private fetch(orgs: number[]): Observable<SettingsSystemList>{

        const sendParams = new HttpParams();
        orgs.forEach( (item) => { sendParams.append("identifiers", item); });

        const options = {
            headers: this.httpHeaderDefault,
            params: sendParams
        };

        const result = this.http.get<SettingsSystem[]>(this.urlSettingsSystem, options)
                        .pipe(
                            switchMap( (rawData: any[]) => {
                                let list = rawData.map(item => SettingsSystem.fromApi(item));
                                let result = new SettingsSystemList(list);
                                return of(result);
                            }),
                            catchError( (err) => {
                                console.error(err);
                                return of( new SettingsSystemList([]));
                            })
                        );
        return result;
    }

}

