<ion-toolbar mode="ios" *ngIf="!loading">
  <ion-buttons slot="start" class="margin-start">
    <ion-text
      >Página {{this.PaginationOptions.currentPage}} de
      {{totalPaginas}}</ion-text
    >
  </ion-buttons>

  <ion-buttons class="ion-justify-content-center" style="width: 100%">
    <ion-text
      >Exibindo {{this.countData}} de {{totalRegistros}} registros.</ion-text
    >
  </ion-buttons>

  <ion-buttons>
    <ion-button
      (click)="prevPage(); sendParamns();"
      [disabled]="disablePrev"
      value="Prev"
      color="dark"
    >
      <ion-icon
        slot="icon-only"
        name="arrow-back"
        style="font-size: 1.25em"
      ></ion-icon>
    </ion-button>

    <ion-button *ngIf="showMorePagesLeft" value="..." disabled>
      ...
    </ion-button>

    <ion-segment
      *ngFor="let nroPagina of arrayPaginas;"
      slot="end"
      value="{{this.PaginationOptions.currentPage}}"
      size="small"
    >
      <ion-segment-button
        #currentPage
        id="{{nroPagina}}"
        (click)="sendParamns(currentPage.value);"
        value="{{nroPagina}}"
        [disabled]="disablePrev && disableNext"
      >
        {{nroPagina}}
      </ion-segment-button>
    </ion-segment>

    <ion-button *ngIf="showMorePagesRight" value="..." disabled>
      ...
    </ion-button>

    <ion-button
      (click)="nextPage(); sendParamns();"
      [disabled]="disableNext"
      value="next"
      color="dark"
      class="margin-end"
    >
      <ion-icon
        slot="icon-only"
        name="arrow-forward"
        style="font-size: 1.25em"
      ></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<!-- LOADING PAGINATION -->
<ion-toolbar mode="ios" *ngIf="loading">
  <ion-buttons slot="start">
    <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
    </ion-skeleton-text>
  </ion-buttons>

  <ion-buttons class="ion-justify-content-center" style="width: 100%">
    <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
    </ion-skeleton-text>
  </ion-buttons>

  <ion-buttons>
    <ion-button value="Prev" color="dark">
      <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
      </ion-skeleton-text>
    </ion-button>

    <ion-segment *ngFor="let nroPagina of [1,2,3,4,5];" slot="end" size="small">
      <ion-skeleton-text animated style="width: 10%"> </ion-skeleton-text>
    </ion-segment>

    <ion-button color="dark" class="ion-margin-end">
      <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
      </ion-skeleton-text>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
