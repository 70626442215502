import { Component, Input } from '@angular/core';

@Component({
    selector: 'item-grid-cards-01',
    templateUrl: './item-grid-cards-01.component.html',
    styleUrls: ['grid-cards-01.scss'],

})
export class ItemGridCards01Component { 

    //Constructor
    constructor(){

    }
}