import { Component, Input } from '@angular/core';
import { ActionItem } from '../../../../../app-modules-core/app-actions/models/action-item';

@Component({
    selector: 'quick-actions-right',
    templateUrl: './quick-actions-right.component.html',
    styleUrls: ['../quick-actions.scss'],

})
export class QuickActionsRightComponent { 

    //Input Params
    @Input() listOfActions: ActionItem[];

    //Constructor
    constructor(){
        
    }
}