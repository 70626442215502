import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { Place } from '../../../../../app-modules-core/trackings/place/model/place';

@Component({
  selector: 'app-modal-localization',
  templateUrl: './modal-localization.component.html',
  styleUrls: ['./modal-localization.component.scss'],
})
export class ModalLocalizationComponent implements OnInit {

  @Input() title: string = "Custom modal";
  @Input() canClose: boolean = true;
  @Input() dtOccurrence: string;
  @Input() callback: Function;
  @Input() place: Place;
  @Input() styleConfiMap: any = [{}];
  
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  closeModal() {
    if (this.canClose) {
      if (this.callback) {
        this.callback();
        return;
      }

      this.modalController.dismiss();
    }
  }

}
