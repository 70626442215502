<!-- TABLE INIT -->
<ion-grid *ngIf="table || table?.headers?.length > 0 && table?.bodies?.length > 0">
  <ion-col>
    <div class="container-body">
      <ion-row class="container-row" *ngFor="let body of table.bodies">
        <ion-row class="container-nested-row" *ngFor="let content of body.content; index as i"
          (click)="click(content.value)">
          <ion-col size="4">
            <app-header size="sm" text="{{ table.headers[i].name + ' :' }}" letter-spacing="tight" style="height: 30px">
            </app-header>
          </ion-col>
          <ion-col size="8">
            <ion-row *ngFor="let value of content.value; index as i">
              <app-paragraph-multi-size size="sm" text="{{ value }}" class="content-row" style="height: 30px"
                routerLink="{{ redirectUrl }}/{{ body.id }}">
              </app-paragraph-multi-size>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-row>
    </div>
  </ion-col>
</ion-grid>

<!-- TABLE LOADING INIT -->
<!-- <ion-grid *ngIf="table || table?.headers?.length > 0 && table?.bodies?.length > 0">
  <ion-col>
    <div class="container-body">
      <ion-row class="container-row" *ngFor="let body of table.bodies">
        <ion-row class="container-nested-row" *ngFor="let content of [1,2,3,4,5]; index as i"
          (click)="click(content.value)">
          <ion-col size="4">
            <app-header size="sm"  letter-spacing="tight" style="height: 30px">
              <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
              </ion-skeleton-text>
            </app-header>
          </ion-col>
          <ion-col size="8">
            <ion-row *ngFor="let value of [1,2,3,4,5]; index as i">
              <app-paragraph-multi-size size="sm"  class="content-row" style="height: 30px"
                >
                <ion-skeleton-text animated style="width: 40%; margin: 10px 40px">
                </ion-skeleton-text>
              </app-paragraph-multi-size>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-row>
    </div>
  </ion-col>
</ion-grid> -->