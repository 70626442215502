import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseHttpClient } from '../../../app-core/http/abstractions/base-http-client';
import { NumberList } from '../../../app-core/libs/collections/list-model-base';
import { libArray } from '../../../app-core/libs/collections/util-collection';
import { ApiBaseService } from '../../../app-core/services/api-base-service';
import { ServiceCenter, ServiceCenterList } from '../models/service-center';
import { ServiceCenterStorage } from './service-center.storage';

@Injectable({ providedIn: "root" })
export class ServiceCenterSearch extends ApiBaseService {

    //Props
    private readonly urlServiceCenters = `${environment.hosts.bff.serviceCenters}`;

    /**
     * 
     * @param http Constructor
     */
    constructor(protected http: BaseHttpClient,
                private storageService: ServiceCenterStorage
                ) {

        super(http);
    }

    /**
     * 
     */
     public findAndCache(orgs: number[]){
        
        var result$ = this.fetch(orgs)
                        .pipe(
                            tap(async values => {
                                await this.storageService.save(values.getAll);
                                return of(values);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of(new ServiceCenterList([]));
                            })                            

                        );


        return result$;
    }

    /**
     * 
    */
    public findByOrg(orgs: number[]): Observable<ServiceCenter[]>{

        if(libArray.isEmpty(orgs)){
            return of([]);
        }

        const result$ = this.findAndCache([])
                            .pipe(
                                switchMap(values => {
                                    const filteredList = this._filter(values, orgs);
                                    return of(filteredList)
                                })
                            )

        return result$;
    }

    /**
     * 
     */
     public findFromCache(orgs: number[]): Observable<ServiceCenterList>{

        const result$ = from(this.storageService.get())
                        .pipe(
                            switchMap(values => {
                                if(!libArray.isEmpty(values)){
                                    return of(new ServiceCenterList(values));
                                }
        
                                return this.findAndCache(orgs);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of(new ServiceCenterList([]));
                            })                              
                        );

        return result$;
    }

    /**
     * 
     */
    public find(orgs: number[]){

        const listFromApi = this.fetch(orgs);

        return listFromApi;
    }

    /**
     * 
     * @returns 
     */
    private fetch(orgs: number[]): Observable<ServiceCenterList>{

        const sendParams = new HttpParams();
        orgs.forEach( (item) => { sendParams.append("id", item); });

        const options = {
            headers: this.httpHeaderDefault,
            params: sendParams
        };

        const result = this.http.get(this.urlServiceCenters, options)
                        .pipe(
                            switchMap( (rawData: any[]) => {
                                let list = rawData.map(item => ServiceCenter.fromApi(item));
                                let result = new ServiceCenterList(list);
                                return of(result);
                            }),
                            catchError( (err) => {
                                console.log(err);
                                return of(new ServiceCenterList([]));
                            })
                        );
        return result;
    }

    /**
     * Apply filters
     * @param listOfItems 
     * @param orgs 
     * @returns 
     */
    private _filter(listOfItems: ServiceCenterList, orgs: number[]){

        let filteredList = listOfItems.getAll;

        if(!libArray.isEmpty(orgs)){
            filteredList = filteredList.filter(x => orgs.indexOf(x.organization.id) >= 0);
        }

        return filteredList;
    }
}

