import { AuthGuard } from "../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../app-core/guards/organization.guard";
import { IApplicationRoute } from "./../../app-core/routes/abstractions/route.interface";
import { DeliveryServiceListPage } from "./pages/delivery-services-list/delivery-service-list";

export const SERVICE_SETTINGS_ROUTES: IApplicationRoute[] = [
  {
    path: "services-settings",
    loadChildren: () =>
      import("../services-settings/services-settings.module").then(
        (m) => m.ServicesSettingsModule
      ),
    guards: [OrganizationGuard, AuthGuard],
  },
];

export const SERVICE_SETTINGS_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "delivery-services",
    component: DeliveryServiceListPage,
    fullPath: "/nav/services-settings/delivery-services",
    icon: "car-check",
    displayName: "Serviços Logísticos",
  },
];
