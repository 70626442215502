export const environment = {
  production: true,
  hosts: {
    bff: {
      default: "https://orchestrator.sinclog.app.br/bff-api/",
      auth: "https://orchestrator.sinclog.app.br/bff-api/v1/auth",
      organizations:
        "https://orchestrator.sinclog.app.br/bff-api/v1/organizations",
      serviceCenters:
        "https://orchestrator.sinclog.app.br/bff-api/v1/service-centers",
      shippers: "https://orchestrator.sinclog.app.br/bff-api/v1/shippers",
      shippers_branch:
        "https://orchestrator.sinclog.app.br/bff-api/v1/shippers-branch",
      shippers_branches:
        "https://orchestrator.sinclog.app.br/bff-api/v1/shippers-branches",
      shippingAgents:
        "https://orchestrator.sinclog.app.br/bff-api/v1/delivery-providers",
      shipments: "https://orchestrator.sinclog.app.br/bff-api/v1/shipment",
      trackings: "https://orchestrator.sinclog.app.br/bff-api/v1/tracking",
      events: "https://orchestrator.sinclog.app.br/bff-api/v1/event",
      status: "https://orchestrator.sinclog.app.br/bff-api/v1/shipment/status",
      service:
        "https://orchestrator.sinclog.app.br/bff-api/v1/shipment/service",
      signalr: "https://orchestrator.sinclog.app.br/bff-api",
    },
  },
  groups: {
    developers: "developers",
  },
  logging: {
    configFile: "./assets/data/logging/config-development.json",
  },
};
