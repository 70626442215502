import { Component, Input } from "@angular/core";
import { Status } from "../wrapper.types";

@Component({
  selector: "app-box",
  templateUrl: "./box.component.html",
  styleUrls: ["./box.scss"],
})
export class BoxComponent {
  //Input Params
  @Input() status: Status;
  @Input() blocked: Boolean;

  //Constructor
  constructor() {}
}
