import { AuthGuard } from "../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../app-core/guards/organization.guard";
import { IApplicationRoute } from "./../../app-core/routes/abstractions/route.interface";
import { DeliveryFlowDashPage } from "./pages/delivery-flow-dash/delivery-flow-dash";
import { EngageDashPage } from "./pages/engage-dash/engage-dash";

export const OPERATIONS_DASH_ROUTES: IApplicationRoute[] = [
  {
    path: "operations-dash",
    loadChildren: () =>
      import("./../operations-dash/operations-dash.module").then(
        (m) => m.OperationsDashModule
      ),
    guards: [OrganizationGuard, AuthGuard],
  },
];

export const OPERATIONS_DASH_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "engage-dash",
    component: EngageDashPage,
    fullPath: "/nav/operations-dash/engage-dash",
    icon: "car-check",
    displayName: "Engajamento",
  },
  {
    path: "delivery-flow-dash",
    component: DeliveryFlowDashPage,
    fullPath: "/nav/operations-dash/delivery-flow-dash",
    icon: "points-geoloc",
    displayName: "Remessas Expressa",
  },
];
