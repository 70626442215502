import { IApplicationRoute } from "../../app-core/routes/abstractions/route.interface";

export const AUTH_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import(
        "../../app-modules-ui/auth/login/pages/login-page/login-page.module"
      ).then((m) => m.LoginPageModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import(
        "../../app-modules-ui/auth/forgot-password/pages/forgot-password/forgot-password.module"
      ).then((m) => m.ForgotPasswordPageModule),
  },
];
