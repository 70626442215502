export class MenuItem{
    id?: string;
    title: string;
    icon: string;
    url: string;
    boxText?: string = null;

    /**
     *
     */
     public constructor(title: string, url: string, icon?: string) {
         this.title = title;
         this.url = url;
         this.icon = icon;
     }

} 