import { ListModelBase } from '../../../app-core/libs/collections/list-model-base';

export class ShippingAgents {
  id: number;
  tradeName: string;
  isActive: boolean;

  /**
   *
   * @param response
   * @returns {ShippingAgents}
   */
  static fromApi(response: any) {
    const data = new ShippingAgents();
    data.id = response.id;
    data.tradeName = response.trade_name;
    data.isActive = response.is_active;

    return data;
  }
}

export class ShippingAgentsList extends ListModelBase<ShippingAgents> {
  listOfItems: ShippingAgents[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: ShippingAgents[]) {
    super(initialList);
  }
}
