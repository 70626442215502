import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DateService {
  public convertToDateTime(dateTimeString) {
    if (!dateTimeString) {
      return dateTimeString;
    }

    let dateTimeSplit = dateTimeString.split("T", 2);
    let date = dateTimeSplit[0].split("-", 3);
    let time = dateTimeSplit[1].split(":", 3);

    return (
      date[2] +
      "/" +
      date[1] +
      "/" +
      date[0] +
      " " +
      time[0] +
      ":" +
      time[1] +
      ":" +
      time[2]
    );
  }

  public convertToDate(dateTimeString) {
    if (!dateTimeString) {
      return dateTimeString;
    }

    let date = dateTimeString.split("-", 3);

    return date[2] + "/" + date[1] + "/" + date[0];
  }

  public toDateTime(dateParam, timeParam) {
    if (!dateParam || !timeParam) {
      return dateParam;
    }

    let dateTimeSplit = dateParam.split("T", 2);
    let date = dateTimeSplit[0].split("-", 3);

    return (
      date[2] +
      "/" +
      date[1] +
      "/" +
      date[0] +
      " " +
      timeParam
    );
  }

  public convertToDateUS(dateTimeString) {
    if (!dateTimeString) {
      return dateTimeString;
    }

    let date = dateTimeString.split("-", 3);

    return date[0] + "/" + date[1] + "/" + date[2];
  }
}
