export enum LogLevel {
    All = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    Fatal = 5,
    Off = 6
}

export class LogPublisherConfig {
    loggerName: string;
    loggerLocation: string;
    isActive: boolean;
}

export class LogEntry {
    // Public Properties
    entryDate: Date = new Date();
    message: string = "";
    level: LogLevel = LogLevel.Debug;
    extraInfo: any[] = [];
    logWithDate: boolean = true;
    
    buildLogString(): string {
        let ret: string = "";
        
        if (this.logWithDate) {
            ret = new Date().toLocaleTimeString() + " - \n";
        }
        
        ret += "Type: " + LogLevel[this.level];
        ret += "\nMessage: " + this.message;
        if (this.extraInfo.length) {
            ret += "\n " + this.formatParams(this.extraInfo);
        }
        
        return ret;
    }
    
    private formatParams(params: any[]): string {
        let ret: string = params.join(",");
        
        // Is there at least one object in the array?
        if (params.some(p => typeof p == "object")) {
            ret = "";
            
            // Build comma-delimited string
            for (let item of params) {
                ret += JSON.stringify(item) + ",";
            }
        }
        
        return ret;
    }
}
