import { Component, Input } from '@angular/core';
import { AppMessage, IAppMessage } from '../../../../../app-core/libs/UTILEntities/app-message';

@Component({
    selector: 'list-full-width-01',
    templateUrl: './list-full-width-01.component.html',
    styleUrls: ['list-full-width-01.scss'],

})
export class ListFullWidth01Component { 

    //@Input() iconMessage: string = 'thumbs-down-outline';

    //Constructor
    constructor(){

    }
}