import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";

import { MenuController } from "@ionic/angular";

import { Storage } from "@ionic/storage";
import { ActionItem } from "../../../../app-modules-core/app-actions/models/action-item";
import { MENU_SETTINGS } from "../../../../app-modules-core/app-menu/models/menu-settings.const";
import { FuseDrawerComponent } from "../../../../shared/components/organisms/drawer";
import { ShipperSearch } from "../../../../app-modules-core/shippers/services/shipper.search";
import { Observable } from "rxjs";
import { Shipper } from "../../../../app-modules-core/shippers/models/shipper";
import { BaseStore } from "../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";

@Component({
  selector: "app-shipper-list",
  templateUrl: "shipper-list.html",
  styleUrls: ["./shipper-list.scss"],
})
export class ShipperListPage {
  @ViewChild(FuseDrawerComponent) filterDrawer: FuseDrawerComponent;

  public listOfShippers: Shipper[];

  /**
   *
   * @param menu
   * @param router
   * @param storage
   * @param cd
   */
  constructor(
    public menu: MenuController,
    public router: Router,
    public storage: Storage,
    public shipperSearch: ShipperSearch,
    private store: BaseStore<IAppGlobalState>
  ) {
    this._loadOrganization().subscribe((settings) => {
      this.shipperSearch
        .find({ orgs: [settings.organization.id] })
        .subscribe((obj) => (this.listOfShippers = obj.listOfItems));
    });
  }

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }
}
