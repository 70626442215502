import { Injectable } from "@angular/core";
import { CustomDateTimePipe } from "../../../../app-core/pipes/datetimes/date.pipe";
import { CustomTimePipe } from "../../../../app-core/pipes/datetimes/time.pipe";
import { DateService } from "../../../../app-core/utils/dates/date.service";
import { TableBody } from "../../../../shared/components/molecules/tables/table.interfaces";
import { ShipmentListData } from "./shipment-list.interface";

const Types = {
  SHIPPER: "SHIPPER",
  SHIPPER_BRANCH: "SHIPPER_BRANCH",
  SERVICE: "SERVICE",
  STATUS: "STATUS",
  DELIVERY_PROVIDER: "DELIVERY_PROVIDER",
};

@Injectable({ providedIn: "root" })
export class ShipmentListPageSearch {
  /**
   *
   */

  public timePipe: CustomTimePipe;
  public dateTimePipe: CustomDateTimePipe;

  constructor(public dates: DateService) {
    this.timePipe = new CustomTimePipe();
    this.dateTimePipe = new CustomDateTimePipe();
  }

  public filterFinds = (
    type: string,
    data: ShipmentListData,
    currentShipment: any
  ) => {
    var types = {
      [Types.SHIPPER]: data.shipper
        .filter(this._filterPredicate(Types.SHIPPER, currentShipment))
        .map((x) => x.tradeName)
        .toString(),
      [Types.SHIPPER_BRANCH]: data.shipperBranch
        .filter(this._filterPredicate(Types.SHIPPER_BRANCH, currentShipment))
        .map((x) => x.tradeName)
        .toString(),
      [Types.SERVICE]: data.services
        .filter(this._filterPredicate(Types.SERVICE, currentShipment))
        .map((x) => x.name)
        .toString(),
      [Types.DELIVERY_PROVIDER]: data.deliveryProvider
        .filter(this._filterPredicate(Types.DELIVERY_PROVIDER, currentShipment))
        .map((x) => x.tradeName)
        .toString(),
      [Types.STATUS]: data.statusShipment
        .filter(this._filterPredicate(Types.STATUS, currentShipment))
        .map((x) => x.name)
        .toString(),
    };

    return types[type];
  };

  private _filterPredicate = (type: string, shipment: any) => {
    var types = {
      [Types.SHIPPER]: (shipper) =>
        shipper?.document?.number?.toString() ==
        shipment.shipper_branch?.shipper?.document?.number?.toString(),
      [Types.SHIPPER_BRANCH]: (shipperBranch) =>
        shipperBranch?.document?.number?.toString() ==
        shipment.shipper_branch?.document?.number?.toString(),
      [Types.SERVICE]: (service) => service?.id == shipment.service.id,
      [Types.DELIVERY_PROVIDER]: (deliveryProvider) =>
        deliveryProvider?.id == shipment.delivery_provider?.id,
      [Types.STATUS]: (status) => status?.id == shipment.status.id,
    };

    return types[type];
  };

  public _prepareData = (pageData: ShipmentListData): { values: any[] }[] => {
    const data: { id?: number; values: any[] }[] = [];

    pageData.shipments.forEach((shipment) => {
      const subData = [];
      subData.push(["#" + shipment.id.toString(), shipment.register_date]);
      subData.push([
        this.filterFinds(Types.SHIPPER, pageData, shipment),
        shipment.sender_origin?.name,
      ]);

      subData.push([
        this.filterFinds(Types.SERVICE, pageData, shipment),
        shipment.deadline?.dt_delivery_current?.toString(),
        this.filterFinds(Types.DELIVERY_PROVIDER, pageData, shipment),
      ]);
      subData.push([
        this.filterFinds(Types.STATUS, pageData, shipment),
        shipment.flow?.dt_last_flow?.toString(),
      ]);
      data.push({ id: shipment.id, values: subData });
    });

    return data;
  };
}
