import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { NumberList } from "../../../app-core/libs/collections/list-model-base";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { LoadingService } from "../../../app-core/services/loadings/loading.service";
import { Tracking, TrackingList } from "../models/tracking";

@Injectable({ providedIn: "root" })
export class TrackingSearch extends ApiBaseService {
  //Props
  private readonly urlTrackings = `${environment.hosts.bff.trackings}`;
  public isLoading$: Observable<Boolean>;
  /**
   *
   * @param http Constructor
   */
  constructor(protected http: BaseHttpClient, private loading: LoadingService) {
    super(http);
    this.isLoading$ = this.loading.loading$;
  }

  /**
   *
   */
  public find(id: string, org: number) {
    const listFromApi = this.fetch(id, org);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(id: string, org: number): Observable<any> {
    const sendParams = new HttpParams();

    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http
      .get(`${this.urlTrackings}/${id}?idOrganization=${org}`, options)
      .pipe(
        switchMap((rawData: any) => {
          this.loading.hide();
          let list = rawData.map((item) => Tracking.fromApi(item));
          let result = new TrackingList(list);
          return of(result);
        }),
        catchError((err) => {
          console.log(err);
          this.loading.hide();
          return of([]);
        })
      );
    return result;
  }

  /**
   * Apply filters
   * @param listOfItems
   * @param orgs
   * @returns
   */
  private _filter(listOfItems: any, orgs: number[]) {
    let filteredList = listOfItems.getAll;

    if (!libArray.isEmpty(orgs)) {
      filteredList = filteredList.filter(
        (x) => orgs.indexOf(x.organization.id) >= 0
      );
    }

    return filteredList;
  }
}
