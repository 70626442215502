<div class="sidebox-auth relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l">
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg class="inset-0 pointer-events-none"
            viewBox="0 0 960 540" width="100%" height="100%" 
            preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
        <g class="sidebox-rings" fill="none" stroke="currentColor" stroke-width="100">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
        </g>
    </svg>
    <!-- Dots -->
    <svg class="sidebox-dots" viewBox="0 0 220 192" width="220" height="192" fill="none">
        <defs>
            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
            </pattern>
        </defs>
        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
    </svg>
    <!-- @formatter:on -->

    <!-- Content -->
    <div class="sidebox-content">
        <div class="sidebox-content-headline">
            <div>{{message.title}}</div>
            <!-- <div>our community</div> -->
        </div>
        <div class="sidebox-content-text" [innerHtml]="message.description">
        </div>
        <div>
            <div class="">
                <!--
                <img
                    class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                    src="assets/images/avatars/female-18.jpg">
                <img
                    class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                    src="assets/images/avatars/female-11.jpg">
                <img
                    class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                    src="assets/images/avatars/male-09.jpg">
                <img
                    class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                    src="assets/images/avatars/male-16.jpg">
                    -->
            </div>
        </div>
    </div>
</div>
