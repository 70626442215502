import { createSelector } from '@ngrx/store';
import { IAppGlobalState } from '../../app-state/store/app-global-model';
import { IAppAuthState } from './app-auth-state.models';

export const selectStateAuth = (state: IAppGlobalState) => {
    //console.log(state);
    return state.auth;
} 

export const selectStateAuthSidebar = createSelector(
    selectStateAuth, 
    (state: IAppAuthState) => {
        //console.log(state);
        return state.sidebarMessage;
    }
);

export const selectStateAuthIsAuthenticated = createSelector(
    selectStateAuth, 
    (state: IAppAuthState) => {
        return state.isAuthenticated;
    }
);

export const selectStateAuthErrorAndLoading = createSelector(
    selectStateAuth, 
    (state: IAppAuthState) => {
        return {
            isLoading: state.isLoading,
            message: state.message
        };
    }
);

export const selectStateAuthUser = createSelector(
    selectStateAuth, 
    (state: IAppAuthState) => {
        return {
            isAuthenticated: state.isAuthenticated,
            profile: state.profile
        };
    }
);

export const selectStateUser = createSelector(
    selectStateAuth, 
    (state: IAppAuthState) => {
        return {
            profile: state.profile
        };
    }
);