import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseStore } from '../../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../../../app-modules-core/app-state/store/app-global-model';

@Component({
    selector: 'app-sidebar-default',
    templateUrl: './sidebar-default.html',
    styleUrls: ['./sidebar-default.scss'],
    encapsulation: ViewEncapsulation.None

})
export class SidebarDefaultComponent {

    /**
     * 
     * @param router 
     */
    constructor(private router: Router) { 

    }

}
