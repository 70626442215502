import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppCoreStoreModule } from './app.module-store';
import { BaseNGRXModule } from './app-core/libs/UTILNgrx';
import { ResquetFailInterceptor } from './app-core/libs/UTILHttp/Interceptors/response/request-fail.interceptor';
import { AuthRequestInterceptor } from './app-core/http/interceptors/auth-request.interceptor';

@NgModule({
    imports: [
        AppCoreStoreModule,
        BaseNGRXModule
    ],
    exports: [],
    providers: [
        //{ provide: HTTP_INTERCEPTORS, useClass: ResquetFailInterceptor, multi: true },
    ],
})
export class AppCoreModule { }
