import { NgModule } from '@angular/core';
import { BlankTemplateModule } from './blank-template/blank-template.module';
import { AuthTemplateComponent } from './auth-template/auth-template.component';
import { AuthTemplateModule } from './auth-template/auth-template.module';
import { DefaultTemplateModule } from './default-template/default-template.module';
import { BlankTemplateComponent } from './blank-template/blank-template.component';
import { DefaultTemplateComponent } from './default-template/default-template.component';
import { SharedModule } from '../../shared.module';
import { DefaultContentTemplateModule } from './default-content/default-content-template.module';

const layoutModules = [

    // Empty
    BlankTemplateModule,
    AuthTemplateModule,
    DefaultTemplateModule,
    DefaultContentTemplateModule
];

@NgModule({
    declarations: [
    ],
    imports: [
        ...layoutModules
    ],
    exports: [
        ...layoutModules
    ]
})
export class TemplatesModule {
}
