import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { HeaderDefaultComponent } from './header-default';

@NgModule({
    declarations: [
        HeaderDefaultComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        HeaderDefaultComponent
    ]
})
export class HeaderDefaultModule {
}
