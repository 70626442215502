import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { SignInSideblockComponent } from './sign-in-sideblock.component';

@NgModule({
    declarations: [
        SignInSideblockComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [SignInSideblockComponent]
})
export class SignInSideblockModule { }
