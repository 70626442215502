import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  DropDown,
  DropDownOptions
} from "../dropdown.interfaces";

export type Sizes = "sm" | "md" | "lg"

@Component({
  selector: 'app-dropdown-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.scss']
})

export class DropdownMultiSelectComponent {
  
  @Input() componentName: string = "";
  @Output() componentNameChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() size: Sizes;
  @Input() placeholder: string;
  @Input() options: DropDownOptions[];
  @Input() options_list: DropDown;
  interface = "";

  currentOption = undefined;

  customPopoverOptions = {
    size: "cover",
    side: "bottom",
    alignment: "start",
    translucent: false,
    animated: true,
    showBackdrop: false,
    mode: "md"
  };

  //Constructor
  constructor(public platform: Platform) {
    if(platform.is('desktop')){
      this.interface = "popover";
    }
  }

  handleChange($event){

  }

}