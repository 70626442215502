<ion-grid>
  <!-- TABLE HEADER INIT -->
  <ion-row>
    <ion-col *ngFor="let header of headers" [size]="returnSize(headers.length)">
      <app-header size="sm" text="{{ header.name }}"></app-header>
    </ion-col>
  </ion-row>
  <!-- TABLE HEADER END -->
  <!-- TABLE BODY INIT -->
  <ion-row *ngFor="let body of bodies">
    <ion-col *ngFor="let content of body.content; index as i">
      <app-box (click)="tableBoxClick(content.value)" [status]="
          headers[i]?.status !== null
            ? headers[i].status
            : content.status !== null
            ? content.status
            : 'information'
        " [blocked]="content.value === '20' ? true : false">
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <app-paragraph-multi-size size="sm" text="{{ content.value }}"></app-paragraph-multi-size>
        </ion-row>
      </app-box>
    </ion-col>
  </ion-row>
  <!-- TABLE BODY END -->
</ion-grid>