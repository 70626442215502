import { Component, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../../../../app-modules-core/app-menu/models/menu-item";
import { MenuService } from "../../../../app-modules-core/app-menu/service/menu.service";
import { SERVICE_SETTINGS_CHILDREN_ROUTES } from "../../../services-settings/service-settings.routing";
import { SHIPPERS_CHILDREN_ROUTES } from "../../../shippers/shippers.routing";
import { SHIPPING_AGENTS_CHILDREN_ROUTES } from "../../../shipping-agents/shipping-agents.routing";

@Component({
  selector: "app-settings-nav",
  templateUrl: "settings-nav.html",
  styleUrls: ["./settings-nav.scss"],
})
export class SettingsNavPage {
  public linksPartners: MenuItem[] = new MenuService().fromApplicationRoute([
    ...SHIPPERS_CHILDREN_ROUTES,
    ...SHIPPING_AGENTS_CHILDREN_ROUTES,
  ]);

  public linksGlobalSettings: MenuItem[] =
    new MenuService().fromApplicationRoute(SERVICE_SETTINGS_CHILDREN_ROUTES);

  /**
   *
   */
  constructor(public router: Router) {}
}
