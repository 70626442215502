import { Observable, of } from 'rxjs';
import { LoggerPublisherBase } from '../logger-publisher-base';
import { LogEntry } from '../logger.model';

export class LoggerLocalStorage extends LoggerPublisherBase {

    constructor() {
        super();
        // Set location
        this.location = "logging";
    }

    /**
     * 
     * @param entry 
     * @returns 
     */
    log(entry: LogEntry): Observable<boolean> {
        let ret: boolean = false;
        let values: LogEntry[];
        
        try {
            // Get previous values from local storage
            values = JSON.parse(localStorage.getItem(this.location)) || [];
            
            // Add new log entry to array
            values.push(entry);
            
            // Store array into local storage
            localStorage.setItem(this.location, JSON.stringify(values));
            
            // Set return value
            ret = true;
        } catch (ex) {
            // Display error in console
            console.log(ex);
        }
        
        return of(ret);
    }
    
    /**
     * 
     * @returns 
     */
    clear(): Observable<boolean> {
        localStorage.removeItem(this.location);
        return of(true);
    }
}