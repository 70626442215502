import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { LoggerPublisherBase } from '../logger-publisher-base';
import { LogEntry } from '../logger.model';

export class LoggerApi extends LoggerPublisherBase {

    constructor(private http: HttpClient) {
        super();
        // Set location
        this.location = "/api/log";
    }
    
    /**
     * Add log entry to back end data store
     * @param entry 
     * @returns 
     */
    log(entry: LogEntry): Observable<boolean> {

        let infoHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        
        return this.http.post(this.location, entry, { headers: infoHeaders })
                        .pipe(
                            map(response => response),
                            catchError(this.handleErrors)
                        )
    }
    
    /**
     * Clear all log entries from local storage
     * @returns 
     */ 
    clear(): Observable<boolean> {
        // TODO: Call Web API to clear all values
        return of(true);
    }
    
    /**
     * 
     * @param error 
     * @returns 
     */
    private handleErrors(error: any): Observable<any> {

        let errors: string[] = [];
        let msg: string = "";
        
        msg = "Status: " + error.status;
        msg += " - Status Text: " + error.statusText;

        if (error.json()) {
            msg += " - Exception Message: " + error.json().exceptionMessage;
        }

        errors.push(msg);
        console.error('An error occurred', errors);
        return throwError( () => new Error(errors[0]));
    }
}