import { Component, Input } from "@angular/core";

export type Sizes = "sm" | "md" | "lg";

export type Spacing = "tight" | "wide" | "wider";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.scss"],
})
export class HeaderComponent {
  @Input() size: Sizes;
  @Input() text: string;
  @Input("letter-spacing") letterSpacing: Spacing;

  //Constructor
  constructor() {}
}
