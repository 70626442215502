import { Injectable } from '@angular/core';
import { LogPublishersService } from './logger-publisher';
import { LoggerPublisherBase } from './logger-publisher-base';
import { LogEntry, LogLevel } from './logger.model';

@Injectable()
export class AppLoggerService {

    //Propos
    level: LogLevel = LogLevel.All;    
    logWithDate: boolean = true;
    publishers: LoggerPublisherBase[];

    /**
     * Constructor Logger
     */
    constructor(private publishersService: LogPublishersService){
        this.publishers = this.publishersService.publishers;
    }

    debug(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Debug, optionalParams);
    }
    
    info(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Info, optionalParams);
    }
    
    warn(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Warn, optionalParams);
    }
    
    error(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Error, optionalParams);
    }
    
    fatal(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Fatal, optionalParams);
    }
    
    log(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.All, optionalParams);
    }    

    /**
     * 
     * @param msg 
     * @param level 
     * @param params 
     * @returns 
     */
    private writeToLog(msg: string, level: LogLevel, params: any[]) {

        if (!this.shouldLog(level)) {
            return;
        }

        let entry: LogEntry = new LogEntry();
        entry.message = msg;
        entry.level = level;
        entry.extraInfo = params;
        entry.logWithDate = this.logWithDate;

        for (let logger of this.publishers) {
            logger.log(entry).subscribe(response => console.log(response));
        }
        
    }

    /**
     * 
     * @param level 
     * @returns 
     */
    private shouldLog(level: LogLevel): boolean {
        let ret: boolean = false;
        if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
            ret = true;
        }
        return ret;
    }    
 
}