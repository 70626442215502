import { HttpHeaders } from '@angular/common/http';
import { BaseHttpClient } from '../http/abstractions/base-http-client';

export abstract class ApiBaseService{

    /**
     * 
     */
    constructor(protected http: BaseHttpClient){

   }

    /**
     * 
    */
    protected get httpHeaderDefault(): HttpHeaders {
        const headers =  new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return headers;
    }   
    
    /**
     * 
     */
     protected get httpOptionsDefault(): any {

        const httpOptions = {
            headers: this.httpHeaderDefault
        };

        return httpOptions;
    }   

    /**
     * 
     */
     protected get httpOptionsNoToken(): any {

        const httpOptions = this.httpOptionsDefault;
        httpOptions.headers.append('no-auth', 'true');

        return httpOptions;
    }   
} 