import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { LoadingService } from "../../../app-core/services/loadings/loading.service";
import {
  ShipmentPackage,
  ShipmentPackageList,
} from "../models/shipment-package";

@Injectable({ providedIn: "root" })
export class ShipmentPackageSearch extends ApiBaseService {
  //Props
  private readonly urlShipmentPackages = `${environment.hosts.bff.shipments}`;
  public isLoading$: Observable<Boolean>;
  /**
   *
   * @param http Constructor
   */
  constructor(protected http: BaseHttpClient, private loading: LoadingService) {
    super(http);
    this.isLoading$ = loading.loading$;
  }

  /**
   *
   */
  public find(id: string, org: number) {
    const listFromApi = this.fetch(id, org);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(id: string, org: number): Observable<ShipmentPackage[]> {
    const options = {
      headers: this.httpHeaderDefault,
    };
    this.loading.show();

    const result = this.http
      .get(
        `${this.urlShipmentPackages}/packages/${id}?idOrganization=${org}`,
        options
      )
      .pipe(
        switchMap((rawData: any) => {
          console.log(rawData);
          let list = rawData.map((item) => item);
          let result = list;
          this.loading.hide();
          return of(result);
        }),
        catchError((err) => {
          console.log(err);
          this.loading.hide();
          return of([]);
        })
      );
    return result;
  }

  /**
   * Apply filters
   * @param listOfItems
   * @param orgs
   * @returns
   */
  private _filter(listOfItems: ShipmentPackageList, orgs: number[]) {
    let filteredList = listOfItems.getAll;

    if (!libArray.isEmpty(orgs)) {
      filteredList = filteredList.filter(
        (x) => orgs.indexOf(x.organization.id) >= 0
      );
    }

    return filteredList;
  }
}
