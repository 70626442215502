export class InvoiceTypesConst {
  private invoicesTypes: Map<Number, string>;

  public static NOTA_FISCAL: Number = 55;
  public static DECLARACAO_CONTEUDO: Number = 0;
  public static CUPOM_FISCAL: Number = 59;

  /**
   *
   */
  constructor() {
    this.init();
    this.fill();
  }

  /**
   *
   * @param id
   * @returns
   */
  public get(id: Number): string {
    return this.invoicesTypes.get(id) ?? '';
  }

  /**
   *
   */
  public init(): void {
    this.invoicesTypes = new Map<Number, string>();
  }

  /**
   *
   */
  private fill() {
    this.invoicesTypes.set(InvoiceTypesConst.NOTA_FISCAL, "Nota fiscal");
    this.invoicesTypes.set(
      InvoiceTypesConst.DECLARACAO_CONTEUDO,
      "Declaração de conteúdo"
    );
    this.invoicesTypes.set(InvoiceTypesConst.CUPOM_FISCAL, "Cupom fiscal");
  }
}
