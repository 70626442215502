import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { SidebarHeaderUserComponent } from './sidebar-header-user';

@NgModule({
    declarations: [
        SidebarHeaderUserComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SidebarHeaderUserComponent
    ]
})
export class SidebarHeaderUserModule {
}
