import { ListModelBase } from "../../../../app-core/libs/collections/list-model-base";

export class DeliveryProvider {
  id: number;
  name: string;
  tradeName: string;

  static fromApi(response: any) {
    const item = new DeliveryProvider();
    item.id = response.id;
    item.tradeName = response.trade_name;
    return item;
  }
}

export class DeliveryProviderList extends ListModelBase<DeliveryProvider> {
  listOfItems: DeliveryProvider[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: DeliveryProvider[]) {
    super(initialList);
  }
}
