import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { BaseHttpClient } from "../../../../app-core/http/abstractions/base-http-client";
import { libArray } from "../../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../../app-core/services/api-base-service";
import {
  DeliveryProvider,
  DeliveryProviderList,
} from "../models/delivery-provider";
import { DeliveryProvidersStorage } from "./delivery-provider.storage";

@Injectable({ providedIn: "root" })
export class DeliveryProviderSearch extends ApiBaseService {
  //Props
  private readonly urlOrganizations = `${environment.hosts.bff.shippingAgents}`;

  /**
   *
   * @param http Constructor
   */
  constructor(
    protected http: BaseHttpClient,
    private storageService: DeliveryProvidersStorage
  ) {
    super(http);
  }

  /**
   *
   */
  public findAndCache(org: number) {
    var result$ = this.fetch(org).pipe(
      tap(async (values) => {
        if (values.getAll.length > 0) {
          var itensToCache = values.getAll.map((d) => {
            return { org: org, ...d };
          });
        }

        await this.storageService.save(itensToCache);
        return of(values);
      }),
      catchError((err) => {
        console.log(err);
        return of(new DeliveryProviderList([]));
      })
    );

    return result$;
  }

  /**
   *
   */
  public findFromCache(org: number): Observable<DeliveryProviderList> {
    const result$ = from(this.storageService.get()).pipe(
      switchMap((values) => {
        if (!libArray.isEmpty(values)) {
          return of(
            new DeliveryProviderList(values.filter((d) => d.org === org))
          );
        }

        return this.findAndCache(org);
      }),
      catchError((err) => {
        console.log(err);
        return of(new DeliveryProviderList([]));
      })
    );

    return result$;
  }

  /**
   * Get All From API
   */
  public find(org: number): Observable<DeliveryProviderList> {
    const listFromApi = this.fetch(org);

    return listFromApi;
  }

  /**
   *
   * @returns
   */
  private fetch(org: number): Observable<DeliveryProviderList> {
    const sendParams = new HttpParams();

    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http
      .get(`${this.urlOrganizations}?idOrganization=${org}`, options)
      .pipe(
        switchMap((rawData: any[]) => {
          let list = rawData.map((item) => DeliveryProvider.fromApi(item));
          let result = new DeliveryProviderList(list);
          return of(result);
        }),
        catchError((err) => {
          console.log(err);
          return of(new DeliveryProviderList([]));
        })
      );
    return result;
  }
}
