import { AuthGuard } from "../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../app-core/guards/organization.guard";
import { IApplicationRoute } from "./../../app-core/routes/abstractions/route.interface";
import { ShippingAgentListPage } from "./pages/list/shipping-agent-list";

export const SHIPPING_AGENTS_ROUTES: IApplicationRoute[] = [
  {
    path: "shipping-agents",
    loadChildren: () =>
      import("../shipping-agents/shipping-agents.module").then(
        (m) => m.ShippingAgentsModule
      ),
    guards: [OrganizationGuard, AuthGuard],
  },
];

export const SHIPPING_AGENTS_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "",
    component: ShippingAgentListPage,
  },
  {
    path: "list",
    component: ShippingAgentListPage,
    fullPath: "/nav/shipping-agents/list",
    icon: "cars-building",
    displayName: "Provedores de Entrega",
  },
];
