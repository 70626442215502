import { Observable, of } from 'rxjs';
import { LoggerPublisherBase } from '../logger-publisher-base';
import { LogEntry } from '../logger.model';

export class LoggerConsole extends LoggerPublisherBase {

    /**
     * Constructor
     */
    constructor(){
        super();
        this.location = "";
    }

    log(entry: LogEntry): Observable<boolean> {

        console.log(entry.buildLogString());
        return of(true);
    }
    
    clear(): Observable<boolean> {

        console.clear();
        return of(true);
    }
}