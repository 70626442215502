import { TableService } from "./../../../../shared/components/molecules/tables/table.service";
import { StatusList } from "./../../../../app-modules-core/shipments/models/status";
import { DeliveryProviderSearch } from "./../../../../app-modules-core/organizations/delivery-providers/services/delivery-provider.search";
import { DeliveryProvider } from "./../../../../app-modules-core/organizations/delivery-providers/models/delivery-provider";
import { Organization } from "./../../../../app-modules-core/organizations/models/organization";
import { BaseStore } from "./../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import {
  Tracking,
  TrackingList,
} from "./../../../../app-modules-core/trackings/models/tracking";
import { ShipmentPackage } from "./../../../../app-modules-core/shipments/models/shipment-package";
import { ShipmentDocument } from "./../../../../app-modules-core/shipments/models/shipment-document";
import { Shipment } from "./../../../../app-modules-core/shipments/models/shipment";
import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { MenuController, ModalController } from "@ionic/angular";

import { Storage } from "@ionic/storage";
import { ActionItem } from "../../../../app-modules-core/app-actions/models/action-item";
import { ShipmentSearch } from "../../../../app-modules-core/shipments/services/shipment.search";
import { combineLatest, forkJoin, map, mergeMap, Observable, of } from "rxjs";
import { TrackingSearch } from "../../../../app-modules-core/trackings/services/tracking.search";
import { EventSearch } from "../../../../app-modules-core/trackings/events/services/events.search";
import { ShipmentPackageSearch } from "../../../../app-modules-core/shipments/services/shipment-package.search";
import { ShipmentDocumentSearch } from "../../../../app-modules-core/shipments/services/shipment-document.search";
import { ServiceTypesConst } from "../../../../app-modules-core/shipments/consts/services-types.const";
import { InvoiceTypesConst } from "../../../../app-modules-core/shipments/consts/invoice-types.const";
import { LoadingService } from "../../../../app-core/services/loadings/loading.service";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";
import * as moment from "moment";
import { ServiceSearch } from "../../../../app-modules-core/shipments/Service/services/service.search";
import { ShipperSearch } from "../../../../app-modules-core/shippers/services/shipper.search";
import { ShipmentError } from "../../../../app-modules-core/shipments/store/app-shipment-state.actions";
import { StatusSearch } from "../../../../app-modules-core/shipments/services/status.search";
import { ServicesSearch } from "../../../../app-modules-core/shipments/services/services.search";
import { ServiceList } from "../../../../app-modules-core/shipments/models/service";
import { ModalTableComponent } from "../../../../shared/components/molecules/modals/modal-table/modal-table.component";
import { ModalDefaultComponent } from "../../../../shared/components/molecules/modals/modal-default/modal-default.component";
import { ModalSlideComponent } from "../../../../shared/components/molecules/modals/modal-slide/modal-slide.component";
import { Evidences } from "../../../../app-modules-core/trackings/evidences/models/evidences";
import { ModalLocalizationComponent } from "../../../../shared/components/molecules/modals/modal-localization/modal-localization.component";
import { Place } from "../../../../app-modules-core/trackings/place/model/place";
//import { MENU_SETTINGS } from '../../../../app-modules-core/app-menu/models/menu-settings.const';
//import { FuseDrawerComponent } from '../../../../shared/components/organisms/drawer';

@Component({
  selector: "app-shipment-details",
  templateUrl: "./shipment-details.html",
  styleUrls: ["./shipment-details.scss"],
})
export class ShipmentDetailsPage implements OnInit {
  private readonly DELIVERED: number = 28;
  private readonly CANCEL: number = 10;
  private readonly PROVIDER_ENGAGE: number = 70;
  private readonly ABORT: number = 72;
  readonly ERRO_DE_INTEGRACAO: number = 128;

  //readonly props
  public idShipment: string;
  public idOrganization: number;

  public colorNameStatus: string = "secondary";
  public testeLink: string = "/";

  //Observables
  public shipment$: Observable<any>;
  public trackings$: Observable<any>;
  public documents$: Observable<any>;
  public packages$: Observable<any>;

  public isTrackingLoading$: Observable<Boolean>;
  public isShipmentLoading$: Observable<Boolean>;
  public isDocumentsLoading$: Observable<Boolean>;
  public isPackagesLoading$: Observable<Boolean>;

  /**
   *
   * @param menu
   * @param router
   * @param storage
   * @param cd
   */
  constructor(
    public menu: MenuController,
    private router: ActivatedRoute,
    public storage: Storage,
    public shipment: ShipmentSearch,
    public shipper: ShipperSearch,
    public tracking: TrackingSearch,
    public event: EventSearch,
    public service: ServicesSearch,
    public status: StatusSearch,
    public documents: ShipmentDocumentSearch,
    public packages: ShipmentPackageSearch,
    public deliveryProvider: DeliveryProviderSearch,
    public loading: LoadingService,
    private store: BaseStore<IAppGlobalState>,
    private modal: ModalController,
    private tableService: TableService
  ) {
    this._handleLoading();
    this._handleRouteParams();
    this._handleOrganization();
  }

  private _handleLoading = () => {
    this.isShipmentLoading$ = this.shipment.isLoading$;
    this.isTrackingLoading$ = this.tracking.isLoading$;
    this.isPackagesLoading$ = this.packages.isLoading$;
    this.isDocumentsLoading$ = this.documents.isLoading$;
  };

  private _handleRouteParams() {
    this.router.params.subscribe((params) => {
      this.idShipment = params["id"];
    });
  }

  private _handleOrganization() {
    this._loadOrganization().subscribe((o) => {
      this.idOrganization = o.organization.id;
    });
  }

  ngOnInit(): void {
    this.shipment$ = this._handleShipment();
    this.documents$ = this.documents.find(this.idShipment, this.idOrganization);
    this.packages$ = this.packages.find(this.idShipment, this.idOrganization);
    this.trackings$ = this.tracking
      .find(this.idShipment, this.idOrganization)
      .pipe(this._handleTrackings());
  }

  private _handleShipment(): Observable<Shipment> {
    return this.shipment.find(this.idShipment, this.idOrganization).pipe(
      mergeMap((shipment) => {
        if (!(Object.keys(shipment).length > 0)) {
          return of({} as Shipment);
        }

        return this.service
          .findFromCache({
            org: this.idOrganization,
            ids: [shipment?.service?.id],
          })
          .pipe(
            map((service) => {
              shipment.service.name =
                service.listOfItems.find((s) => s.id === shipment.service.id)
                  ?.name ?? null;

              return service;
            }),
            mergeMap((service) => {
              return this.deliveryProvider
                .findFromCache(this.idOrganization)
                .pipe(
                  map((d) => {
                    shipment.delivery_provider = {
                      ...shipment.delivery_provider,
                      name:
                        d.listOfItems.find(
                          (d) => shipment.delivery_provider?.id == d.id
                        )?.tradeName ?? null,
                    };

                    return shipment;
                  })
                );
            }),
            this._handleStatus(shipment),
            this._handleShipper(shipment)
          );
      })
    );
  }

  private _handleTrackings() {
    return mergeMap((trackings: TrackingList) => {
      if (!trackings.listOfItems || trackings.listOfItems.length === 0) {
        return of({} as Tracking);
      }

      return this.event
        .find(
          trackings.listOfItems.map((tracking: any) => tracking.type?.id),
          this.idOrganization
        )
        .pipe(
          map((events) => {
            return trackings.listOfItems.map((t: any) => {
              var currentEvent = events.find((e: any) => t.type?.id == e.id);

              if (!currentEvent) return {};

              var newTracking = {
                ...t,
                descricao: currentEvent.description_default,
              };
              return newTracking;
            });
          })
        );
    });
  }

  private _handleShipper(shipment: Shipment) {
    return mergeMap((_) => {
      return this.shipper.findFromCache({ orgs: [this.idOrganization] }).pipe(
        map((shipper) => {
          shipment.shipper_branch.shipper.tradeName =
            shipper.listOfItems.find(
              (x) =>
                x.document.number.toString() ===
                shipment?.shipper_branch?.shipper?.document.number.toString()
            )?.tradeName ?? null;
          return shipment;
        })
      );
    });
  }

  private _handleStatus(shipment: Shipment) {
    return mergeMap((_) => {
      return this.status
        .findFromCache({
          org: this.idOrganization,
          ids: [shipment?.status?.id],
        })
        .pipe(
          map((status) => {
            shipment.status.name =
              status.listOfItems.find((x) => x.id === shipment?.status?.id)
                ?.name ?? null;

            if (shipment.status?.name?.toUpperCase() == "CANCELADA") {
              this.colorNameStatus = "danger";
            }

            if (shipment.status?.name?.toUpperCase() == "ENTREGUE") {
              this.colorNameStatus = "success";
            }

            return shipment;
          })
        );
    });
  }

  public getServiceDescription(id: Number): string {
    return new ServiceTypesConst().get(id);
  }
  
  public getInvoiceKey(invoice: any, documentNumber: any, documentSerie: any) {
    if (!invoice) return "";
    return (
      invoice.state_id_issuer.toString().padStart(2, '0') +
      invoice.year.toString().padStart(2, '0') +
      invoice.month.toString().padStart(2, '0') +
      invoice.issuer.toString().padStart(14, '0') +
      invoice.model_document.toString().padStart(2, '0') +
      documentSerie.toString().padStart(3, '0') +
      documentNumber.toString().padStart(9, '0') +
      invoice.issue_type +
      invoice.code.toString().padStart(8, '0') +
      invoice.dv
    );
  }

  public getInvoiceDescription(id: Number, number: number): string {
    return `${new InvoiceTypesConst().get(id)} - ${number}`;
  }

  public getDeliveryProviderDescription(deliveryProvider: any, shipment: any) {
    var currentDeliveryProvider = deliveryProvider.listOfItems.filter(
      (d) => shipment.delivery_provider?.id == d.id
    );

    if (!currentDeliveryProvider || currentDeliveryProvider?.length > 0)
      return currentDeliveryProvider[0].tradeName;

    return null;
  }

  //entrega em atraso
  public lateDelivery(
    dtDeliveryCurrent: string,
    doneFinish: string,
    status: number
  ): boolean {
    if (status !== this.PROVIDER_ENGAGE) {
      return false;
    }

    let now = moment().startOf("day");
    let dtDeliveryCurrentFormated = this.formateDate(dtDeliveryCurrent);

    if (
      now > dtDeliveryCurrentFormated &&
      (doneFinish == undefined || doneFinish == null)
    ) {
      return true;
    }

    return false;
  }

  //entregue no prazo
  public deliveryOnTime(
    dtDeliveryCurrent: string,
    doneFinish: string,
    status: number
  ): boolean {
    if (status !== this.DELIVERED) {
      return false;
    }

    let dtDeliveryCurrentFormated = this.formateDate(dtDeliveryCurrent);
    let doneFinishFormated = this.formateDate(doneFinish);

    if (
      (doneFinish != undefined || doneFinish != null) &&
      doneFinishFormated <= dtDeliveryCurrentFormated
    ) {
      return true;
    }

    return false;
  }

  //em andamento no prazo
  public deliveryInProgress(
    dtDeliveryCurrent: string,
    doneFinish: string,
    status: number
  ): boolean {
    if (status !== this.PROVIDER_ENGAGE) {
      return false;
    }

    let now = moment().startOf("day");
    let dtDeliveryCurrentFormated = this.formateDate(dtDeliveryCurrent);

    if (
      now <= dtDeliveryCurrentFormated &&
      (doneFinish == undefined || doneFinish == null)
    ) {
      return true;
    }

    return false;
  }

  //entregue com atraso
  public deliveredLate(
    dtDeliveryCurrent: string,
    doneFinish: string,
    status: number
  ): boolean {
    if (status !== this.DELIVERED) {
      return false;
    }

    let now = moment().startOf("day");
    let dtDeliveryCurrentFormated = this.formateDate(dtDeliveryCurrent);
    let doneFinishFormated = this.formateDate(doneFinish);

    if (now > dtDeliveryCurrentFormated && doneFinishFormated != null) {
      return true;
    }

    return false;
  }

  //entrega cancelada
  public canceledDelivery(dtCancellation: string, status: number): boolean {
    if (status !== this.CANCEL) {
      return false;
    }

    if (dtCancellation != undefined || dtCancellation != null) {
      return true;
    }

    return false;
  }

  public abort(status: number) {
    if (status !== this.ABORT) {
      return false;
    }

    return true;
  }

  public formateDate(date: string): moment.Moment {
    if (date != undefined && date != null) {
      let dateSplited = date.split("/");
      let formatedDate = `${dateSplited[1]}/${dateSplited[0]}/${dateSplited[2]}`;

      return moment(formatedDate);
    }

    return null;
  }

  /**
   * Show/Hide filters
   */
  public toggleFilters = () => {
    //this.filterDrawer.toggle();
  };

  /**
   * List of utilities actions available
   */
  public listOfActions: ActionItem[] = [];

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }

  async openEvidenceModal(evidences: Evidences, dtOccurrence: string) {
    const modal = await this.modal.create({
      component: ModalSlideComponent,
      cssClass: "global-modal-class",
      componentProps: {
        title: "Evidências do Evento",
        canClose: true,
        evidences: evidences,
        dtOccurrence: dtOccurrence,
        callback: () => this.modal.dismiss(),
      },
    });
    modal.present();
  }

  async openLocalizationModal(place: Place, dtOccurrence: string) {
    const modal = await this.modal.create({
      component: ModalLocalizationComponent,
      cssClass: "global-modal-class",
      componentProps: {
        title: "Localização do Evento",
        canClose: true,
        place: place,
        dtOccurrence: dtOccurrence,
        callback: () => this.modal.dismiss(),
      },
    });
    modal.present();
  }

  async openTableModal(
    id: number,
    name: string,
    comments: string,
    date: string
  ) {
    this.tableService.renew();
    this.tableService.buildHeaders("Tracking", "Descrição", "Data do Tracking");
    this.tableService.buildBodies({
      data: [{ id: id, values: [name, comments ?? " - ", date] }],
    });

    var table = this.tableService.table;

    const modal = await this.modal.create({
      component: ModalTableComponent,
      cssClass: "global-modal-class",
      componentProps: {
        title: "Logs de Eventos",
        canClose: true,
        table: table,
        callback: () => this.modal.dismiss(),
      },
    });

    modal.present();
  }
}
