import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";


@Component({
  selector: "app-modal-default",
  templateUrl: "./modal-default.component.html",
  styleUrls: ["./modal-default.component.scss"],
})
export class ModalDefaultComponent {

  @Input() title: string = "Custom modal";
  @Input() canClose: boolean = true;
  @Input() callback: Function;


  constructor(private modalController: ModalController) {
  }

  closeModal() {
    if (this.canClose) {
      if (this.callback) {
        this.callback();
        return;
      }

      this.modalController.dismiss();
    }
  }
}
