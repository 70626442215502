import { NgModule } from "@angular/core";
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { storeFreeze } from "ngrx-store-freeze";

import { environment } from '../environments/environment';
import { HydrationEffects } from './app-modules-core/app-hydration/store/hydrate.effects';
import { hydrationMetaReducer } from './app-modules-core/app-hydration/store/hydrate.reducers';
import { IAppGlobalState } from './app-modules-core/app-state/store/app-global-model';
import { AppGlobalEffects } from './app-modules-core/app-state/store/app-global.effects';
import { appGlobalSettingsReducer } from './app-modules-core/app-state/store/app-global.reducers';
import { AuthEffects } from './app-modules-core/auth/store/app-auth-state.effects';
import { appAuthStateReducer } from './app-modules-core/auth/store/app-auth-state.reducer';

/*************************  
 * META REDUCERS MAPING
**************************/
const storeImutate = !environment.production ? [storeFreeze] : [];
export const metaReducers: Array<MetaReducer<any, any>> = [
  // localStorageSyncReducer,
  hydrationMetaReducer,
  ...storeImutate,
  // reset,
];

/*************************  
 * APP ALL REDUCERS MAPING
**************************/
const appReducers: ActionReducerMap<IAppGlobalState> = {
    auth: appAuthStateReducer,
    globalSettings: appGlobalSettingsReducer
};

/*************************  
 * APP ALL EFFECTS MAPING
**************************/
const appGlobalEffects = [
    //AUTH
    HydrationEffects,
    AuthEffects,
    AppGlobalEffects
];
  

/*************************  
 * ADDITIONAL MODULES TO HELP DEBUG NGRX
**************************/
const extModules = [
    ...(!environment.production
        ? [StoreDevtoolsModule.instrument({ maxAge: 25 })]
        : []),
];

/*************************  
 * CREATING APP DEFAULT STORE MODULE
**************************/
@NgModule({
    imports: [
        StoreModule.forRoot(appReducers, {metaReducers}),
        EffectsModule.forRoot(appGlobalEffects),
        extModules
    ],
    providers: [],
})
export class AppCoreStoreModule { }
