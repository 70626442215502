import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { SidebarHeaderBrandComponent } from './sidebar-header-brand';

@NgModule({
    declarations: [
        SidebarHeaderBrandComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SidebarHeaderBrandComponent
    ]
})
export class SidebarHeaderBrandModule {
}
