<ion-select 
    [ngClass]="{'small': size === 'sm', 'medium' : size === 'md', 'large' : size === 'lg' }"
    [(ngModel)]="componentName"
    id="{{componentName}}"
    name="{{componentName}}"
    (ngModelChange)="componentNameChange.emit(componentName)"
    interface="{{interface}}"
    [interfaceOptions]="customPopoverOptions"
    placeholder="{{placeholder}}"
    (ionChange)="handleChange($event)"
    [multiple]="true"
    >
    <ion-select-option *ngFor="let option of options_list?.options" [value]="option.value">{{ option.name }}</ion-select-option>
</ion-select>