import { AppMessage, AppMessageError, AppMessageInfo, IAppMessage } from '../../app-core/libs/UTILEntities/app-message';

export type SideblockMessageTypes =
    | 'login'
    | 'forgot-password';


export const MessageBoxLogin: IAppMessage = new AppMessageInfo(
    'Para todos que entendem que logística é mais do que entregar', 
    'Você está em um ambiente restrito. O acesso é permitido somente para pessoas autorizadas. Caso você seja uma delas, espero que possamos fazer um ótimo trabalho!'
);


export const MessageBoxForgotPassword: IAppMessage = new AppMessageInfo(
    'Fique tranquilo, isso ocorre com todos nós :)', 
    'Informe seu login no formulário ao lado. Iremos enviar uma nova senha para o e-mail cadastrado em sua conta. Caso não receba, acione o seu gestor. Ahh, não se esqueça de olhar se mensagem não está no SPAM do e-mail.'
);

export const MsgIncorrectLogin: IAppMessage = new AppMessageError('', 'O login ou senha informados são inválidos');

export const MsgCouldNotReachServer: IAppMessage = new AppMessageError('', 'Tivemos problemas ao tentar fazer o seu login. Tente novamente mais tarde!');