import { OrganizationGuard } from "./../../app-core/guards/organization.guard";
import { IApplicationRoute } from "../../app-core/routes/abstractions/route.interface";
import { ShipmentListPage } from "./pages/list/shipment-list";
import { ShipmentDetailsPage } from "./pages/shipment-detail/shipment-details";
import { AuthGuard } from "../../app-core/guards/auth.guard";

export const SHIPMENTS_ROUTES: IApplicationRoute[] = [
  {
    path: "shipments",
    loadChildren: () =>
      import("../shipments/shipments.module").then((m) => m.ShipmentsModule),
    guards: [OrganizationGuard, AuthGuard],
  },
];

export const SHIPMENTS_CHILDREN_ROUTES: IApplicationRoute[] = [
  {
    path: "details/:id",
    component: ShipmentDetailsPage,
  },
  {
    path: "list",
    component: ShipmentListPage,
    fullPath: "/nav/shipments/list",
    icon: "car-check",
    displayName: "Listagem de Remessas",
  },
];
