import { Component, Input } from "@angular/core";

export type Sizes = "sm-0" | "sm" | "md" | "lg";

@Component({
  selector: "app-paragraph-multi-size",
  templateUrl: "./paragraph-multi-size.component.html",
  styleUrls: ["./paragraph-multi-size.scss"],
})
export class ParagraphMultiSizeComponent {
  @Input() size: Sizes;
  @Input() text: string;

  //Constructor
  constructor() {}
}
