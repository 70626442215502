import { Router } from '@angular/router';

export const navToLogin = function(router: Router){
    router.navigateByUrl('/auth/login');
}

export const navToLogout = function(router: Router){
    router.navigateByUrl('/auth/logout');
}

export const navToHome = function(router: Router){
    router.navigateByUrl('/home', { replaceUrl: true });
}

export const navToSelectOrganization = function(router: Router){
    router.navigateByUrl('/nav/select-organization', { replaceUrl: true });
}
