import {Component, Input, Output, EventEmitter } from '@angular/core';

export type Sizes = "sm" | "md" | "lg"

@Component({
  selector: 'app-filter-date-interval',
  templateUrl: './date-interval.component.html',
  styleUrls: ['./date-interval.scss']
})

export class DateIntervalComponent {
  
  @Input() componentNameStart: string = "";
  @Output() componentNameStartChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() componentNameEnd: string = "";
  @Output() componentNameEndChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() labelText: string;
  @Input() startValue: string;
  @Input() endValue: string;

  //Constructor
  constructor() { }

}