import { createAction, props } from '@ngrx/store';
import { IAppMessage } from '../../../app-core/libs/UTILEntities/app-message';
import { BaseAction } from '../../../app-core/libs/UTILNgrx/Action/BaseAction';
import { User } from '../../auth/models/user';
import { Organization } from '../../organizations/models/organization';
import { SettingsSystem } from '../../organizations/models/settings-system';
import { ServiceCenter } from '../../service-centers/models/service-center';

export enum ActionTypes {
    APP_INIT = "[APP GLOBAL] APP_INIT",
    APP_THEME_DEFINE = "[APP GLOBAL] APP_THEME_DEFINE",
    APP_LOADING = "[APP GLOBAL] APP_LOADING",
    APP_REHYDRATE = "[APP GLOBAL] APP_REHYDRATE_ROUTINE",
    APP_SELECT_SERVICE_CENTER = "[APP GLOBAL] APP_SELECT_SERVICE_CENTER",
    APP_SETUP_ORG_SETTINGS = "[APP GLOBAL] APP_SETUP_ORG_SETTINGS",
    APP_SETUP_ALL_ORGS = "[APP GLOBAL] APP_SETUP_ALL_ORGS",
    APP_RESET_SETTINGS = "[APP GLOBAL] APP_RESET_SETTINGS",

}


export class ThemeDefine implements BaseAction{
    readonly type = ActionTypes.APP_THEME_DEFINE;
    constructor(public payload: any) {}
}

export class AppLoading implements BaseAction{
    readonly type = ActionTypes.APP_LOADING;
    constructor(public payload: any) {}
}

export const AppHydrate = createAction(
    ActionTypes.APP_REHYDRATE,
    props<{ payload: any }>()
);

export const AppSetupOrgSettings = createAction(
    ActionTypes.APP_SETUP_ORG_SETTINGS, 
    props<{ settings: SettingsSystem }>()
);

export const AppSetupAllOrgs = createAction(
    ActionTypes.APP_SETUP_ALL_ORGS
);

export const AppSelectServiceCenter = createAction(
    ActionTypes.APP_SELECT_SERVICE_CENTER, 
    props<{ serviceCenter: ServiceCenter }>()
);

export const AppResetSettings = createAction(
    ActionTypes.APP_RESET_SETTINGS
);
