<!-- Modal Table Component INIT -->
<app-modal-default class="modal-table" [title]="title" [canClose]="canClose" (click)="closeModal()">
  <ion-grid class="ion-margin-horizontal">
    <ion-row class="container-row">
      <app-table-default class="ion-hide-md-down" [table]="table">
      </app-table-default>
      <app-table-vertical
        *ngIf="table"
        [table]="table"
        class="ion-hide-md-up"
      ></app-table-vertical>
    </ion-row>
    <!-- <app-header
      *ngIf="table.headers.length > 0 && table.bodies.length > 0"
      size="sm"
      text="total de registros encontrados {{ table.bodies.length }}"
      letter-spacing="tight"
    ></app-header> -->
  </ion-grid>
  <div
    class="container-footer"
    *ngIf="table.headers.length > 0 && table.bodies.length > 0"
  >
    <!-- <ion-button color="light">consulta em lote</ion-button> -->
    <!-- <ion-button color="success"
      ><ion-icon name="document-outline"></ion-icon>exportar</ion-button
    > -->
  </div>
</app-modal-default>
<!-- Modal Table Component END -->
