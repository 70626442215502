<ion-row style="height: 100%;">
    <ion-col class="p-0">
        <ion-router-outlet class="main-content" id="main-content"></ion-router-outlet>
    </ion-col>
    <ion-col class="p-0 hide-xs-down">
        <app-sign-in-sideblock></app-sign-in-sideblock>
    </ion-col>
</ion-row>
<!--
<ion-split-pane when="sm" contentId="main-content">
    <ion-menu contentId="main-content" side="end">
        <ion-content>
        </ion-content>
    </ion-menu>
</ion-split-pane>
-->