import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BaseStore } from "../../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import { MenuItem } from "../../../../../app-modules-core/app-menu/models/menu-item";
import { menuServicesLevel } from "../../../../../app-modules-core/app-menu/models/menu-services-level.const";
import { MenuService } from "../../../../../app-modules-core/app-menu/service/menu.service";
import { IAppGlobalState } from "../../../../../app-modules-core/app-state/store/app-global-model";
import * as AuthActions from "../../../../../app-modules-core/auth/store/app-auth-state.actions";
import { ServiceCenter } from "../../../../../app-modules-core/service-centers/models/service-center";
import { GLOBAL_SEARCH_ROUTES } from "../../../../../app-modules-ui/menus/pages/global-search-nav/global-search.routing";
import { SETTINGS_ROUTES } from "../../../../../app-modules-ui/menus/pages/settings-nav/settings.routing";
import { SHIPMENT_OPERATIONS_ROUTES } from "../../../../../app-modules-ui/menus/pages/shipments-operations-nav/shipments-operations.routing";
import { ComponentUserBase } from "../../../componet-user-base";

@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.html",
  styleUrls: ["./sidebar-menu.scss"],
})
export class SidebarMenuComponent extends ComponentUserBase implements OnInit {
  //Props
  public linksOperations: MenuItem[] = new MenuService().fromApplicationRoute([
    ...SHIPMENT_OPERATIONS_ROUTES,
    ...GLOBAL_SEARCH_ROUTES,
  ]);
  public linksServiceLevel: MenuItem[] = menuServicesLevel;
  public linksAdm: MenuItem[] = new MenuService().fromApplicationRoute([
    ...SETTINGS_ROUTES,
  ]);
  public serviceCenterSelected: ServiceCenter;

  //Observables
  private serviceCenter$: Observable<ServiceCenter>;

  /**
   *
   * @param store
   * @param router
   */
  constructor(
    protected store: BaseStore<IAppGlobalState>,
    private router: Router
  ) {
    super(store);
  }

  /**
   *
   */
  openTutorial() {
    //this.menu.enable(false);
    //this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl("/tutorial");
  }

  /**
   *
   */
  ngOnInit(): void {
    console.debug("init ");
    this.listenerInfoAuth();
    //this.listenerServiceCenter();
  }

  /**
   *
   */
  logout(): void {
    this.store.dispatch(AuthActions.ActionLogout({ infoError: null }));
  }

  /**
   *
   */
  listenerServiceCenter(): void {
    this.serviceCenter$.subscribe((info) => {
      this.serviceCenterSelected = info;
    });
  }
}
