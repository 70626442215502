import { Component, Input } from '@angular/core';

@Component({
    selector: 'grid-cards-01',
    templateUrl: './grid-cards-01.component.html',
    styleUrls: ['grid-cards-01.scss'],

})
export class GridCards01Component { 

    //@Input() iconMessage: string = 'thumbs-down-outline';

    //Constructor
    constructor(){

    }
}