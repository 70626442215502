<ion-header collapse="fade" [translucent]="true">
    <ion-toolbar class="default-header">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-searchbar class="quick-search" color="light" animated debounce="500" placeholder="Busca rápida...">
        </ion-searchbar>
        <ion-buttons slot="end">
            <ion-button (click)="controlPopover($event)">
                <ion-icon size="large" name="person-circle-outline"></ion-icon>
            </ion-button>
            <ion-popover mode="md" dismiss-on-select="true" show-backdrop="false" #popover alignment="start"
                (didDismiss)="isOpen = false" [isOpen]="isOpen">
                <ng-template>
                    <ion-item-divider color="dark" class="divider-grey">
                        <ion-text class="ion-text-infos">
                            <p class="paragraph-info">{{userInfo?.name}}</p>
                            <p class="paragraph-info">{{userInfo?.username}}</p>
                        </ion-text>
                    </ion-item-divider>
                    <ion-item-divider>
                        <ion-grid class="no-padding-left">
                            <ion-row class="pointer ion-row-flex">
                                <ion-col size="2" class="ion-no-padding">
                                    <ion-icon size="large" name="person-circle-outline"></ion-icon>
                                </ion-col>
                                <ion-col size="10" class="ion-no-padding">
                                    <strong>Minha Conta</strong>
                                </ion-col>
                            </ion-row>
                            <ion-row class="pointer ion-row-flex" (click)="goToServiceCenterNav()">
                                <ion-col size="2" class="ion-no-padding">
                                    <ion-icon size="large" name="lock-open-outline"></ion-icon>
                                </ion-col>
                                <ion-col size="10" class="ion-no-padding">
                                    <strong>Meus Acessos</strong>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-item-divider>
                    <ion-item-divider>
                        <ion-grid class="no-padding-left">
                            <ion-row class="pointer ion-row-flex" (click)="logout()">
                                <ion-col size="2" class="ion-no-padding">
                                    <ion-icon size="large" name="exit-outline"></ion-icon>
                                </ion-col>
                                <ion-col size="10" class="ion-no-padding">
                                    <strong>Sair</strong>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-item-divider>
                </ng-template>
            </ion-popover>
        </ion-buttons>
    </ion-toolbar>
</ion-header>