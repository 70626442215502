import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-check',
    templateUrl: './label-check.component.html',
    styleUrls: ['./label-check.scss'],

})
export class LabelCheckComponent { 

    @Input() cssClass: string;
    @Input() title: string;
    @Input() noItalic: boolean;

    //Constructor
    constructor(){

    }
}