import { Component, Input } from "@angular/core";
import { Table, TableBody, TableHeader } from "../table.interfaces";

@Component({
  selector: "app-table-vertical-line-break",
  templateUrl: "./table-vertical-line-break.component.html",
  styleUrls: ["./table-vertical-line-break.scss"],
})
export class TableVerticalLineBreakComponent {
  @Input() table: Table;
  @Input() loading: Boolean = false;
  @Input() redirectUrl: String;
  @Input() tableVerticalLineBreakCallback: (id: Number) => Promise<void>;
  //Constructor
  constructor() { }

  async click(id: Number) {
    if (this.tableVerticalLineBreakCallback) {
      await this.tableVerticalLineBreakCallback(id);
      return;
    }
  }
}
