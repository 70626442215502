import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { AppSettingsService } from '../services/app-settings.service';
import * as AppActions from './app-global.actions';

@Injectable()
export class AppGlobalEffects {

    /**
     * 
     */
    constructor(
        private actions$: Actions,
        private router: Router,
        private settingsService: AppSettingsService 
    ) { }


    
}