import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { from, Observable, of, switchMap } from 'rxjs';
import { IAppGlobalState } from '../../app-modules-core/app-state/store/app-global-model';
import { AuthService } from '../../app-modules-core/auth/services/auth.service';
import { BaseStore } from '../libs/UTILNgrx/Store/BaseStore';
import * as AuthActions from '../../app-modules-core/auth/store/app-auth-state.actions';
import { AppMessageError } from '../libs/UTILEntities/app-message';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private store:  BaseStore<IAppGlobalState>,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        console.log("CHECKING AUTH --- "+redirectURL);
        return from(this._authService.check())
               .pipe(
                    switchMap((info) => {

                        if (info.isLogged) {
                            // Allow the access
                            return of(true);
                        }

                        //Update Application State
                        this.store.dispatch(AuthActions.ActionLogout({infoError: new AppMessageError('', info.msg) }));

                        // Redirect to the sign-in page
                        this._router.navigate(['auth/login'], { queryParams: { redirectURL } });

                        // Prevent the access
                        return of(false);

                })
            );
    }
}
