import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { from, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../app-modules-core/auth/services/auth.service';
import { navToHome } from '../../app-modules-ui/global-links';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check() {
        // Check the authentication status


        return from(this._authService.check())
               .pipe(
                    switchMap((info) => {
                        // If the user is authenticated...
                        if (info.isLogged) {
                            
                            // Redirect to the root
                            navToHome(this._router);

                            // Prevent the access
                            return of(false);
                        }

                        // Allow the access
                        return of(true);
                    })
                );
            
    }
}
