import { NgModule } from "@angular/core";
import { DefaultTemplateComponent } from "./default-template.component";
import { SharedModule } from "../../../shared.module";
import { SidebarDefaultModule } from "../../organisms/sidebar-default/sidebar-default.module";
import { HeaderDefaultModule } from "../../organisms/header-default/header-default.module";

@NgModule({
  declarations: [DefaultTemplateComponent],
  imports: [SharedModule, SidebarDefaultModule, HeaderDefaultModule],
  exports: [DefaultTemplateComponent],
})
export class DefaultTemplateModule {}
