import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-linkbox-image',
    templateUrl: './linkbox-image.component.html',
    styleUrls: ['./linkbox-image.component.scss']
})
export class LinkBoxImageComponent {

    @Input() iconSVG: string; 
    @Input() title: string;
    @Input() boxText: string;

    /**
     * Constructor
     */
    constructor() { 

    }

}
