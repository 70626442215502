import { createAction, props } from '@ngrx/store';
import { IAppMessage } from '../../../app-core/libs/UTILEntities/app-message';
import { User } from '../models/user';
import { SideblockMessageTypes } from '../auth-messages';

export enum AuthActionTypes {
    APP_CHANGE_AUTH_SIDEBAR = "[APP AUTH STATE] CHANGE_AUTH_SIDEBAR",
    APP_LOGIN = "[APP AUTH] AUTH_LOGIN",
    LOGIN_LOADING_SETTINGS = "[APP AUTH] LOGIN_LOADING_SETTINGS",
    LOGIN_SUCCESS = "[APP AUTH] APP_LOGIN_SUCCESS",
    LOGIN_FAILURE = "[APP AUTH] APP_LOGIN_FAILURE",
    LOGOUT = "[APP AUTH] LOGOUT",
    LOGOUT_SUCCESS = "[APP AUTH] LOGOUT_SUCCESS"
}

export const ChangeAuthSidebar = createAction(
    AuthActionTypes.APP_CHANGE_AUTH_SIDEBAR,
    props<{ sidebar: SideblockMessageTypes }>()
);

export const ActionLogin = createAction(
    AuthActionTypes.APP_LOGIN, 
    props<{ username: string, password: string }>()
);

export const LoadingSystemSettings = createAction(
    AuthActionTypes.LOGIN_LOADING_SETTINGS, 
    props<{ user: User, appMsg: IAppMessage, isLogin: boolean }>()
);


export const ActionLoginSuccess = createAction(
    AuthActionTypes.LOGIN_SUCCESS, 
    props<{ infoProfile: User }>()
);

export const ActionLoginFailure = createAction(
    AuthActionTypes.LOGIN_FAILURE, 
    props<{ infoError: IAppMessage }>()
);

export const ActionLogout = createAction(
    AuthActionTypes.LOGOUT,
    props<{ infoError: IAppMessage }>()
);

export const ActionLogoutSuccess = createAction(
    AuthActionTypes.LOGOUT_SUCCESS,
    props<{ infoError: IAppMessage }>()
);

/*
export class ChangeAuthSidebar implements BaseAction{
    readonly type = AuthActionTypes.APP_CHANGE_AUTH_SIDEBAR;
    constructor(public payload: any) {}
}

export class AppLogin implements BaseAction{
    readonly type = AuthActionTypes.APP_LOGIN;
    constructor(public payload: any) {}
}


export class ActionLoginSuccess implements BaseAction{
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public payload: any) {}
}

export class ActionLoginFailure implements BaseAction{
    readonly type = AuthActionTypes.LOGIN_FAILURE;
    constructor(public payload: any) {}
}

*/