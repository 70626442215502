import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class AppStorageService {

    //Props
    private _storage: Storage | null = null;

    //Constructor
    constructor(private storage: Storage) {
        this.init();
    }

    public async init(): Promise<void> {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        //console.log(`CRIANDO DATABASE ****`);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    // Create and expose methods that users of this service can
    // call, for example:
    public set(key: string, value: any) {
        this._storage?.set(key, value);
    }

    /**
     * 
     */
    public async get(key: string, defaultValue: any = null): Promise<any> {

        if(!this._storage){
            await this.init();
        }

        let value = await this._storage.get(key);
        return value ?? defaultValue;
    }

    /**
     * 
     * @param key 
     */
     public remove(key: string): Promise<any> {
        return this._storage.remove(key);
    }

    /**
     * 
     */
     public clear() {
        this._storage.clear();
    }
    
}