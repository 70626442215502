import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseHttpClient } from "../../../app-core/http/abstractions/base-http-client";
import { libArray } from "../../../app-core/libs/collections/util-collection";
import { ApiBaseService } from "../../../app-core/services/api-base-service";
import { ShippingAgents, ShippingAgentsList } from "../models/shippingAgents";

@Injectable({ providedIn: "root" })
export class ShippingAgentSearch extends ApiBaseService {
  //Props
  private readonly urlShippingAgents = `${environment.hosts.bff.shippingAgents}`;

  /**
   *
   * @param http
   */
  constructor(protected http: BaseHttpClient) {
    super(http);
  }

  public find(org: number) {
    if (!org) {
      return of(new ShippingAgentsList([]));
    }

    return this.fetch(org);
  }

  private fetch(org: number): Observable<ShippingAgentsList> {
    const sendParams = new HttpParams();

    const options = {
      headers: this.httpHeaderDefault,
      params: sendParams,
    };

    const result = this.http
      .get(`${this.urlShippingAgents}?idOrganization=${org}`, options)
      .pipe(
        switchMap((rawData: any[]) => {
          const list = new ShippingAgentsList(
            rawData.map((item) => ShippingAgents.fromApi(item))
          );
          return of(list);
        }),
        catchError((err) => of(new ShippingAgentsList([])))
      );

    return result;
  }
}
