import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs";
import { BaseStore } from "../../../app-core/libs/UTILNgrx/Store/BaseStore";
import { AppStorageService } from "../../../app-core/storage/app-storage-service";
import { IAppGlobalState } from "../../app-state/store/app-global-model";

import * as AppHydrateAcions from "./hydrate.actions";

const KEY_STATE_APP = "sinclogcore_ui_state";

@Injectable()
export class HydrationEffects implements OnInitEffects {
  /**
   * Constructor
   * @param action$
   * @param store
   */
  constructor(
    private action$: Actions,
    private store: BaseStore<IAppGlobalState>
  ) {}

  hydrate$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AppHydrateAcions.AppHydrate),
      map(() => {
        const storageValue = localStorage.getItem(KEY_STATE_APP);

        if (!storageValue) {
          return AppHydrateAcions.AppHydrateFail();
        }

        try {
          const state: IAppGlobalState = JSON.parse(storageValue);
          return AppHydrateAcions.AppHydrateSuccess({
            state: this._prepareState(state),
          });
        } catch {
          localStorage.removeItem(KEY_STATE_APP);
        }
      })
    );
  });

  serialize$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(
          AppHydrateAcions.AppHydrateSuccess,
          AppHydrateAcions.AppHydrateFail
        ),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap((state) =>
          localStorage.setItem(KEY_STATE_APP, JSON.stringify(state))
        )
      );
    },
    { dispatch: false }
  );

  /**
   *
   * @returns
   */
  ngrxOnInitEffects(): Action {
    return AppHydrateAcions.AppHydrate();
  }

  _prepareState(state: IAppGlobalState): IAppGlobalState {
    var { isLoading, ...newAuth } = state.auth;

    return {
      auth: { ...newAuth, isLoading: false },
      globalSettings: state.globalSettings,
    };
  }
}
