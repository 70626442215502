import { IApplicationRoute } from "./../abstractions/route.interface";
import { Injectable } from "@angular/core";
import { Route, Routes } from "@angular/router";

@Injectable({ providedIn: "root" })
export class RouteService {
  public resolve(routes: IApplicationRoute[]): Routes {
    var result = Object.values(routes).map((route): Route => {
      return {
        path: route.path,
        ...(!!route.loadChildren && { loadChildren: route.loadChildren }),
        ...(!!route.component && { component: route.component }),
        ...(!!route.pathMatch && { pathMatch: route.pathMatch }),
        ...(!!route.data && { data: route.data }),
        ...(!!route.redirectTo && { redirectTo: route.redirectTo }),
        ...(route.childGuard?.length > 0 && {
          canActivateChild: [...route.childGuard],
        }),
        ...(route.guards?.length > 0 && {
          canActivate: [...route.guards],
        }),
        ...(route.subRoutes?.length > 0 && {
          children: this.resolve(route.subRoutes),
        }),
      };
    });
    return result;
  }
}
