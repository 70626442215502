import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import "moment/dist/locale/pt-br";

@Pipe({
    name: "customDateTimePipe",
})
export class CustomDateTimePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        moment.locale("pt-br");
        return moment(value, "dd/MM/yyyy").format("dd/MM/yyyy");
    }
}
