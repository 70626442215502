import { MenuItem } from "./../models/menu-item";
import { Injectable } from "@angular/core";
import { IApplicationRoute } from "../../../app-core/routes/abstractions/route.interface";

@Injectable({ providedIn: "root" })
export class MenuService {
  public fromApplicationRoute(routes: IApplicationRoute[]): MenuItem[] {
    if (!routes) return;
    var result = Object.values(routes)
      .filter((route) => !!route.displayName)
      .map(
        (route) => new MenuItem(route.displayName, route.fullPath, route.icon)
      );
    return result;
  }
}
