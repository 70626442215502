import { StatusList } from "./../../../../app-modules-core/shipments/models/status";
import { ShipmentList } from "./../../../../app-modules-core/shipments/models/shipment";
import { BaseStore } from "./../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import { fromEvent, map, mergeMap, Observable, Subscription } from "rxjs";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

import { DropDown } from "../../../../shared/components/molecules/dropdown/dropdown.interfaces";
import { ShipmentsSearch } from "../../../../app-modules-core/shipments/services/shipments.search";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";
import { ActionItem } from "../../../../app-modules-core/app-actions/models/action-item";
import { FuseDrawerComponent } from "../../../../shared/components/organisms/drawer";
import { ShipperSearch } from "../../../../app-modules-core/shippers/services/shipper.search";
import { ShipperBranchesSearch } from "../../../../app-modules-core/shippers/shipper-branch/services/shipper_branches.search";
import { DeliveryProviderSearch } from "../../../../app-modules-core/organizations/delivery-providers/services/delivery-provider.search";
import { PaginationOptions } from "../../../../shared/components/molecules/paginations/Pagination/models/pagination-options";
import { ServicesSearch } from "../../../../app-modules-core/shipments/services/services.search";
import { StatusSearch } from "../../../../app-modules-core/shipments/services/status.search";
import { StatusFilter } from "../../../../app-modules-core/shipments/models/status";
import { IonInfiniteScroll, Platform } from "@ionic/angular";
import {
  Table,
  TableBody,
  TableContent,
  TableHeader,
} from "../../../../shared/components/molecules/tables/table.interfaces";
import { TableService } from "../../../../shared/components/molecules/tables/table.service";
import { ShipmentListData } from "./shipment-list.interface";
import { ShipmentListPageSearch } from "./shipment-list.search";
import { DateService } from "../../../../app-core/utils/dates/date.service";
import { DropdownService } from "../../../../shared/components/molecules/dropdown/dropdown.service";
import { DeliveryProviderList } from "../../../../app-modules-core/organizations/delivery-providers/models/delivery-provider";
import { ShipperList } from "../../../../app-modules-core/shippers/models/shipper";
import { ServiceList } from "../../../../app-modules-core/shipments/models/service";
import PlatformType from "../../../../app-core/utils/PlatformIdentifier/models/PlatformType";
import { PlatformIdentifierService } from "../../../../app-core/utils/PlatformIdentifier/platformIdentifier.service";

@Component({
  selector: "app-shipment-list",
  templateUrl: "./shipment-list.html",
  styleUrls: ["./shipment-list.scss"],
})
export class ShipmentListPage implements OnInit {
  //Filters:
  @Input() ddlServices: any = [];
  @Input() ddlStatus: any = [];
  @Input() startDate: any = "";
  @Input() endDate: any = "";

  public shipperSub: any;
  public idOrganization: number;
  private dataPagination: PaginationOptions;
  private dataPaginationMobile: PaginationOptions;
  public countDataShipments: number;
  public services$: DropDown;
  public status$: DropDown;
  public loading: boolean = true;
  public shippers = [];
  public shippersSelected: number[] = [];
  public statusSelected: number[] = [];
  public oldEvent: PaginationOptions;
  platformTypeObservable$: Observable<number>;
  platformTypeSubs$: Subscription;
  platformType: number;
  @ViewChild(FuseDrawerComponent) filterDrawer: FuseDrawerComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  /**
   *
   */
  constructor(
    private shipment: ShipmentsSearch,
    private shipmentStatus: StatusSearch,
    private shipper: ShipperSearch,
    private shipper_branches: ShipperBranchesSearch,
    private services: ServicesSearch,
    private deliveryProvider: DeliveryProviderSearch,
    private store: BaseStore<IAppGlobalState>,
    public tableService: TableService,
    private pageService: ShipmentListPageSearch,
    private dates: DateService,
    private platformIdentifier: PlatformIdentifierService,
    private dropdownService: DropdownService
  ) {
    this._handleOrganization();
    this.loadFilters();

    //detecção de plataforma web/mobile
    this.platformTypeObservable$ = this.platformIdentifier.getPlataformType();
    this.platformTypeSubs$ = this.platformTypeObservable$.subscribe(
      (type: number) => {
        this.platformType = type;
      }
    );

    this.dataPagination = new PaginationOptions();
    this.dataPaginationMobile = new PaginationOptions();
    this.oldEvent = new PaginationOptions();
    this.pagination();
  }

  public getShipperByTerm(term?: any) {
    if (term == undefined) {
      return;
    }

    this.shipperSub = this.shipper
      .find({ orgs: [this.idOrganization], term })
      .subscribe((shipper) => {
        this.shippers = shipper.listOfItems.map((x) => ({
          id: x.document.number,
          name: x.tradeName,
        }));
      });
  }

  public getShippersSelected(itemSelected?: any) {
    if (itemSelected == undefined) {
      return;
    }
    this.shippersSelected = itemSelected;
  }

  /**
   * Show/Hide filters
   */
  public toggleFilters = () => {
    this.filterDrawer.toggle();
  };

  /**
   * List of utilities actions available
   */
  public listOfActions: ActionItem[] = [
    {
      title: "Filtros",
      iconSrc: "filter",
      clickAction: this.toggleFilters,
    },
  ];

  ngOnInit(): void {}

  public controlPaginationMobile(event: any, infoshipment: any) {
    if (event != undefined) {
      this.oldEvent = event;
      event.target.complete();
    }

    if (event != undefined && infoshipment.qyData < this.dataPagination.limit) {
      event.target.disabled = true;
      return;
    }

    this.dataPagination.offset =
      this.dataPagination.currentPage * this.dataPagination.limit;
    this.dataPagination.currentPage++;
  }

  private _handleOrganization() {
    this._loadOrganization().subscribe((o) => {
      this.idOrganization = o.organization.id;
    });
  }

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }

  public loadFilters = () => {
    this.services$ = this.dropdownService.buildDropdown(
      this.services.findFromCache({ org: this.idOrganization }).pipe(
        mergeMap((services) => {
          return services.getAll;
        })
      ),
      "id",
      "name"
    );

    this.status$ = this.dropdownService.buildDropdown(
      this.shipmentStatus.findFromCache({ org: this.idOrganization }).pipe(
        mergeMap((statusShipment) => {
          return statusShipment.getAll;
        })
      ),
      "id",
      "name"
    );
  };

  public pagination = (event?: any) => {
    if (event != null && this.platformType == PlatformType.web.value) {
      this.dataPagination = event;
    }

    this.loadGeneralInfos(
      (infos: ShipmentListData) => this.handleData(infos, event),
      this.dataPagination
    );
  };

  public handleData = (infosShipment: ShipmentListData, event: any) => {
    if (this.platformType == PlatformType.web.value) {
      this.tableService.renew();
    }

    if (this.platformType == PlatformType.mobile.value) {
      this.controlPaginationMobile(event, infosShipment);
    }

    var filledData = this.pageService._prepareData(infosShipment);
    this.tableService.buildBodies({ data: filledData });
    this.tableService.buildHeaders(
      "Remessa",
      "Embarcador/Remetente",
      "Serviço/Prazo/Provedor",
      "Status/Movimentação"
    );
    this.isLoading(false);
  };

  private loadGeneralInfos = (callback, pagination: PaginationOptions) => {
    this.isLoading(true);
    var shipmentsFilter = StatusFilter.filter;
    StatusFilter.withOrganization(this.idOrganization);
    StatusFilter.withServices(this.ddlServices);
    StatusFilter.withStatus(this.ddlStatus);
    StatusFilter.withDates(
      this.dates.convertToDateUS(this.startDate.toString()),
      this.dates.convertToDateUS(this.endDate.toString())
    );
    StatusFilter.withSortByIdDesc(true);
    StatusFilter.withPagination(pagination.limit, pagination.offset);
    StatusFilter.withShipperDocuments(this.shippersSelected);

    this.shipment
      .findAll(shipmentsFilter)
      .pipe(this._handleShipment(callback))
      .subscribe();
  };

  private _handleStatus = (
    ids: number[],
    callback: Function,
    shipment: any,
    shipper: any,
    shipperBranch: any,
    deliveryProvider: any,
    services: any
  ) => {
    return this.shipmentStatus
      .findFromCache({
        org: this.idOrganization,
        ids: ids,
      })
      .pipe(
        map((statusShipment: StatusList) => {
          var data: ShipmentListData = {
            shipments: shipment,
            statusShipment: statusShipment.listOfItems,
            shipper: shipper,
            shipperBranch: shipperBranch,
            deliveryProvider: deliveryProvider,
            services: services,
            qyData: shipment.length,
          };

          callback(data);
        })
      );
  };

  private _handleShipment(callback: any) {
    return mergeMap((shipment: ShipmentList) => {
      this.countDataShipments = shipment.listOfItems?.length ?? 0;
      return this.shipper
        .find({ orgs: [this.idOrganization] })
        .pipe(this._handleShipper(shipment.listOfItems, callback));
    });
  }

  private _handleShipper(shipment: any[], callback: any) {
    return mergeMap((shipper: ShipperList) => {
      if (!shipment || shipment.length <= 0) {
        this.isLoading(false);
        return [];
      }

      var arrayIdsShipper = shipper.listOfItems
        .filter((x) => x?.id > 0)
        .map((x) => x?.id);
      let arrayIdsShipperNoDuplicate = [
        ...new Set(arrayIdsShipper),
      ] as number[];

      return this.shipper_branches
        .findBranch(arrayIdsShipperNoDuplicate)
        .pipe(
          this._handleShipperBranch(shipment, callback, shipper.listOfItems)
        );
    });
  }

  private _handleShipperBranch(shipment: any, callback: any, shipper: any[]) {
    return mergeMap((shipperBranch: ShipperList) => {
      return this.deliveryProvider
        .findFromCache(this.idOrganization)
        .pipe(
          this._handleDeliveryProviders(
            shipment,
            callback,
            shipper,
            shipperBranch.listOfItems
          )
        );
    });
  }

  private _handleDeliveryProviders(
    shipment: any[],
    callback: any,
    shipper: any[],
    shipperBranch: any[]
  ) {
    return mergeMap((deliveryProvider: DeliveryProviderList) => {
      if (!shipment) return [];

      var arrayIdsServices = shipment
        .filter((x) => x?.service?.id > 0)
        .map((x) => x?.service?.id);
      let arrayIdsServicesNoDuplicate = [
        ...new Set(arrayIdsServices),
      ] as number[];

      return this.services
        .findFromCache({
          ids: arrayIdsServicesNoDuplicate,
          org: this.idOrganization,
        })
        .pipe(
          this._handleServices(
            shipment,
            callback,
            shipper,
            shipperBranch,
            deliveryProvider.listOfItems
          )
        );
    });
  }

  private _handleServices(
    shipment: any,
    callback: any,
    shipper: any[],
    shipper_branch: any[],
    deliveryProvider: any[]
  ) {
    return mergeMap((services: ServiceList) => {
      var arrayIdsStatus = shipment
        .filter((x) => x?.status?.id > 0)
        .map((x) => x?.status?.id);
      let arrayIdsStatusNoDuplicate = [...new Set(arrayIdsStatus)] as number[];

      return this._handleStatus(
        arrayIdsStatusNoDuplicate,
        callback,
        shipment,
        shipper,
        shipper_branch,
        deliveryProvider,
        services.listOfItems
      );
    });
  }

  private isLoading(isLoading: boolean): void {
    if (this.dataPaginationMobile.offset == 0) {
      this.loading = isLoading;
      return;
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.shipperSub?.unsubscribe();
    this.tableService.renew();
    this.dataPaginationMobile = new PaginationOptions();
    this.infiniteScroll.disabled = false;
    this.platformTypeSubs$.unsubscribe();
  }

  clearFilters() {
    this.ddlServices = "";
    this.ddlStatus = "";
    this.startDate = "";
    this.endDate = "";
  }

  findByFilters() {
    this.tableService.renew();
    this.dataPaginationMobile = new PaginationOptions();
    this.dataPagination = new PaginationOptions();
    this.pagination();
    this.infiniteScroll.disabled = false;
  }
}
