import { Component, Input } from '@angular/core';

@Component({
    selector: 'item-list-full-width-01',
    templateUrl: './item-list-full-width-01.component.html',
    styleUrls: ['list-full-width-01.scss'],

})
export class ItemListFullWidth01Component { 

    //Constructor
    constructor(){

    }
}