<app-heading-page
  title="Gestão Delivery"
  subTitle="Acompanhamento do ciclo de vida de nossas remessas. Bora garantir que todas cheguem ao seu destino!"
>
</app-heading-page>
<ion-content>
  <div *ngIf="combinedDeliveriesStats$ | async; let combined">
    <div *ngIf="(signalr.isLoading$ | async) === false">
      <app-table-special-column
        *ngIf="combined.delivery"
        class="ion-hide-md-down"
        [table]="combined.delivery"
        specialColumn="1"
        title="TOTALIZADOR DE REMESSAS"
        [tableSpecialColumnCallback]="openDeliveryDetail"
      ></app-table-special-column>
      <app-table-vertical
        *ngIf="combined.delivery"
        [table]="combined.delivery"
        class="ion-hide-md-up"
        [tableVerticalCallback]="openDeliveryDetail"
      ></app-table-vertical>
      <app-empty-content-message *ngIf="!combined.delivery">
      </app-empty-content-message>
    </div>
  </div>

  <!-- LOADING CONTENT -->
  <app-loading-table-special-column
    class="ion-hide-md-down"
    *ngIf="signalr.isLoading$ | async"
  >
  </app-loading-table-special-column>

  <app-loading-table-vertical
    class="ion-hide-md-up"
    *ngIf="signalr.isLoading$ | async"
  ></app-loading-table-vertical>
</ion-content>
