import { Shipper } from "../../models/shipper";

export class ShipperBranch {
  shipper: Shipper;


  /**
   *
   * @param response
   * @returns {ShipperBranch}
   */
  static fromApi(response: any) {
    const data = new ShipperBranch();
    data.shipper = Shipper.fromApi(response.shipper);
    return data;
  }
}

