import { AuthGuard } from "../../../../app-core/guards/auth.guard";
import { OrganizationGuard } from "../../../../app-core/guards/organization.guard";
import { IApplicationRoute } from "../../../../app-core/routes/abstractions/route.interface";
import { SERVICE_SETTINGS_ROUTES } from "../../../services-settings/service-settings.routing";
import { SHIPPERS_ROUTES } from "../../../shippers/shippers.routing";
import { SHIPPING_AGENTS_ROUTES } from "../../../shipping-agents/shipping-agents.routing";
import { SettingsNavPage } from "./settings-nav";

export const SETTINGS_ROUTES: IApplicationRoute[] = [
  {
    path: "settings",
    component: SettingsNavPage,
    guards: [OrganizationGuard, AuthGuard],
    fullPath: `/nav/settings`,
    icon: "construct",
    displayName: "Configurações",
  },
];
