import { Component, Input } from "@angular/core";

@Component({
  selector: "app-empty-content-message",
  templateUrl: "./empty-content-message.component.html",
  styleUrls: ["./empty-content-message.scss"],
})
export class EmptyContentMessageComponent {
  //Input Params
  @Input() message: string;

  //Constructor
  constructor() {}
}
