<app-heading-page title="Serviços Logísticos">
</app-heading-page>

<grid-cards-01>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of listOfServices">
        <ion-item-sliding>
          <ion-item color="light" lines="none" class="item-card-list">
            <item-grid-cards-01>
              <div class="col-9">
                <span>#{{item.id}}</span>&nbsp;&nbsp;
                <span>{{item.name}}</span>
              </div>
              <div class="col-3 text-end">
                <ion-badge
                  style="padding-bottom: 5px"
                  class="fw-light"
                  [color]="item.isActive? 'success': 'danger'"
                  >{{item.isActive? 'Ativo': 'Inativo'}}
                </ion-badge>
              </div>
            </item-grid-cards-01>
          </ion-item>
          <ion-item-options class="options-actions-grid" side="end">
            <ion-item-option color="danger">Excluir</ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </div>
    </div>
  </grid-cards-01>