import { Component, ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'app-default-content-template',
    templateUrl: 'default-content-template.html',
    styleUrls: ['./default-content-template.scss'],
})
export class DefaultContentTemplate {


    /**
     * 
     */
    constructor(
    ) { }


}
