import { MenuItem } from './menu-item';

export const menuServicesLevel: MenuItem[] = [
    /*
    {
        title: 'Atendimento',
        url: '/nav/service-level',
        icon: 'calendar'
    },
    */

    /*
    {
        title: 'Painéis',
        url: '/nav/control-panels',
        icon: 'pulse'
    },
    {
        title: 'Relatórios',
        url: '/nav/reports',
        icon: 'bar-chart'
    }
    */

];