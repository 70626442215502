import { Location } from '@angular/common'
import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-heading-page',
    templateUrl: './heading-page.html',
    styleUrls: ['./heading-page.scss']
})
export class HeadingPageComponent {

    @Input() title: string;
    @Input() subTitle: string;

    /**
     * 
     */
    constructor(private location: Location) {
    }

    /**
     * 
     */
    public goBack(): void {

        this.location.back()
    }
}
