import { ListModelBase } from '../../../app-core/libs/collections/list-model-base';
import { Document } from './document';

export class Shipper {
  id: number;
  tradeName: string;
  isActive: boolean;
  document: Document;

  /**
   *
   * @param response
   * @returns {Shipper}
   */
  static fromApi(response: any) {
    const data = new Shipper();
    data.id = response?.id;
    data.tradeName = response?.trade_name;
    data.document = Document.fromApi(response?.document);
    data.isActive = response?.is_active;

    return data;
  }
}

export class ShipperList extends ListModelBase<Shipper> {
  listOfItems: Shipper[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: Shipper[]) {
    super(initialList);
  }
}
