import jwt_decode from "jwt-decode";
import { environment } from "../../../../environments/environment";
import {
  NumberList,
  StringList,
} from "../../../app-core/libs/collections/list-model-base";
import { libArray } from "../../../app-core/libs/collections/util-collection";

import {
  Organization,
  OrganizationList,
} from "../../organizations/models/organization";
import { ServiceCenter } from "../../service-centers/models/service-center";
import { AccessToken } from "./auth-token";

export class User {
  id?: string;
  name: string;
  email: string;
  username: string;
  orgsIds: number[];
  orgs: Organization[];
  groups: string[];
  serviceCenters: ServiceCenter[];

  get isDeveloper(): boolean {
    return (
      this.groups?.filter((val) => val == environment.groups.developers)
        .length > 0
    );
  }

  get canViewAllOrgs() {
    return this.isDeveloper;
  }

  get hasOrgs(): boolean {
    return !libArray.isEmpty(this.orgsIds);
  }

  get hasMultipleOrgs(): boolean {
    return libArray.isMultiple(this.orgsIds);
  }

  /**
   *
   * @param token
   * @returns New instance of User
   */
  static fromJwt(response: any) {
    //Decode JWT token
    let token = jwt_decode<AccessToken>(response.access_token);

    let user = new User();
    user.id = token.sub;
    user.email = token.email;
    user.name = token.name;
    user.username = token.username;
    user.groups = token.groups;
    user.orgsIds = token.orgs;

    user.setServiceCenters(response.service_centers);

    return user;
  }

  static fromJson(payload: string) {
    let user = new User();
    Object.assign(user, JSON.parse(payload));

    return user;
  }

  /**
   *
   * @param info Array of service centers
   */
  public setServiceCenters(info: any) {
    if (!info) {
      return;
    }

    if (!Array.isArray(info)) {
      let onlyServiceCenter = ServiceCenter.fromInfo(
        info.id,
        info.idOrganization,
        info.abbr
      );
      this.serviceCenters = [onlyServiceCenter];
      return;
    }

    this.serviceCenters = info.map((value: any) => {
      return ServiceCenter.fromInfo(value.id, value.idOrganization, value.abbr);
    });
  }
}
