import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-badge-default',
    templateUrl: './badge-default.component.html',
    styleUrls: ['./badge-default.scss'],

})
export class BadgeDefaultComponent { 

    @Input() cssClass: string;
    @Input() color: string;
    @Input() title: string;

    //Constructor
    constructor(){

    }
}