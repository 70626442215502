import { BaseStore } from "./../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";

import { MenuController, ModalController } from "@ionic/angular";

import { Storage } from "@ionic/storage";
import {
  map,
  Observable,
  mergeMap,
  forkJoin,
  combineLatest,
  Subscription,
} from "rxjs";
import {
  SignalrData,
  SignalRService,
} from "../../../../app-core/libs/signalr/signalr.service";
import { ModalTableComponent } from "../../../../shared/components/molecules/modals/modal-table/modal-table.component";

import {
  Table,
  TableContent,
} from "../../../../shared/components/molecules/tables/table.interfaces";
import { LoadingService } from "../../../../app-core/services/loadings/loading.service";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";
import { ServicesSearch } from "../../../../app-modules-core/shipments/services/services.search";

@Component({
  selector: "app-engage-dash",
  templateUrl: "./engage-dash.html",
  styleUrls: ["./engage-dash.scss"],
})
export class EngageDashPage implements OnInit, OnDestroy {
  //@ViewChild(FuseDrawerComponent) filterDrawer: FuseDrawerComponent;
  public engage$: Observable<Table>;
  public combinedEngage$: Observable<{
    engage: Table;
  }>;
  public idOrganization: number;
  public subscriptions: Subscription[] = [];

  /**
   *
   * @param menu
   * @param router
   * @param storage
   * @param cd
   * @param modal
   */
  constructor(
    public menu: MenuController,
    public router: Router,
    public storage: Storage,
    private modal: ModalController,
    public signalr: SignalRService,
    public serviceSearch: ServicesSearch,
    public loading: LoadingService,
    private store: BaseStore<IAppGlobalState>
  ) {}
  ngOnInit(): void {
    this._handleOrganization();
    this._handleSignalR();

    this.combinedEngage$ = this._handleIncomingData();
  }

  ngOnDestroy(): void {
    this.signalr.clearHub("ConnectToServiceStats");
    this.signalr.closeConnection();

    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  private _handleIncomingData(): Observable<{ engage: Table }> {
    return combineLatest({
      engage: this.signalr.dataSubject.pipe(
        this._handleServices(this.idOrganization)
      ),
    });
  }

  private _handleSignalR() {
    this.signalr.createConnection("shipments-stats-hub");
    this.signalr.connectListener("ServiceStatsFindAll");
    this.signalr.startConnection(
      "shipments-stats-hub",
      "ConnectToServiceStats",
      this.idOrganization.toString()
    );
  }

  private _handleServices(org: number) {
    return mergeMap((serviceStats: SignalrData) => {
      return this.serviceSearch
        .findFromCache({
          ids: serviceStats?.listOfData?.map((s) => s.id_service),
          org,
        })
        .pipe(
          map((listOfServices) => {
            var listOfServiceStats = serviceStats?.listOfData?.map(
              (serviceStat) => {
                var currentService = listOfServices.listOfItems.find(
                  (s) => serviceStat.id_service == s.id
                );

                if (!currentService) return {};
                var newServiceStats = {
                  ...serviceStat,
                  name: currentService.name,
                };
                return newServiceStats;
              }
            );

            return this.buildTable(listOfServiceStats);
          })
        );
    });
  }

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }

  private _handleOrganization() {
    this.subscriptions.push(
      this._loadOrganization().subscribe((o) => {
        this.idOrganization = o.organization?.id;
      })
    );
  }

  /**
   * List of utilities actions available
   */
  public listOfTracking: any[] = [
    {
      id: 0,
      event: "Criação da Remessa",
      dtEvent: "2021-12-12 05:33",
      username: "usuario.sobrenome",
    },
    {
      id: 0,
      event: "Envio para provedor de entrega",
      dtEvent: "2021-12-12 05:33",
      username: "usuario.sobrenome",
    },
    {
      id: 0,
      event: "Confirmação de engajamento do parceiro",
      dtEvent: "2021-12-12 05:33",
      username: "usuario.sobrenome",
    },
    {
      id: 0,
      event: "Remessa em rota de entrega",
      dtEvent: "2021-12-12 05:33",
      username: "usuario.sobrenome",
    },
    {
      id: 0,
      event: "Remessa entregue com sucesso",
      dtEvent: "2021-12-12 05:33",
      username: "usuario.sobrenome",
    },
  ];

  public tableofShipmentsTotals: Table = {
    headers: [
      {
        name: "Serviços",
      },
      {
        name: "Solicitação",
      },
      {
        name: "Data da criação",
      },
      {
        name: "Embarcador",
      },
      {
        name: "Prazo",
      },
    ],
    bodies: [
      {
        content: [
          {
            value: ["LOGISTICA_REVERSA"],
          },
          {
            value: ["912783127"],
          },
          {
            value: ["28/04/2022"],
          },
          {
            value: ["EDITORA HEZIOM"],
          },
          {
            value: ["05/05/2022"],
          },
        ],
      },
      {
        content: [
          {
            value: ["LOGISTICA_REVERSA"],
          },
          {
            value: ["912783127"],
          },
          {
            value: ["28/04/2022"],
          },
          {
            value: ["EDITORA HEZIOM"],
          },
          {
            value: ["05/05/2022"],
          },
        ],
      },
    ],
  };

  buildTable = (services: any): Table => {
    if (!services) {
      return null;
    }

    const table: Table = {
      bodies: [],
      headers: [],
    };

    table.headers.push({ name: "SERVIÇOS" });
    table.headers.push({ name: "AGUARDANDO ENGAJAMENTO" });
    table.headers.push({ name: "ENGAJAMENTO ATRASADO", status: "warning" });
    table.headers.push({ name: "AGUARDANDO CONFIRMAÇÃO" });
    table.headers.push({ name: "ENGAJADOS", status: "success" });

    services?.forEach((s) => {
      const columns: TableContent[] = [];

      columns.push({ value: [s.name] });
      columns.push({ value: [s.qy_waiting_engagement] });
      columns.push({ value: [s.qy_delayed_engagement] });
      columns.push({ value: [s.qy_waiting_confirmation_engagement] });
      columns.push({ value: [s.qy_engaged] });

      table.bodies.push({ content: columns });
    });
    return table;
  };

  getTableOf() {
    this.tableofShipmentsTotals.bodies = new Array(10).fill({
      content: this.tableofShipmentsTotals.bodies[0]?.content,
    });

    return this.tableofShipmentsTotals;
  }

  openEngageDetail = async (id: Number) => {
    var modal = await this.modal.create({
      component: ModalTableComponent,
      cssClass: "custom-modal-css",
      componentProps: {
        title: "Remessas",
        canClose: true,
        table: this.getTableOf(),
        callback: () => this.modal.dismiss(),
      },
    });

    await modal.present();
  };
}
