import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as AuthActions from "../../../../app-modules-core/auth/store/app-auth-state.actions";
import { Observable } from 'rxjs';
import { BaseStore } from '../../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../../../app-modules-core/app-state/store/app-global-model';
import { User } from '../../../../app-modules-core/auth/models/user';
import { OrganizationBaseComponent } from '../../organization-base';


@Component({
    selector: 'app-header-default',
    templateUrl: './header-default.html',
    styleUrls: ['./header-default.scss'],
    encapsulation: ViewEncapsulation.None

})
export class HeaderDefaultComponent extends OrganizationBaseComponent implements OnInit, OnDestroy {

    //Propertiers
    @ViewChild('popover') popover;
    isOpen = false;
    public userInfo: User = new User();

    /**
     * 
     */
    constructor(protected store: BaseStore<IAppGlobalState>,
        private router: Router,) {
        super(store);
        this._handlerUserInfo();
    }

    controlPopover(e: Event) {
        this.popover.event = e;
        this.isOpen = true;
    }

    private _handlerUserInfo() {
        this._loadUserInfo().subscribe((user) => {
            this.userInfo = user;
        });
    }

    private _loadUserInfo(): Observable<User> {
        return this.store.select((state) => state.auth.profile);
    }


    logout(): void {
        this.store.dispatch(AuthActions.ActionLogout({ infoError: null }));
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.subscribeAppSate();
    }

    ngOnDestroy(): void {
        this.unsubscribeAppSate();
    }

    /**
     * 
     */
    public goToServiceCenterNav() {
        this.router.navigateByUrl('/nav/select-service-centers');
    }


}

