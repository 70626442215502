<div class="container">
  <div class="col-12">
    <h4 class="mb-0">Consultas de Remessas</h4>
    <hr />
  </div>
</div>
<div class="container">
  <div class="row">
    <div
      class="col-xl-2 col-md-3 col-sm-6 col-6"
      *ngFor="let p of links; let i = index"
    >
      <app-linkbox-image
        [title]="p.title"
        [iconSVG]="p.icon"
        [boxText]="p.boxText"
        [routerLink]="p.url"
      ></app-linkbox-image>
    </div>
  </div>
</div>
