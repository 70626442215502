import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { BaseStore } from '../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../app-modules-core/app-state/store/app-global-model';
import { selectOrgAndServiceCenter } from '../../app-modules-core/app-state/store/app-global.selectors';
import { Organization } from '../../app-modules-core/organizations/models/organization';
import { ServiceCenter } from '../../app-modules-core/service-centers/models/service-center';



export class OrganizationBaseComponent {

    //Propertiers
    public serviceCenterSelected: ServiceCenter;
    public organizationSelected: Organization;
    protected infoAppState$: Observable<{ serviceCenterSelected: ServiceCenter, organization: Organization}>;
    protected subAppState: Subscription;

    
    /**
     * 
     */
    constructor(protected store: BaseStore<IAppGlobalState>) { 
        this.infoAppState$ = this.store.pipe(select(selectOrgAndServiceCenter));
    }

    
    /**
     * 
     */
     subscribeAppSate(): void{

        this.subAppState = this.infoAppState$.subscribe( (info) => {
            this.serviceCenterSelected = info.serviceCenterSelected;
            this.organizationSelected = info.organization;
        })
    }

    /**
     * 
     */
    unsubscribeAppSate(): void {
        this.subAppState.unsubscribe();
    }



}

