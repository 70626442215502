import { RouteService } from "./app-core/routes/service/route.service";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BASE_ROUTES } from "./app.routing";
const routes = new RouteService().resolve(BASE_ROUTES);
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  /**
   *
   */
  constructor() {}
}
