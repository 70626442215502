import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { SidebarMenuComponent } from './sidebar-menu';

@NgModule({
    declarations: [
        SidebarMenuComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SidebarMenuComponent
    ]
})
export class SidebarMenuModule {
}
