<div class="header-branding">
    <div class="logo-header" (click)="goToHome()">
        <img src="assets/img/logos/sinclog-symbol-header.svg" alt="Sinclog">
    </div>
    <div *ngIf="organizationSelected" class="title-header" (click)="goToHome()">
        <span>{{organizationSelected.tradeName}}</span>
    </div>
    <div button *ngIf="!(organizationSelected)" class="title-header" (click)="goToSelectOrganization()">
        <span>TODAS</span>
    </div>
</div>