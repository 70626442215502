import { ContentTypes } from "./../../../../app-core/http/content-type-enum";
import { Injectable } from "@angular/core";
import {
  Table,
  TableBody,
  TableContent,
  TableHeader,
} from "./table.interfaces";

@Injectable({ providedIn: "root" })
export class TableService {
  private instance: Table = {
    headers: [],
    bodies: [],
  };

  public table: Table = this.instance;

  public hasContent = () =>
    this.instance.bodies.length > 0 && this.instance.headers.length > 0;

  public renew = () => {
    this.table.bodies = [];
    this.table.headers = [];
  };

  public buildHeaders(...headers: string[]): void {
    this.instance.headers = headers.map((header) => {
      return {
        name: header,
      };
    });
  }

  public buildContent(values: any[]): TableContent[] {
    return values.map((value) => {
      return {
        value: value,
      };
    });
  }

  public buildBodies({ data }: { data: { id?: number; values: any[] }[] }) {
    var newBodies = data.map((d): TableBody => {
      return {
        id: d.id,
        content: this.buildContent(d.values),
      };
    });

    this.instance.bodies = [...this.instance.bodies, ...newBodies];
  }
}
