import { Injectable } from '@angular/core';
import { AppStorageService } from '../../../app-core/storage/app-storage-service';
import { SettingsSystem } from '../models/settings-system';

@Injectable({ providedIn: "root" })
export class SettingsOrganizationStorage {

    //Props
    private readonly KEY_COLLECTION_SETTINGS = 'settings_of_organizations';

    /**
     * 
     */
    constructor(private storageService: AppStorageService) {
    }

    /**
     * 
     */
     public get(){
         return this.storageService.get(this.KEY_COLLECTION_SETTINGS);
    }

    /**
     * 
     */
     public async save(items: SettingsSystem[]){
        await this.clear();
        return this.storageService.set(this.KEY_COLLECTION_SETTINGS, JSON.stringify(items));
    }

    /**
     * 
     * @returns 
     */
    public clear(){
        return this.storageService.remove(this.KEY_COLLECTION_SETTINGS);
    }


}

