import { ListModelBase } from "../../../app-core/libs/collections/list-model-base";
import { Organization } from "../../organizations/models/organization";
import { ShipperBranch } from "../../shippers/shipper-branch/models/shipper_branch";
import { Service } from "./service";
import { Status } from "./status";

export class Shipment {
  id: string;
  organization: Organization;
  shipper_branch: ShipperBranch;
  service: Service;
  status: Status;
  id_tms: number;
  edi: any;
  deadline: any;
  register_date: string;
  delivery_provider: any;
  rules: any;
  payer: any;
  sender_origin: any;

  /**
   *
   */
  static fromInfo(_id: string, _idOrganization: number, _abbr: string) {
    let sc = new Shipment();
    sc.id = _id;
    sc.organization = new Organization();
    sc.organization.id = _idOrganization;
    return sc;
  }

  /**
   *
   * @param response
   */
  static fromApi(response: any) {
    const item = new Shipment();
    item.id = response.id;
    item.organization = Organization.fromApi(response.organization);
    item.service = Service.fromApi(response.service);
    item.status = Status.fromApi(response.status);
    item.shipper_branch = ShipperBranch.fromApi(response.shipper_branch);
    item.deadline = response.deadline;
    item.payer = response.payer;

    return response;
  }
}

export class ShipmentList extends ListModelBase<Shipment> {
  listOfItems: Shipment[];

  /**
   *
   * @param initialList
   */
  constructor(initialList: Shipment[]) {
    super(initialList);
  }
}
