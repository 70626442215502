import { ListModelBase } from '../../../app-core/libs/collections/list-model-base';

export class Organization{
    id: number;
    name: string;
    tradeName: string;

    static fromApi(response: any){
        const item = new Organization();
        item.id = response.id;
        item.tradeName = response.trade_name;
        return item;
    }
}

export class OrganizationList extends ListModelBase<Organization>{

    listOfItems: Organization[];

    /**
     * 
     * @param initialList 
     */
    constructor(initialList: Organization[]){
        super(initialList);
    }
}
