
export class Document {
  type: string;
  number: string;

  /**
   *
   * @param response
   * @returns {Document}
   */
  static fromApi(response: any) {
    const data = new Document();
    data.type = response?.type;
    data.number = response?.number;

    return data;
  }
}

