import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { debounceTime, distinctUntilChanged, filter, Subject } from 'rxjs';
import { MultiSelectAutoCompleteData } from './models/multi-select-auto-complete-data';

@Component({
  selector: 'app-dropdown-multi-select-auto-complete',
  templateUrl: './multi-select-auto-complete.component.html',
  styleUrls: ['./multi-select-auto-complete.scss']
})

export class DropdownMultiSelectAutoCompleteComponent {

  public selectedShippers: number[] = [];
  public minLengthTerm: number = 3;
  public shipperInput$ = new Subject<string>();
  public itensSelected: number[] = [];

  // //Constructor
  constructor(private config: NgSelectConfig) {

    this.shipperInput$.pipe(
      filter(term => {
        return term !== null && term !== undefined && term !== "" && term.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      debounceTime(800)
    );

    this.shipperInput$.subscribe(term => { this.data.emit(term); });
  }

  @Output() private data = new EventEmitter<string>();
  @Output() private dataSelected = new EventEmitter<number[]>();
  @Input() shippers: MultiSelectAutoCompleteData[];

  public getSelected = (event?: any) => {

    if (event == undefined) {
      return;
    }
    this.itensSelected.push(event);
    this.dataSelected.emit(this.itensSelected);
  }

  public getRemove = (event?: any) => {

    if (event == undefined) {
      return;
    }

    this.itensSelected = this.itensSelected.filter((ele) => {
      return ele != event.value
    });

    this.dataSelected.emit(this.itensSelected);
  }

  public removeAllItens = ($event?: any) => {

    this.itensSelected = [];
    this.dataSelected.emit(this.itensSelected);
  }

  ngOnDestroy(): void {
    this.shipperInput$.unsubscribe();
  }

}
