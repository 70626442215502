import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-h2-icon',
    templateUrl: './h2-icon.component.html',
    styleUrls: ['./h2-icon.scss'],

})
export class H2IconComponent { 

    @Input() iconSrc: string;
    @Input() iconName: string;

    //Constructor
    constructor(){

    }
}