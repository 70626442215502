import { Delivery } from "./../../../../app-modules-core/shipments/stats/models/delivery";
import { BaseStore } from "./../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";

import { MenuController, ModalController } from "@ionic/angular";

import { Storage } from "@ionic/storage";
import { combineLatest, map, mergeMap, Observable, Subscription } from "rxjs";
import {
  SignalrData,
  SignalRService,
} from "../../../../app-core/libs/signalr/signalr.service";
import { ModalTableComponent } from "../../../../shared/components/molecules/modals/modal-table/modal-table.component";
import {
  Table,
  TableContent,
} from "../../../../shared/components/molecules/tables/table.interfaces";
import { DeliveryProviderSearch } from "../../../../app-modules-core/organizations/delivery-providers/services/delivery-provider.search";
import { LoadingService } from "../../../../app-core/services/loadings/loading.service";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";

@Component({
  selector: "app-delivery-flow-dash",
  templateUrl: "./delivery-flow-dash.html",
  styleUrls: ["./delivery-flow-dash.scss"],
})
export class DeliveryFlowDashPage implements OnInit, OnDestroy {
  public service: Table;
  public deliveriesStats$: Observable<Table>;
  public combinedDeliveriesStats$: Observable<{
    delivery: Table;
  }>;
  public idOrganization: number;
  public listOfDeliveryProviders: any[];
  public subscriptions: Subscription[] = [];

  /**
   *
   * @param menu
   * @param router
   * @param storage
   * @param cd
   */
  constructor(
    public menu: MenuController,
    public router: Router,
    public storage: Storage,
    private modal: ModalController,
    public signalr: SignalRService,
    private deliveryProvider: DeliveryProviderSearch,
    public loading: LoadingService,
    private store: BaseStore<IAppGlobalState>
  ) {}
  ngOnDestroy(): void {
    this.signalr.closeConnection();

    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  ngOnInit(): void {
    this._handleOrganization();
    this._handleDeliveryProviders();
    this.combinedDeliveriesStats$ = this._handleIncomingData();
  }

  buildTable = (providers: any): Table => {
    if (!providers) {
      return null;
    }

    const table: Table = {
      bodies: [],
      headers: [],
    };

    table.headers.push({ name: "PROVEDORES DE ENTREGA" });
    table.headers.push({ name: "AGUARDANDO OPERAÇÃO" });
    table.headers.push({ name: "COLETADOS" });
    table.headers.push({ name: "ROTA AO DESTINO" });
    table.headers.push({ name: "INSUCESSOS", status: "warning" });
    table.headers.push({ name: "ENTREGUE", status: "success" });

    providers?.forEach((s) => {
      const columns: TableContent[] = [];

      columns.push({
        value: [
          s.name,
          s.qy_waiting_confirmation +
            s.qy_waiting_confirmation +
            s.qy_colected +
            s.qy_on_destiny_route +
            s.qy_failure +
            s.qy_delivered,
        ],
      });
      columns.push({ value: [s.qy_waiting_confirmation] });
      columns.push({ value: [s.qy_colected] });
      columns.push({ value: [s.qy_on_destiny_route] });
      columns.push({ value: [s.qy_failure] });
      columns.push({ value: [s.qy_delivered] });

      table.bodies.push({ content: columns });
    });

    return table;
  };

  private _handleDeliveryProviders() {
    this.subscriptions.push(
      this.deliveryProvider
        .findFromCache(this.idOrganization)
        .subscribe((deliveryProviders) => {
          this.listOfDeliveryProviders = deliveryProviders.listOfItems;
          this._handleSignalR();
        })
    );
  }

  private _handleIncomingData() {
    return combineLatest({
      delivery: this.signalr.dataSubject.pipe(this.handleDeliveryStats()),
    });
  }

  private handleDeliveryStats = () => {
    return map((deliveryStats: SignalrData) => {
      if (!(deliveryStats?.listOfData?.length > 0)) {
        return this.buildTable(null);
      }

      var listOfServiceStats = deliveryStats?.listOfData?.map((stat) => {
        var currentDeliveryProvider = this.listOfDeliveryProviders.find(
          (d) => stat.id_delivery_provider == d.id
        );

        if (!currentDeliveryProvider) return {};

        var newStats = {
          ...stat,
          name: currentDeliveryProvider.tradeName,
        };
        return newStats;
      });
      return this.buildTable(listOfServiceStats);
    });
  };

  private _handleSignalR() {
    this.signalr.createConnection("shipments-stats-hub");
    this.signalr.startConnection(
      "shipments-stats-hub",
      "ConnectToStats",
      this.idOrganization.toString(),
      this.listOfDeliveryProviders.map((c) => c.id.toString())
    );
    this.signalr.connectListener("StatsFindAll");
  }

  private _handleOrganization() {
    this.subscriptions.push(
      this._loadOrganization().subscribe((o) => {
        this.idOrganization = o.organization?.id;
      })
    );
  }

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }

  public tableofShipmentsTotals: Table = {
    headers: [
      {
        name: "Serviços",
      },
      {
        name: "Solicitação",
      },
      {
        name: "Data da criação",
      },
      {
        name: "Embarcador",
      },
      {
        name: "Prazo",
      },
    ],
    bodies: [
      {
        content: [
          {
            value: ["LOGISTICA_REVERSA"],
          },
          {
            value: ["912783127"],
          },
          {
            value: ["28/04/2022"],
          },
          {
            value: ["EDITORA HEZIOM"],
          },
          {
            value: ["05/05/2022"],
          },
        ],
      },
      {
        content: [
          {
            value: ["LOGISTICA_REVERSA"],
          },
          {
            value: ["912783127"],
          },
          {
            value: ["28/04/2022"],
          },
          {
            value: ["EDITORA HEZIOM"],
          },
          {
            value: ["05/05/2022"],
          },
        ],
      },
    ],
  };
  getTableOf() {
    this.tableofShipmentsTotals.bodies = new Array(10).fill({
      content: this.tableofShipmentsTotals.bodies[0]?.content,
    });

    return this.tableofShipmentsTotals;
  }

  openDeliveryDetail = async (id: Number) => {
    var modal = await this.modal.create({
      component: ModalTableComponent,
      cssClass: "custom-modal-css",
      componentProps: {
        title: "Remessas",
        canClose: true,
        table: this.getTableOf(),
        callback: () => this.modal.dismiss(),
      },
    });

    await modal.present();
  };
}
