import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-icon-01',
    templateUrl: './label-icon-01.component.html',
    styleUrls: ['./label-icon-01.scss'],

})
export class LabelIcon01Component { 

    @Input() cssClass: string;
    @Input() title: string;
    @Input() iconSrc: string;

    //Constructor
    constructor(){

    }
}