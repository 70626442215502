import { OrganizationGuard } from "../../../../app-core/guards/organization.guard";
import { IApplicationRoute } from "../../../../app-core/routes/abstractions/route.interface";
import { GlobalSearchNavPage } from "./global-search-nav";

export const GLOBAL_SEARCH_ROUTES: IApplicationRoute[] = [
  {
    path: "global-searches",
    component: GlobalSearchNavPage,
    guards: [OrganizationGuard],
    displayName: "Consultas",
    icon: "search-circle",
    fullPath: "/nav/global-searches",
  },
];
