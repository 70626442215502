
export class PaginationOptions{
    
    limit: number;
    offset: number;
    currentPage: number;
    /**
     *
     */
    constructor(limit: number = 10, offset: number = 0, currentPage: number = 1) {
        this.limit = limit;
        this.offset = offset;
        this.currentPage = currentPage;
    }
}