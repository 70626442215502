<!-- CORE COMPONENT INIT -->
<ion-grid>
  <ion-row class="ion-align-items-end">
    <!--  INIT -->
    <ion-col class="wrapper-divisor" size="3">
      <div class="wrapper-header">
        <app-header
          size="sm"
          text="{{ table?.headers[specialColumn - 1].name }}"
          letter-spacing="wide"
        ></app-header>
      </div>
      <ion-grid *ngFor="let item of table?.bodies">
        <app-box status="information">
          <ion-row
            style="height: 100%"
            class="wrapper-body ion-justify-content-center ion-align-items-center"
          >
            <app-paragraph-multi-size
              *ngFor="let text of item.content[specialColumn - 1].value"
              size="sm"
              text="{{ text }}"
            ></app-paragraph-multi-size>
          </ion-row>
        </app-box>
      </ion-grid>
    </ion-col>
    <!--  END -->
    <!--  INIT -->
    <ion-col>
      <div class="wrapper-content">
        <app-header size="sm" [text]="title" letter-spacing="wide"></app-header>
        <hr class="underline" />
      </div>
      <app-table-box
        [tableBoxCallback]="tableSpecialColumnClick"
        [headers]="newTable.headers"
        [bodies]="newTable.bodies"
      ></app-table-box>
    </ion-col>
    <!--  END -->
  </ion-row>
</ion-grid>
<!-- CORE COMPONENT END -->
