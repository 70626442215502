import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of, switchMap } from "rxjs";
import {
  IAppGlobalSettings,
  IAppGlobalState,
} from "../../app-modules-core/app-state/store/app-global-model";
import { BaseStore } from "../libs/UTILNgrx/Store/BaseStore";

@Injectable({
  providedIn: "root",
})
export class OrganizationGuard implements CanActivate {
  /**
   * Constructor
   */
  constructor(
    private store: BaseStore<IAppGlobalState>,
    private _router: Router
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
    return this._check(redirectUrl);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
  }
  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(redirectURL: string): Observable<boolean> {
    // Check the org is defined
    console.log("CHECKING GUARD ORG --- " + redirectURL);

    return this._loadOrganization().pipe(
      switchMap((info) => {
        const hasOrg = !!info.organization && info.organization.id > 0;
        if (hasOrg) {
          return of(true);
        }

        this._router.navigate(["nav/select-organization"], {
          queryParams: { redirectURL },
        });

        return of(false);
      })
    );
  }
}
