import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { MenuController } from "@ionic/angular";

import { Storage } from "@ionic/storage";
import { ServicesSearch } from "../../../../app-modules-core/shipments/services/services.search";
import { Observable } from "rxjs";
import { Service } from "../../../../app-modules-core/shipments/models/service";
import { BaseStore } from "../../../../app-core/libs/UTILNgrx/Store/BaseStore";
import {
IAppGlobalSettings,
IAppGlobalState,
} from "../../../../app-modules-core/app-state/store/app-global-model";

@Component({
    selector: 'app-delivery-service-list',
    templateUrl: 'delivery-service-list.html',
    styleUrls: [],
})
export class DeliveryServiceListPage {

    public listOfServices: Service[];

    /**
     * 
     * @param menu 
     * @param router 
     * @param storage 
     * @param cd 
     */
    constructor(
        public menu: MenuController,
        public router: Router,
        public storage: Storage,
        private services: ServicesSearch,
        private store: BaseStore<IAppGlobalState>
    ) {
        this._loadOrganization().subscribe((settings) => {
            this.services
            .findAll({ ids: [], org: settings.organization.id })
            .subscribe((obj) => (this.listOfServices = obj.listOfItems));
        });
        
    }

    private _loadOrganization(): Observable<IAppGlobalSettings> {
    return this.store.select((state) => state.globalSettings);
    }
    
}
