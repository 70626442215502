import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from "@angular/common/http";
import { from, lastValueFrom, Observable } from "rxjs";
import { AuthStorageService } from '../../../app-modules-core/auth/services/auth-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';


@Injectable({ providedIn: "root" })
export class AuthRequestInterceptor implements HttpInterceptor {

    /**
     * 
     * @param authStorageService 
     */
    constructor(private authStorageService: AuthStorageService) {

    }

    /**
     * 
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.get("no-auth")){
            return next.handle(req);
        }

        return from(this.handle(req, next));
    }

    /**
     * 
     * @param req 
     * @param next 
     * @returns 
     */
    private async handle(req: HttpRequest<any>, next: HttpHandler){

        const token = await this.authStorageService.getToken();

        let authReq = req;
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

        return lastValueFrom(next.handle(authReq));
    }
}
