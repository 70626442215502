<ion-item-divider class="ion-margin">
  <ion-row class="ion-justify-content-between ion-no-margin">
    <ion-text class="modal-title">
      <app-header size="sm" [text]="title" letter-spacing="wide"></app-header>
    </ion-text>
    <ion-text>
      <ion-col class="text-end close" (click)="closeModal()">X</ion-col>
    </ion-text>
  </ion-row>
</ion-item-divider>
<ng-content> </ng-content>
