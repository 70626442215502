import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthError } from '../models/auth-error';
import { AuthToken } from '../models/auth-token';
import { User } from '../models/user';
import { TokenService } from './token.service';
import { BaseStore } from '../../../app-core/libs/UTILNgrx/Store/BaseStore';
import { IAppGlobalState } from '../../app-state/store/app-global-model';
import * as AppActions from '../../app-state/store/app-global.actions';
import { AuthStorageService } from './auth-storage.service';
import { ContentTypes } from '../../../app-core/http/content-type-enum';
import { AppLoggerService } from '../../../app-core/logging/app-logger-service';

@Injectable({ providedIn: "root" })
export class AuthService {

    //Props
    private isAuthenticated: boolean = false;
    private pathSignIn = `${environment.hosts.bff.auth}/sign-in`;
    private pathSignOut = `${environment.hosts.bff.auth}/sign-out`;
    private pathRefreshToken = `${environment.hosts.bff.auth}/refresh-token`;

    /**
     * 
     * @param http Constructor
     */
    constructor(private http: HttpClient,
                private storageService: AuthStorageService,
                private logger: AppLoggerService,
                private store:  BaseStore<IAppGlobalState>) { }

    /**
     * 
     */
    get httpOptions(): any {
        let httpOptions = {
            headers: new HttpHeaders().set('Content-Type', ContentTypes.FORM_URLENCODED)
                                      .set('no-auth', 'true')

        };

        return httpOptions;
    }

    /**
     * 
     * @param email 
     * @param password 
     * @returns 
     */
    public signIn(usernameParam: string, passwordParam: string): Observable<any> {

        if (this.isAuthenticated) {
            return throwError(() => new AuthError('Usuário já está logado. Faça o logout primeiro.'));
        }

        const options = this.httpOptions;

        const bodyForm = new HttpParams()
                            .set('username', usernameParam)
                            .set('password', passwordParam);

        return this.http.post<AuthToken>(
            this.pathSignIn,
            bodyForm.toString(),
            options
        );

    }

    /**
     * 
     * @returns 
     */
    public signRefreshToken(token: string): Observable<any> {

        if (this.isAuthenticated) {
            return throwError(() => new Error('User is already logged in'));
        }

        return this.http.post<AuthToken>(
            this.pathRefreshToken,
            { accessToken: token },
            this.httpOptions
        ).pipe(
            switchMap(async (response: any) => {

                if(!response.access_token){
                    console.error("NO ACCESS TOKEN PROVIDED");
                    return of(false);
                }

                await this.registerResponseToken(response);
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    public async signOut(): Promise<boolean> {

        let refreshToken: string = await this.storageService.getRefreshToken();

        const options = this.httpOptions;

        const bodyForm = new HttpParams()
                            .set('refreshToken', refreshToken);

        const promise = new Promise<boolean>( (resolve, rejected) => {

            this.http.post<AuthToken>(
                this.pathSignOut,
                bodyForm.toString(),
                options
            ).subscribe({
                next: async (v) => {
                    
                    //Clear session infos
                    await this.storageService.clearAuthInfo();
                    
                    // Set the authenticated flag to false
                    this.isAuthenticated = false;

                    resolve(true);
                },
                error: (e) => {
                    this.logger.error("Fail to sign out", e);
                    rejected("Falha ao tentar fazer o logout");
                } 
            });                

        });

        return promise;    
    }

    /**
     * Check the authentication status
     */
    public async check(): Promise<{isLogged: boolean, msg: string }> {

        // Check the access token availability
        let token: string = await this.storageService.getToken();

        if (!token) {
            return Promise.resolve({isLogged: false, msg: "Identificamos que você não está logado no sistema. Informe suas credenciais."});
        }

        // Check the access token expire date
        if (TokenService.isTokenExpired(token)) {
            return Promise.resolve({isLogged: false, msg: "Parece que seu acesso expirou. Informe seu login novamente."});
        }

        return Promise.resolve({isLogged: true, msg: ""});

        // Não utilizamos refresh token no momento.
        // O token tem 12 horas de duração por padrão. Após isso, o usuário deve logar novamente
        // If the access token exists and it didn't expire, sign in using it
        const source$ = this.signRefreshToken(token);
        const signInValue = await lastValueFrom(source$);
        return Promise.resolve(signInValue);
    }

    /**
     * Get response and tranform into user data saving locally
     * @param response From OAuth request
     */
     public async registerResponseToken(response: any): Promise<User> {

        //Fill user data from token
        let user = User.fromJwt(response);

        // Store tokens and user infos in storage
        await this.storageService.saveAuthInfo(response, user);

        // Set the authenticated flag to true
        this.isAuthenticated = true;

        if(user.serviceCenters?.length == 1){
            //Dispatch LoginSuccess Action
            this.store.dispatch(AppActions.AppSelectServiceCenter({serviceCenter: user.serviceCenters[0]}));
        }

        return user;
    }  

    //============= START PRIVATE METHODS ================= //


    //============= END PRIVATE METHODS ================= //
}

